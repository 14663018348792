// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CssTablePagination } from "../../../Common/componentesCssMui";

import { Icon } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./TableUsers.scss";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE LOS DETALLES DE USUARIOS
const columns = [
  { id: "id", label: "", align: "center", minWidth: 50, width: 50 },
  { id: "apellidos", label: "Apellidos", minWidth: 100 },
  { id: "nombres", label: "Nombres", minWidth: 100 },
  { id: "email", label: "Correo electrónico", minWidth: 200 },
  { id: "username", label: "Nombre de usuario", minWidth: 175 },
  { id: "activo", label: "Es activo", align: "center", minWidth: 100 },
  { id: "staff", label: "Es staff", align: "center", minWidth: 100 },
  { id: "permiso", label: "Nivel de permiso", align: "center", minWidth: 160 },
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
    // minWidth: 100,
  },
];

export function TableUsers(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA UsersAdmin.js
  const { users, updateUser, onDeleteUser } = props;

  // CREACIÓN DE LAS VARIABLES DE ESTADOS
  const [page, setPage] = React.useState(0); // PARA EL MANEJO DE LA PAGINACIÓN
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // CANTIDAD DE FILAS A MOSTRAR

  // FUNCIÓN PARA EL CAMBIO LA PÁGINA DE LA TABLA
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FUNCIÓN PARA CAMBIAR LA CANTIDAD DE FILAS A MOSTRAR
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {users ? (
        // DETALLES DE LOS USUARIOS
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              className="table-users-admin"
            >
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        width: column.width,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "bold",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, index) => {
                    return (
                      <TableRow tabIndex={-1} key={user.id}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                          sx={{ py: 0, my: 0 }}
                        >
                          {index + 1 + page * rowsPerPage}
                          {/* {user.id} */}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {user.last_name}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {user.first_name}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {user.email}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {user.username}
                        </TableCell>

                        <TableCell
                          className={"status"}
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {user.is_active ? (
                            <Icon name="check" />
                          ) : (
                            <Icon name="close" />
                          )}
                        </TableCell>

                        <TableCell
                          className={"status"}
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {user.is_staff ? (
                            <Icon name="check" />
                          ) : (
                            <Icon name="close" />
                          )}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                            fontWeight: "bold",
                          }}
                        >
                          {user.permiso === "ADMIN"
                            ? "ADMINISTRADOR"
                            : user.permiso}
                        </TableCell>

                        <Actions
                          user={user}
                          updateUser={updateUser}
                          onDeleteUser={onDeleteUser}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* == CONTROL DE LA PAGINACIÓN ======================================== */}
          <CssTablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Usuarios por página:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES DE ACTUALIZAR O ELIMINAR DATOS DE UN USUARIO
function Actions(props) {
  const { user, updateUser, onDeleteUser } = props;
  return (
    <TableCell align="center" sx={{ py: FONTSIZE.paddingCell }}>
      {/* == BOTÓN PARA MODIFICAR DATOS DE UN USUARIO ========================== */}
      <Tooltip title="Editar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => updateUser(user)}
          type="button"
          sx={{ my: 0 }}
        >
          <EditIcon color="info" />
        </IconButton>
      </Tooltip>
      {/* == BOTÓN PARA ELIMINAR UN USUARIO ==================================== */}
      {user.is_active && (
        <Tooltip title="Eliminar">
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => onDeleteUser(user)}
            type="button"
            sx={{ my: 0 }}
          >
            <DeleteForeverIcon color="error" />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
}
