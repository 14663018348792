// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Loader } from "semantic-ui-react";

import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  IconButton,
  Tooltip,
} from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import {
  HeaderPage,
  TablePresupuesto,
  AddEditPresupuestoForm,
  ModificarPresupuestoForm,
  DetallesPresupuesto,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE ICONOS
import { Search as SearchIcon } from "../../components/Admin/icons/search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// IMPORTACIÓN DE HOOKS CREADOS
import { usePresupuesto } from "../../hooks";

export function PresupuestoAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { loading, presupuestos, getPresupuestos } = usePresupuesto();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [tamanio, setTamanio] = useState("small"); // PARA EL TAMAÑO DEL MODAL

  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [anio, setAnio] = useState(""); // PARA EL TEXT FIELD
  const [filtroAnio, setFiltroAnio] = useState(""); // PARA FILTRAR LA BÚSQUEDA POR AÑO
  const [ordering] = useState("-anio"); // PARA EL ORDENAMIENTO DE LOS DATOS

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  useEffect(
    () => getPresupuestos(filtroAnio, "", ordering),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, filtroAnio, ordering]
  );

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // ============================== FUNCIONES PARA LA SECCIÓN DE FILTROS ==============================
  // PARA REALIZAR LA BÚSQUEDA DE UN PRESUPUESTO POR EL AÑO, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeAnio = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setFiltroAnio(event.target.value);
    }
    setAnio(event.target.value);
  };

  // PARA ELIMINAR TODOS LOS FILTROS
  const handleChangeFilter = () => {
    setAnio("");
    setFiltroAnio("");
  };
  // =======================================================================================================

  // FUNCIÓN PARA AÑADIR UN NUEVO PRESUPUESTO, SE ABRIRÁ EL MODAL
  const addPresupuesto = () => {
    setTitleModal("Nuevo presupuesto");
    setContentModal(
      <AddEditPresupuestoForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    setTamanio("small");
    openCloseModal();
  };

  // FUNCIÓN PARA ACTUALIZAR LOS DATOS DE UN PRESUPUESTO, SE ABRIRÁ EL MODAL
  const updatePresupuesto = (data) => {
    setTitleModal("Actualizar datos del presupuesto");
    setContentModal(
      <AddEditPresupuestoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        presupuesto={data}
      />
    );
    setTamanio("small");
    openCloseModal();
  };

  // FUNCIÓN PARA LA MODIFICACIÓN PRESUPUESTARIA, SE ABRIRÁ EL MODAL
  const modificacionPresupuestaria = (data, tipo) => {
    setTitleModal("Modificación presupuestaria");
    setContentModal(
      <ModificarPresupuestoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        presupuesto={data}
        tipo={tipo}
      />
    );
    setTamanio("small");
    openCloseModal();
  };

  // FUNCIÓN PARA LOS DETALLES DE UN PRESUPUESTO, SE ABRIRÁ EL MODAL
  const detallePresupuesto = (data) => {
    setTitleModal("Detalles del presupuesto");
    setContentModal(
      <DetallesPresupuesto presupuesto={data} onClose={openCloseModal} />
    );
    setTamanio("large");
    openCloseModal();
  };

  return (
    <>
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Presupuestos"
        subheader="En esta sección podrás agregar y ver el estado de los presupuestos."
        btnTitle="Nuevo presupuesto"
        btnClick={addPresupuesto}
      />

      {/* =================== SECCIÓN DE FILTROS ==================  */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* ======================= BÚSQUEDA POR AÑO ======================= */}
            <Grid item md={2.1}>
              <CssTextField
                size="small"
                inputProps={{
                  type: "number",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                value={anio}
                onKeyDown={handleChangeAnio}
                placeholder="Buscar por año"
                variant="outlined"
                onChange={handleChangeAnio}
                fullWidth
                sx={{ marginTop: 1 }}
              />
            </Grid>

            {/* ====================== LIMPIAR FILTROS ====================== */}
            <Grid item sx={{ marginTop: 0.25 }}>
              <Tooltip title="Limpiar filtro">
                <IconButton size="large" onClick={handleChangeFilter}>
                  <FilterAltOffIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* ========= CARGA DE LA TABLA DE PRESUPUESTOS ========= */}
          {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
            <TablePresupuesto
              presupuestos={presupuestos}
              updatePresupuesto={updatePresupuesto}
              modificacionPresupuestaria={modificacionPresupuestaria}
              detallePresupuesto={detallePresupuesto}
            />
          )}
        </CardContent>
      </Card>

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size={tamanio}
      />
    </>
  );
}
