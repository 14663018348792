// IMPORTACIÓN DE REACT
import * as React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";

import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";

// IMPORTACIÓN DE ICONOS
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GroupIcon from "@mui/icons-material/Group";
import BadgeIcon from "@mui/icons-material/Badge";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InventoryIcon from "@mui/icons-material/Inventory";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AddBusinessSharpIcon from "@mui/icons-material/AddBusinessSharp";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { Link, useLocation } from "react-router-dom";

// IMPORTACIÓN DE HOOKS CREADOS
import { useAuth } from "../../hooks";

// DEFINICIÓN DEL TAMAÑO DEL MENÚ LATERAL DESPLEGADO
const drawerWidth = 240;

// DEFINICIÓN DE LA TRANSICIÓN AL ABRIR EL MENÚ LATERAL
const openedMixin = (theme) => ({
  zIndex: 10,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// DEFINICIÓN DE LA TRANSICIÓN AL CERRAR EL MENÚ LATERAL
const closedMixin = (theme) => ({
  zIndex: 10,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 7px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 7px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// DEFINICIÓN DE LA TRANSICIÓN DEL MENÚ SUPERIOR
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 11,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// DEFINICIÓN DE QUE MENÚ LATERAL SE VA A OBSERVAR
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// CREACIÓN DEL ESTILO DEL TOOLTIP
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

export function MiniDrawer(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS
  const { children } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { auth, logout } = useAuth();
  const { pathname } = useLocation();

  // DEFINICIÓN DE LAS VARIABLES DE ESTADO
  const [open, setOpen] = React.useState(false); // PARA ABRIR O CERRAR EL MENÚ LATERAL

  // FUNCIONES PARA EL COMPORTAMIENTO DEL MENÚ LATERAL
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // CREACIÓN DE LAS VARIABLES DE ESTADO PARA EL COMPORTAMIENTO DE LAS LISTAS DESPLEGABLES
  const [openAcopio, setOpenAcopio] = React.useState(false);
  const [openDesacopio, setOpenDesacopio] = React.useState(false);
  const [openPrestamo, setOpenPrestamo] = React.useState(false);

  // CREACIÓN DE LAS FUNCIONES PARA EL COMPORTAMIENTO DE LAS LISTAS DESPLEGABLES
  const handleClickAcopio = () => {
    setOpenAcopio(!openAcopio);
    setOpenDesacopio(false);
    setOpenPrestamo(false);
  };

  const handleClickDesacopio = () => {
    setOpenAcopio(false);
    setOpenDesacopio(!openDesacopio);
    setOpenPrestamo(false);
  };

  const handleClickPrestamo = () => {
    setOpenAcopio(false);
    setOpenDesacopio(false);
    setOpenPrestamo(!openPrestamo);
  };

  // FUNCIÓN PARA DEFINIR QUE SE VA A MOSTRAR EN EL MENÚ SUPERIOR, EL NOMBRE DE USUARIO O EMAIL
  const renderName = () => {
    if (auth.me?.first_name && auth.me?.last_name) {
      return `${auth.me.first_name} ${auth.me.last_name}`;
    }
    return auth.me?.email;
  };

  // LISTA DE LAS PÁGINAS QUE PUEDE ACCEDER EL USUARIO ADMINISTRADOR
  const itemsAdmin = [
    {
      href: "/users",
      icon: <AssignmentIndIcon />,
      title: "Usuarios",
    },
    {
      href: "/persona",
      icon: <GroupIcon />,
      title: "Personas",
    },
    {
      href: "/area",
      icon: <ApartmentIcon />,
      title: "Áreas",
    },
    {
      href: "/trabajador",
      icon: <BadgeIcon />,
      title: "Trabajadores",
    },
    {
      href: "/presupuesto",
      icon: <MonetizationOnIcon />,
      title: "Presupuesto",
    },
  ];

  // LISTA DE LAS PÁGINAS QUE PUEDE ACCEDER EL USUARIO DE COMPRAS
  const itemsCompras = [
    {
      href: "/proveedores",
      icon: <LocalShippingIcon />,
      title: "Proveedores",
    },
    {
      href: "/categorias",
      icon: <AssignmentIcon />,
      title: "Categorías",
    },
    {
      href: "/insumos",
      icon: <InventoryIcon />,
      title: "Insumos",
    },
  ];

  const items2Compras = [
    {
      title: "Acopios",
      icon: <AddBusinessSharpIcon />,
      onClick: handleClickAcopio,
      value: openAcopio,
      hrefs: [
        {
          title: "Realizar acopio",
          href: "/acopios",
          icon: <AppRegistrationIcon />,
        },
        {
          title: "Lista de acopios",
          href: "/acopios_realizados",
          icon: <FormatListBulletedIcon />,
        },
      ],
    },
  ];

  // LISTA DE LAS PÁGINAS QUE PUEDE ACCEDER EL USUARIO SOLICITANTE
  const itemsSolicitante = [
    {
      title: "Desacopios",
      icon: <BookmarkRemoveIcon />,
      onClick: handleClickDesacopio,
      value: openDesacopio,
      hrefs: [
        {
          title: "Solicitar desacopio",
          href: "/desacopios",
          icon: <ContentPasteGoIcon />,
        },
        {
          title: "Lista de desacopios",
          href: "/solicitud_desacopios",
          icon: <ListAltIcon />,
        },
      ],
    },
    {
      title: "Préstamos",
      icon: <NoteAltIcon />,
      onClick: handleClickPrestamo,
      value: openPrestamo,
      hrefs: [
        {
          title: "Solicitar préstamo",
          href: "/prestamos",
          icon: <ReceiptLongIcon />,
        },
        {
          title: "Lista de préstamos",
          href: "/solicitud_prestamos",
          icon: <BallotOutlinedIcon />,
        },
      ],
    },
  ];

  // FUNCIÓN PARA DEFINIR TODAS LAS PAGINAS QUE VA A ACCEDER UN USUARIO
  const renderMenuItems = () => {
    // USUARIO ADMINISTRADOR
    if (auth.me?.permiso === "ADMIN" || auth.me?.is_staff) {
      return itemsAdmin.concat(itemsCompras);
    } else {
      // USUARIO DE COMPRAS
      if (auth.me?.permiso === "COMPRAS") {
        return itemsCompras;
      } else {
        // USUARIO SOLICITANTE
        return [];
      }
    }
  };

  // FUNCIÓN PARA DEFINIR TODAS LAS PAGINAS QUE VA A ACCEDER UN USUARIO
  const renderMenuGrupsItems = () => {
    // USUARIO ADMINISTRADOR
    if (auth.me?.permiso === "ADMIN" || auth.me?.is_staff) {
      return items2Compras.concat(itemsSolicitante);
    } else {
      // USUARIO DE COMPRAS
      if (auth.me?.permiso === "COMPRAS") {
        return items2Compras;
      } else {
        // USUARIO SOLICITANTE
        return itemsSolicitante;
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* === MENÚ SUPERIOR ===================================================*/}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#2C6975", // 68B2A0 CDE0C9  E0ECDE 2C6975
          // backgroundImage: "linear-gradient(90deg, #2C6975, #497d88ca)",
        }}
      >
        <Toolbar
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            {/* === ICONO DE DESPLEGAR MENÚ U OCULTAR MENÚ ====================*/}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: -0.7,
                marginRight: 5,
              }}
            >
              {open ? (
                <ChevronLeftIcon
                  sx={{
                    color: "#FFFF",
                  }}
                />
              ) : (
                <MenuIcon />
              )}
            </IconButton>

            {/* === TÍTULO DEL MENÚ ========================================= */}
            <Typography variant="h6" noWrap component="div">
              SAME
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // width: "fit-content",
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              bgcolor: "background.paper",
              color: "text.secondary",
              "& svg": {
                m: 1,
              },
              "& hr": {
                mx: 0.5,
              },
            }}
          >
            {/* === MUESTRA DEL NOMBRE DEL USUARIO O EL EMAIL ================ */}
            <Typography
              sx={{
                color: "#000000",
                mx: 1,
                fontSize: 14,
                // fontFamily:
                //   '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              }}
            >
              Hola, {renderName()}
            </Typography>

            <Divider orientation="vertical" variant="middle" flexItem />

            {/* === BOTÓN PARA DESLOGUEAR ==================================== */}
            <List component="div" disablePadding>
              <ListItem disablePadding as={Link} to={"/"} onClick={logout}>
                <LightTooltip placement="bottom" title={"Cerrar sesión"}>
                  <LogoutIcon />
                </LightTooltip>
              </ListItem>
            </List>
          </Box>
        </Toolbar>
      </AppBar>

      {/* === MENÚ LATERAL ================================================================================ */}
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: "#2C6975",
          },
        }}
      >
        <DrawerHeader></DrawerHeader>

        {/* ========================= LISTA DE LINKS DE LISTA SIMPLE ========================= */}
        <List>
          {renderMenuItems().map((item) => (
            <div key={item.title}>
              <ListItem
                // key={item.title}
                disablePadding
                as={Link}
                to={item.href}
                sx={{
                  display: "block",
                  py: 0.5,
                  px: 1,
                }}
              >
                <LightTooltip placement="right" title={open ? "" : item.title}>
                  <ListItemButton
                    sx={{
                      height: 40,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      backgroundColor: pathname === item.href && "#FFFFFF59",
                      borderRadius: 1.5,
                      width: "100%",

                      "& .MuiListItemIcon-root": {
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                        color: "#FFFFFF",
                      },
                      "& .MuiListItemText-root": {
                        opacity: open ? 1 : 0,
                        color: "#FFFFFF",
                        "& .MuiListItemText-primary": {
                          fontWeight: "500",
                          fontSize: 15,
                          fontFamily:
                            '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                        },
                      },
                      "&:hover": {
                        backgroundColor: "#FFFFFF59",
                      },
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </LightTooltip>
              </ListItem>

              {item.title === "Presupuesto" && (
                <Divider
                  sx={{
                    borderColor: "#ffffff",
                    mx: 1,
                  }}
                />
              )}
            </div>
          ))}

          {auth?.me.permiso !== "SOLICITANTE" && (
            <Divider
              sx={{
                borderColor: "#ffffff",
                mx: 1,
              }}
            />
          )}

          {/* ========================= LISTA DE LINKS DE LISTA DESPLEGABLES ========================= */}
          {renderMenuGrupsItems().map((item) => (
            <div key={item.title}>
              <ListItem
                disablePadding
                sx={{
                  display: "block",
                  py: 0.5,
                  px: 1,
                }}
              >
                <LightTooltip placement="right" title={open ? "" : item.title}>
                  <ListItemButton
                    onClick={item.onClick}
                    sx={{
                      height: 40,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      // backgroundColor: pathname === item.href && "#FFFFFF59",
                      borderRadius: 1.5,
                      width: "100%",

                      "& .MuiListItemIcon-root": {
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                        color: "#FFFFFF",
                      },
                      "& .MuiListItemText-root": {
                        opacity: open ? 1 : 0,
                        color: "#FFFFFF",
                        "& .MuiListItemText-primary": {
                          fontWeight: "500",
                          fontSize: 15,
                          fontFamily:
                            '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                        },
                      },
                      "&:hover": {
                        backgroundColor: "#FFFFFF59",
                      },
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>

                    <ListItemText primary={item.title} />
                    {item.value ? (
                      <ExpandLess
                        sx={{
                          color: "#FFFFFF",
                        }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          color: "#FFFFFF",
                        }}
                      />
                    )}
                  </ListItemButton>
                </LightTooltip>
              </ListItem>

              <Collapse in={item.value} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.hrefs.map((item) => (
                    <ListItem
                      key={item.title}
                      disablePadding
                      as={Link}
                      to={item.href}
                      selected={pathname === item.href}
                      sx={{
                        display: "block",
                        paddingBottom: 0.5,
                        px: open ? 3.5 : 1,
                      }}
                    >
                      <LightTooltip
                        placement="right"
                        title={open ? "" : item.title}
                      >
                        <ListItemButton
                          sx={{
                            height: 40,
                            justifyContent: open ? "initial" : "center",
                            px: open ? 4 : 3,
                            backgroundColor:
                              pathname === item.href && "#FFFFFF59",
                            borderRadius: 1.5,
                            width: "100%",

                            "& .MuiListItemIcon-root": {
                              minWidth: 0,
                              mr: open ? 1 : "auto",
                              justifyContent: "center",
                              color: "#FFFFFF",
                            },
                            "& .MuiListItemText-root": {
                              opacity: open ? 1 : 0,
                              color: "#FFFFFF",
                              "& .MuiListItemText-primary": {
                                fontWeight: "500",
                                fontSize: 15,
                                fontFamily:
                                  '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                              },
                            },
                            "&:hover": {
                              backgroundColor: "#FFFFFF59",
                            },
                          }}
                        >
                          {open ? (
                            <ListItemText primary={item.title} />
                          ) : (
                            <ListItemIcon>{item.icon}</ListItemIcon>
                          )}
                        </ListItemButton>
                      </LightTooltip>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </Drawer>

      {/* === VISUALIZACIÓN DE LOS LAYOUT CON SUS RESPECTIVOS COMPONENTES, CORRESPONDIENTE A CADA LINK */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <div className="content">{children}</div>
      </Box>
    </Box>
  );
}
