// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import { MenuItem, Grid } from "@mui/material";

import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

import { Loader } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

// IMPORTACIÓN DE ESTILOS
import "./AddEditInsumoForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { useFormik } from "formik";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { useInsumo, useCategoria } from "../../../../hooks";

export function AddEditInsumoForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA InsumosAdmin.js
  const { onClose, onRefetch, insumo } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { updateInsumo } = useInsumo();
  const { getCategorias, categorias } = useCategoria();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = React.useState(false);

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getCategorias(), []); // FUNCIÓN PARA OBTENER TODAS LAS CATEGORÍAS CREADAS

  // DEFINICIÓN DEL FORMIK Y YUP PARA LA VALIDACIÓN DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(insumo),
    validationSchema: Yup.object(Schame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        setLoading(true);
        await updateInsumo(insumo.id, formValue); // SOLO SE PERMITE ACTUALIZAR LOS DATOS DEL INSUMO
        onRefetch(); // REFRESCA LA PÁGINA InsumosAdmin.js
        onClose(); // CIERRA EL MODAL
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form className="add-edit-insumo-form" onSubmit={formik.handleSubmit}>
      {/* ============================ CATEGORÍA ============================ */}
      {categorias ? (
        <>
          <CssTextField
            name="categoria"
            select
            search="true"
            label="Categoría"
            disabled
            value={formik.values.categoria}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.categoria && formik.errors.categoria)}
            helperText={formik.touched.categoria && formik.errors.categoria}
            onBlur={formik.handleBlur}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          >
            {categorias?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.nombre}
              </MenuItem>
            ))}
          </CssTextField>
          {/* =================== GRILLA DE 2 COLUMNAS [CÓDIGO Y NÚMERO DE SERIE] ===================*/}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            {/* =============== CÓDIGO =============== */}
            <Grid item xs>
              <CssTextField
                label="Código del insumo"
                name="codigo"
                disabled
                value={formik.values.codigo}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.codigo && formik.errors.codigo)}
                helperText={formik.touched.codigo && formik.errors.codigo}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>

            {/* =============== NÚMERO DE SERIE =============== */}
            <Grid item xs>
              <CssTextField
                label="Número de serie"
                name="serie"
                // disabled
                value={formik.values.serie}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.serie && formik.errors.serie)}
                helperText={formik.touched.serie && formik.errors.serie}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>
          </Grid>

          {/* =================== GRILLA DE 2 COLUMNAS [MARCA Y MODELO] ===================*/}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            {/* =============== MARCA =============== */}
            <Grid item xs>
              <CssTextField
                label="Marca del insumo"
                name="marca"
                value={formik.values.marca}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.marca && formik.errors.marca)}
                helperText={formik.touched.marca && formik.errors.marca}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>

            {/* =============== MODELO =============== */}
            <Grid item xs>
              <CssTextField
                label="Modelo del insumo"
                name="modelo"
                value={formik.values.modelo}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.modelo && formik.errors.modelo)}
                helperText={formik.touched.modelo && formik.errors.modelo}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>
          </Grid>

          {/* =================== GRILLA DE 2 COLUMNAS [DATOS TÉCNICOS Y OBSERVACIONES] ===================*/}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            {/* =============== DATOS TÉCNICOS =============== */}
            <Grid item xs>
              <CssTextField
                label="Datos técnicos"
                name="datos_tecnicos"
                value={formik.values.datos_tecnicos}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.datos_tecnicos && formik.errors.datos_tecnicos
                )}
                helperText={
                  formik.touched.datos_tecnicos && formik.errors.datos_tecnicos
                }
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                multiline
                rows={4}
              />
            </Grid>

            {/* =============== OBSERVACIONES =============== */}
            <Grid item xs>
              <CssTextField
                label="Observaciones"
                name="observaciones"
                value={formik.values.observaciones}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.observaciones && formik.errors.observaciones
                )}
                helperText={
                  formik.touched.observaciones && formik.errors.observaciones
                }
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                multiline
                rows={4}
              />
            </Grid>
          </Grid>

          {/* =============== TIEMPO DE MANTENIMIENTO =============== */}
          <CssTextField
            id="outlined-select-currency"
            name="tiempo_mantenimiento"
            select
            search="true"
            label="Tiempo de mantenimiento"
            value={formik.values.tiempo_mantenimiento}
            onChange={formik.handleChange}
            error={Boolean(
              formik.touched.tiempo_mantenimiento &&
                formik.errors.tiempo_mantenimiento
            )}
            helperText={
              formik.touched.tiempo_mantenimiento &&
              formik.errors.tiempo_mantenimiento
            }
            onBlur={formik.handleBlur}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          >
            <MenuItem value={1}>MENSUAL</MenuItem>
            <MenuItem value={2}>BIMESTRAL</MenuItem>
            <MenuItem value={3}>TRIMESTRAL</MenuItem>
            <MenuItem value={6}>SEMESTRAL</MenuItem>
            <MenuItem value={12}>ANUAL</MenuItem>
          </CssTextField>

          <hr />
          {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="center"
            columnSpacing={{ md: 1.5 }}
          >
            <Grid item>
              <CssButtonCancelar
                startIcon={<CloseIcon fontSize="large" />}
                onClick={onClose}
              >
                Cancelar
              </CssButtonCancelar>
            </Grid>

            <Grid item>
              <CssLoadingButton
                endIcon={
                  insumo ? (
                    <ReplaySharpIcon fontSize="large" />
                  ) : (
                    <AddTaskOutlinedIcon fontSize="large" />
                  )
                }
                variant="contained"
                loading={loading}
                type="submit"
              >
                {insumo ? "Actualizar" : "Crear"}
              </CssLoadingButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      )}
    </form>
  );
}

// INICIALIZACIÓN DE LOS VALORES INICIALES DEL FORMULARIO
function initialValues(data) {
  return {
    marca: data?.marca || "",
    modelo: data?.modelo || "",
    serie: data?.serie || "",
    codigo: data?.codigo || "",
    datos_tecnicos: data?.datos_tecnicos || "",
    observaciones: data?.observaciones || "",
    categoria: data?.categoria || "",
    tiempo_mantenimiento: data?.tiempo_mantenimiento || "",
    cantidad: 1,
  };
}

// VALIDACIÓN DE LOS CAMPOS DEL FORMULARIO PARA INSUMOS NO DESECHABLES
function Schame() {
  return {
    marca: Yup.string().required("Ingrese la marca"),
    modelo: Yup.string(),
    serie: Yup.string(),
    codigo: Yup.string().required("Ingrese el código"),
    datos_tecnicos: Yup.string(),
    observaciones: Yup.string(),
    categoria: Yup.number().required(
      "Ingrese la categoría a la que pertenece el insumo"
    ),
    tiempo_mantenimiento: Yup.number().required(
      "Ingrese el tiempo de mantenimiento"
    ),
  };
}
