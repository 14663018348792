// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CssTablePagination } from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./TableProveedor.scss";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE PROVEEDORES
const columns = [
  { id: "id", label: "", minWidth: 50, width: 50 },
  { id: "nombre", label: "Nombre" },
  { id: "descripcion", label: "Descripción" },
  { id: "direccion", label: "Dirección" },
  { id: "telefono", label: "Teléfono" },
  { id: "email", label: "Correo electrónico" },
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
    // minWidth: 200,
  },
];

export function TableProveedor(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA ProveedorAdmin.js
  const { proveedores, updateProveedor, deleteProveedor } = props;

  // CREACIÓN DE LAS VARIABELS DE ESTADOS
  const [page, setPage] = React.useState(0); // PARA EL MANEJO DE LA PAGINACIÓN
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // CANTIDAD DE FILAS A MOSTRAR

  // FUNCIÓN PARA EL CAMBIO DE PÁGINA DE LA TABLA
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FUNCIÓN PARA CAMBIAR LA CANTIDAD DE FILAS A MOSTRAR
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      {proveedores ? (
        // DETALLES DE LOS PROVEEDORES
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 340 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              className="table-proveedor-admin"
            >
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      colSpan={column.colSpan}
                      style={{
                        width: column.width,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "500",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {proveedores
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((proveedor, index) => {
                    return (
                      <TableRow tabIndex={-1} key={proveedor.id}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {index + 1 + page * rowsPerPage}
                          {/* {proveedor.id} */}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {proveedor.nombre}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {proveedor.descripcion.length > 30
                            ? proveedor.descripcion.substr(0, 30) + "..."
                            : proveedor.descripcion
                            ? proveedor.descripcion
                            : "S/D"}
                          {/* {proveedor.descripcion} */}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {proveedor.direccion ? proveedor.direccion : "-"}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {proveedor.telefono ? proveedor.telefono : "-"}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {proveedor.email ? proveedor.email : "-"}
                        </TableCell>

                        <Actions
                          proveedor={proveedor}
                          updateProveedor={updateProveedor}
                          deleteProveedor={deleteProveedor}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* == CONTROL DE LA PAGINACIÓN ======================================== */}
          <CssTablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={proveedores.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Proveedores por página:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES DE ACTUALIZAR O ELIMINAR DATOS DE UN PROVEEDOR
function Actions(props) {
  const { proveedor, updateProveedor, deleteProveedor } = props;

  return (
    <TableCell align="center" sx={{ py: FONTSIZE.paddingCell }}>
      {/* == BOTÓN PARA MODIFICAR DATOS DE UN PROVEEDOR =================== */}
      <Tooltip title="Editar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => updateProveedor(proveedor)}
          type="button"
        >
          <EditIcon color="info" />
        </IconButton>
      </Tooltip>
      {/* == BOTÓN PARA ELIMINAR UN PROVEEDOR ============================= */}
      <Tooltip title="Eliminar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => deleteProveedor(proveedor)}
          type="button"
        >
          <DeleteForeverIcon color="error" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
