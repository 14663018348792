// IMPORTACIÓN DE REACT
import React, { useState } from "react";

// IMPORTACIÓN DE COMPONENTES
import { InputAdornment, IconButton } from "@mui/material";
import { CssTextField, CssLoadingButton } from "../../Common/componentesCssMui";

import { toast, Flip } from "react-toastify";

// IMPORTACIÓN DE ICONOS
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// IMPORTACIÓN DE ESTILOS
import "./LoginForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { useFormik } from "formik";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { loginApi } from "../../../api/user";
import { useAuth } from "../../../hooks";

export function LoginForm() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { login } = useAuth();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showPassword, setShowPassword] = useState(false);

  // FUNCIÓN AUXILIAR PARA VER LA CONTRASEÑA
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // DEFINICIÓN DE FORMIK Y YUP PARA LA VALIDACIÓN DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(),
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      try {
        // CONVERSIÓN DE DATOS IMPORTANTES A MINÚSCULA
        formValue.email = formValue.email.toLowerCase();

        const response = await loginApi(formValue);
        const { access } = response;
        login(access);

        // SI EL LOGIN ES CORRECTO SE MUESTRA UN MENSAJE MEDIANTE EL TOAST
        toast.success("Ingreso Exitoso", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          transition: Flip,
        });
      } catch (error) {
        // SI EL LOGIN NO ES CORRECTO SE MUESTRA UN MENSAJE DE ERROR MEDIANTE EL TOAST
        // console.log("ERROR");
        // console.log(error);
        toast.error(error.message);
      }
    },
  });
  return (
    <form className="login-form-admin" onSubmit={formik.handleSubmit}>
      {/* === CORREO ELECTRÓNICO ========================================= */}
      <CssTextField
        // inputProps={{ style: { textTransform: "lowercase" } }}
        label="Correo electrónico"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        margin="dense"
        onBlur={formik.handleBlur}
        variant="outlined"
        size="small"
      />

      {/* === CONTRASEÑA ================================================= */}
      <CssTextField
        label="Contraseña"
        name="password"
        type={showPassword ? "text" : "password"}
        value={formik.values.password}
        onChange={formik.handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        margin="dense"
        onBlur={formik.handleBlur}
        variant="outlined"
        size="small"
      />

      {/* === BOTÓN PARA INICIAR SESIÓN =================================== */}
      <CssLoadingButton
        variant="contained"
        loading={false}
        type="submit"
        fullWidth
        sx={{ mt: 2 }}
      >
        Iniciar sesión
      </CssLoadingButton>
    </form>
  );
}

// INICIALIZACIÓN DE LOS VALORES INICIALES DEL FORMULARIO PARA AÑADIR INSUMOS
function initialValues() {
  return {
    email: "",
    password: "",
  };
}

// VALIDACIÓN DE LOS CAMPOS DEL FORMULARIO
function validationSchema() {
  return {
    email: Yup.string()
      .email("Ingrese un correo electrónico valido")
      .required("Ingrese el correo electrónico"),
    password: Yup.string().required("Ingrese la contraseña"),
  };
}
