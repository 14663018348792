// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getPersonasApi,
  addPersonaApi,
  updatePersonaApi,
  deletePersonaApi,
} from "../api/persona";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from "./";

export function usePersona() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [personas, setPersonas] = useState(null); // PARA ALMACENAR LAS PERSONAS

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE PERSONAS
  const getPersonas = async (dni, ordering) => {
    try {
      setLoading(true);
      const response = await getPersonasApi(dni, ordering, auth.token);
      setLoading(false);
      setPersonas(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UNA NUEVA PERSONA
  const addPersona = async (data) => {
    try {
      setLoading(true);
      const resultado = await addPersonaApi(data, auth.token);
      setLoading(false);
      return resultado;
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log(error);
    }
  };

  // ACTUALIZACIÓN DE LOS DATOS DE UNA PERSONA
  const updatePersona = async (id, data) => {
    try {
      setLoading(true);
      await updatePersonaApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ELIMINACIÓN DE UNA PERSONA
  const deletePersona = async (id) => {
    try {
      setLoading(true);
      await deletePersonaApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getPersonas,
    personas,
    addPersona,
    updatePersona,
    deletePersona,
    loading,
    error,
  };
}
