// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER TRABAJADORES
export async function getTrabajadoresApi(
  dni = "",
  email = "",
  area = "",
  usuario = "",
  ordering = "",
  token
) {
  try {
    const filtroDni = `persona__num_doc=${dni}`;
    const filtroEmail = `usuario__email=${email}`;
    const filtroArea = `area=${area}`;
    const filtroUsuario = `usuario=${usuario}`;
    const filtroOrdering = `ordering=${ordering}`;
    const url = `${BASE_API}/api/trabajadores/?${filtroDni}&${filtroEmail}&${filtroArea}&${filtroUsuario}&${filtroOrdering}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER LOS DATOS DEL TRABAJADOR QUE SOLICITA UN ACOPIO, DESACOPIO O PRÉSTAMO
export async function getTrabajadorSolicitanteApi(usuario = "", token) {
  try {
    const filtroUsuario = `usuario=${usuario}`;
    const url = `${BASE_API}/api/trabajadores/?${filtroUsuario}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER LOS DATOS DEL TRABAJADOR QUE APRUEBA O DESAPRUEBA UNA SOLICITUD
export async function getTrabajadorActualizadorApi(usuario = "", token) {
  try {
    const filtroUsuario = `usuario=${usuario}`;
    const url = `${BASE_API}/api/trabajadores/?${filtroUsuario}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER LOS DATOS DEL TRABAJADOR QUE REGISTRA LA DEVOLUCIÓN
export async function getTrabajadorDevolucionApi(usuario = "", token) {
  try {
    const filtroUsuario = `usuario=${usuario}`;
    const url = `${BASE_API}/api/trabajadores/?${filtroUsuario}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AGREGAR UN NUEVO TRABAJADOR
export async function addTrabajadorApi(data, token) {
  try {
    const url = `${BASE_API}/api/trabajadores/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un error al añadir al trabajador, es probable que los campos ya estén asociado a otro trabajador",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente al trabajador", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LOS DATOS DE UN TRABAJADOR
export async function updateTrabajadorApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/trabajadores/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (response.status === 400) {
      toast.error(
        "Hubo un error al actualizar los datos del trabajador, es probable que los campos ya estén asociado a otro trabajador",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente los cambios", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ELIMINAR UN TRABAJADOR
export async function deleteTrabajadorApi(id, token) {
  try {
    const url = `${BASE_API}/api/trabajadores/${id}/`;

    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);

    if (response.status === 400) {
      toast.error("Hubo un error al eliminar los datos del trabajador", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
      });
    } else {
      toast.success("Se ha eliminado los datos del trabajador", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
