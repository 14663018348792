// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CssTablePagination } from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./TableArea.scss";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE ÁREAS
const columns = [
  { id: "id", label: "", minWidth: 50, width: 50 },
  { id: "nombre", label: "Nombre" },
  { id: "descripcion", label: "Descripción" },
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
    // minWidth: 200,
  },
];

export function TableArea(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA AreasAdmin.js
  const { areas, updateArea, deleteArea } = props;

  // CREACIÓN DE LAS VARIABELS DE ESTADOS
  const [page, setPage] = React.useState(0); // PARA EL MANEJO DE LA PAGINACIÓN
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // CANTIDAD DE FILAS A MOSTRAR

  // FUNCIÓN PARA EL CAMBIO DE PÁGINA DE LA TABLA
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FUNCIÓN PARA CAMBIAR LA CANTIDAD DE FILAS A MOSTRAR
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {areas ? (
        // DETALLES DE LAS ÁREAS
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 340 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              className="table-acopio-admin"
            >
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      colSpan={column.colSpan}
                      style={{
                        width: column.width,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "500",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {areas
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((area, index) => {
                    return (
                      <TableRow tabIndex={-1} key={area.id}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {index + 1 + page * rowsPerPage}
                          {/* {area.id} */}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {area.nombre}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {area.descripcion.length > 100
                            ? area.descripcion.substr(0, 100) + "..."
                            : area.descripcion
                            ? area.descripcion
                            : "S/D"}
                          {/* {area.descripcion} */}
                        </TableCell>

                        <Actions
                          area={area}
                          updateArea={updateArea}
                          deleteArea={deleteArea}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* == CONTROL DE LA PAGINACIÓN ======================================== */}
          <CssTablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={areas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Áreas por página:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES DE ACTUALIZAR O ELIMINAR DATOS DE UNA ÁREA
function Actions(props) {
  const { area, updateArea, deleteArea } = props;

  return (
    <TableCell align="center" sx={{ py: FONTSIZE.paddingCell }}>
      {/* == BOTÓN PARA MODIFICAR DATOS DE UNA ÁREA ============================= */}
      <Tooltip title="Editar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => updateArea(area)}
          type="button"
        >
          <EditIcon color="info" />
        </IconButton>
      </Tooltip>
      {/* == BOTÓN PARA ELIMINAR UNA ÁREA ====================================== */}
      <Tooltip title="Eliminar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => deleteArea(area)}
          type="button"
        >
          <DeleteForeverIcon color="error" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
