// IMPORTACIÓN DE REACT
import React from "react";
import { Link } from "react-router-dom";

// IMPORTACIÓN DE COMPONENTES
import { Box, Button, Container, Typography } from "@mui/material";

// IMPORTACIÓN DE ICONOS
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// IMPORTACIÓN DE ESTILOS
import "./Error404.scss";

export function Error404() {
  return (
    <Box
      className="error404"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100%",
      }}
    >
      <Container maxWidth="md" className="error404__content">
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* ======================= TEXTOS ======================= */}
          <div className="error404__content__logo">
            <b>SAME</b>
          </div>

          <Typography align="center" color="red" variant="h1">
            Error: 404
          </Typography>

          <Typography align="center" color="white" variant="h4">
            La página que intenta acceder no fue encontrada
          </Typography>

          {/* ================== BOTÓN PARA REGRESAR ================ */}
          <Link to="/">
            <Button
              className="error404__content__button"
              startIcon={<ArrowBackIcon fontSize="small" />}
              variant="contained"
              sx={{
                background: "#296686",
                "&:hover": {
                  background: "#26788b",
                },
              }}
            >
              Volver a la página principal
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
