// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER LAS CATEGORÍAS, SE PUEDE FILTRAR LA OBTENCIÓN DE LOS REGISTROS
export async function getCategoriasApi(
  desechable = "",
  nombre = "",
  estado_stock = "",
  id = "",
  ordering = "",
  token
) {
  try {
    const desechableFilter = `desechable=${desechable}`;
    const nombreFilter = `nombre=${nombre}`;
    const stockFilter = `estado_stock=${estado_stock}`;
    const idFilter = `id=${id}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/categorias/?${desechableFilter}&${nombreFilter}&${stockFilter}&${idFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AGREGAR UNA NUEVA CATEGORÍA
export async function addCategoriaApi(data, token) {
  try {
    const url = `${BASE_API}/api/categorias/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un error al crear la categoría, es probable que el nombre de la categoría ya exista",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente la nueva categoría", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LOS DATOS DE UNA CATEGORÍA
export async function updateCategoriaApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/categorias/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un error al actualizar los datos de la categoría, es probable que el nombre de la categoría ya exista",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente los cambios", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ELIMINAR UNA CATEGORÍA
export async function deleteCategoriaApi(id, token) {
  try {
    const url = `${BASE_API}/api/categorias/${id}/`;
    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
