// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE PÁGINAS
import { LoginAdmin, MiniDrawer } from "../../pages/Admin";

// IMPORTACIÓN DE ESTILOS
import "./AdminLayout.scss";

// IMPORTACIÓN DE HOOKS CREADOS
import { useAuth } from "../../hooks";

export function AdminLayout(props) {
  // COMPONENTES QUE FORMAN PARTE DE LA PÁGINA OBTENIDA MEDIANTE PROPS
  const { children } = props;

  // DATOS DE LA AUTENTICACIÓN DEL USUARIO
  const { auth } = useAuth();

  // SI NO ESTA AUTENTICADO SE MUESTRA EL LOGIN
  if (!auth) return <LoginAdmin />;

  // SI ESTA AUTENTICADO SE MUESTRA SU RESPECTIVO MENÚ DE ACUERDO A SUS PERMISOS
  return (
    <div className="admin-layout">
      <MiniDrawer>{children}</MiniDrawer>
    </div>
  );
}
