// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER PROVEEDORES
export async function getProveedoresApi(
  id = "",
  nombre = "",
  email = "",
  ordering = "",
  token
) {
  try {
    const filterId = `id=${id}`;
    const filterNombre = `nombre=${nombre}`;
    const filterEmail = `email=${email}`;
    const filterOrdering = `ordering=${ordering}`;
    const url = `${BASE_API}/api/proveedores/?${filterId}&${filterNombre}&${filterEmail}&${filterOrdering}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AGREGAR UN NUEVO PROVEEDOR
export async function addProveedorApi(data, token) {
  try {
    const url = `${BASE_API}/api/proveedores/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un error al crear un nuevo proveedor, es probable que el nombre del proveedor ya exista",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente el nuevo proveedor", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LOS DATOS DE UN PROVEEDOR
export async function updateProveedorApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/proveedores/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un error al actualizar los datos del proveedor, es probable que el nombre del proveedor ya exista",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente los cambios", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ELIMINAR UN PROVEEDOR
export async function deleteProveedorApi(id, token) {
  try {
    const url = `${BASE_API}/api/proveedores/${id}/`;

    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);

    if (response.status === 400) {
      toast.error("Hubo un error al eliminar los datos del proveedor", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
      });
    } else {
      toast.success("Se ha eliminado los datos del proveedor", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
