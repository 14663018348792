// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Loader } from "semantic-ui-react";

import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import {
  HeaderPage,
  TableCategoriaAdmin,
  AddEditCategoriaForm,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE ICONOS
import { Search as SearchIcon } from "../../components/Admin/icons/search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// IMPORTACIÓN DE HOOKS CREADOS
import { useCategoria } from "../../hooks";

export function CategoriasAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { loading, categorias, getCategorias, deleteCategoria } =
    useCategoria();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [filtroId, setFiltroId] = useState(""); // PARA FILTRAR LA BÚSQUEDAA POR ID

  const [nombre, setNombre] = useState(""); // PARA EL TEXT FIELD DE NOMBRE
  const [filtroNombre, setFiltroNombre] = useState(""); // PARA FILTRAR LA BÚSQUEDA POR NOMBRE

  const [selectDesechable, setSelectDesechable] = useState("VER_TODOS"); // PARA EL TEXT FIELD
  const [filtroDesechable, setFiltroDesechable] = useState(""); // PARA FILTRAR LA BÚSQUEDA SI LA CATEGORÍA PERTENECE A INSUMO DESECHABLES O NO

  const [selectStock, setSelectStock] = useState("VER_TODOS"); // PARA EL TEXT FIELD
  const [filtroStock, setFiltroStock] = useState(""); // PARA FILTRAR POR EL ESTADO DEL STOCK

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  useEffect(
    () =>
      getCategorias(
        filtroDesechable,
        filtroNombre.toUpperCase(),
        filtroStock,
        filtroId,
        "nombre"
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, filtroId, filtroNombre, filtroDesechable, filtroStock]
  ); // FUNCIÓN PARA OBTENER LAS CATEGORÍAS CREADAS, CON ALGUNOS FILTROS

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // ============================== FUNCIONES PARA LA SECCIÓN DE FILTROS ==============================
  // PARA REALIZAR LA BÚSQUEDA DE UNA CATEGORÍA POR EL NOMBRE, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeNombre = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setFiltroNombre(event.target.value);
    }
    setNombre(event.target.value);
  };

  // PARA VISUALIZAR LAS CATEGORÍAS EN FUNCIÓN DEL ESTADO DEL STOCK
  const handleChangeStock = (event) => {
    setSelectStock(event.target.value);
    if (event.target.value === "VER_TODOS") {
      setFiltroStock("");
    } else {
      setFiltroStock(event.target.value);
    }
  };

  // PARA VISUALIZAR LAS CATEGORÍAS DE INSUMOS DESECHABLES O NO DESECHABLES
  const handleChangeDesechable = (event) => {
    setSelectDesechable(event.target.value);
    if (event.target.value === "VER_TODOS") {
      setFiltroDesechable("");
    } else {
      if (event.target.value === "DESECHABLE") {
        setFiltroDesechable(true);
      } else {
        setFiltroDesechable(false);
      }
    }
  };

  // PARA ELIMINAR TODOS LOS FILTROS
  const handleChangeFilter = () => {
    setFiltroId("");
    setFiltroNombre("");
    setNombre("");
    setSelectDesechable("VER_TODOS");
    setFiltroDesechable("");
    setSelectStock("VER_TODOS");
    setFiltroStock("");
  };
  // =======================================================================================================

  // FUNCIÓN PARA AÑADIR UNA NUEVA CATEGORÍA, SE ABRIRÁ EL MODAL
  const addCategoria = () => {
    setTitleModal("Nueva categoría");
    setContentModal(
      <AddEditCategoriaForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ACTUALIZAR LOS VALORES DE UNA CATEGORÍA, SE ABRIRÁ EL MODAL
  const updateCategoria = (data) => {
    setTitleModal("Actualizar datos de la categoría");
    setContentModal(
      <AddEditCategoriaForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        categoria={data}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UNA CATEGORÍA CREADA
  const onDeleteCategoria = async (data) => {
    const result = window.confirm(`¿Eliminar categoría: ${data.nombre}?`);
    if (result) {
      try {
        await deleteCategoria(data.id);
        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Categorías de insumos"
        subheader="En esta sección se encuentran todas las categorías creadas para los insumos."
        btnTitle="Añadir nueva categoría"
        btnClick={addCategoria}
      />

      {/* =================== SECCIÓN DE FILTROS ==================  */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* ======================= BÚSQUEDA POR NOMBRE ======================= */}
            <Grid item md={3}>
              <CssTextField
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                value={nombre}
                onKeyDown={handleChangeNombre}
                placeholder="Buscar por nombre"
                variant="outlined"
                onChange={handleChangeNombre}
                fullWidth
                sx={{ marginTop: 1 }}
              />
            </Grid>

            {/* ========== MOSTRAR INSUMOS DESECHABLES O NO DESECHABLES ========== */}
            <Grid item md={3}>
              <CssTextField
                select
                search="true"
                label="Filtrar por insumos"
                value={selectDesechable}
                onChange={handleChangeDesechable}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"DESECHABLE"}>INSUMOS DESECHABLES</MenuItem>
                <MenuItem value={"NO_DESECHABLE"}>
                  INSUMOS NO DESECHABLES
                </MenuItem>
                <MenuItem value={"VER_TODOS"}>VER TODOS</MenuItem>
              </CssTextField>
            </Grid>

            {/* ======= MOSTRAR INSUMOS DE ACUERDO AL ESTADO DE SU STOCK  ======= */}
            <Grid item md={3}>
              <CssTextField
                select
                search="true"
                label="Filtrar por stock"
                value={selectStock}
                onChange={handleChangeStock}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"EN_STOCK"}>EN STOCK</MenuItem>
                <MenuItem value={"LIMITADO"}>STOCK LIMITADO</MenuItem>
                <MenuItem value={"CRITICO"}>STOCK CRÍTICO</MenuItem>
                <MenuItem value={"VER_TODOS"}>VER TODOS</MenuItem>
              </CssTextField>
            </Grid>

            {/* ====================== LIMPIAR FILTROS ====================== */}
            <Grid item sx={{ marginTop: 0.25 }}>
              <Tooltip title="Limpiar filtro">
                <IconButton size="large" onClick={handleChangeFilter}>
                  <FilterAltOffIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* ========= CARGA DE LA TABLA DE CATEGORÍAS ========= */}
          {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
            <TableCategoriaAdmin
              categorias={categorias}
              updateCategoria={updateCategoria}
              deleteCategoria={onDeleteCategoria}
            />
          )}
        </CardContent>
      </Card>

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}
