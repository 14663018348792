// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Grid, Autocomplete } from "@mui/material";
import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

// IMPORTACIÓN DE ESTILOS
import "./AddEditPrestamoForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { map } from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";

export function AddEditPrestamoForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA PrestamosAdmin.js
  const { onClose, insumo, prestamo, setPrestamo, index, categorias } = props;

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading] = React.useState(false);

  const [categoriasFormat, setCategoriasFormat] = useState([]); // SETEA LOS DATOS DE CATEGORÍAS

  const [valueCategoria, setValueCategoria] = useState(null); // PARA EL VALOR DEL AUTOCOMPLETE
  const [inputCategoria, setInputCategoria] = useState(""); // PARA EL VALOR DE LA OPCIÓN SELECCIONADA DEL AUTOCOMPLETE

  // CREACIÓN DE LOS useEffect
  useEffect(
    () => setCategoriasFormat(formatCategorias(categorias)),
    [categorias]
  ); // SETEO DE LOS DATOS DE LAS CATEGORÍAS

  // DEFINICIÓN DEL FORMIK Y YUP PARA LA VALIDACIÓN DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(insumo),
    validationSchema: Yup.object(Schame()),
    validateOnChange: false,
    onSubmit: (formValue) => {
      try {
        // SE VERIFICA SI RECIBIMOS UN INSUMO MEDIANTE PROPS
        if (insumo) {
          // SI SE RECIBE UN INSUMO MEDIANTE PROPS
          var i = 0;
          let index_busqueda = -1;
          // SE VERIFICA SI SE CAMBIO LA CATEGORÍA DEL INSUMO
          if (prestamo[index].nombre !== formValue.nombre) {
            // SI SE CAMBIO LA CATEGORÍA DEL INSUMO
            // SE PROCEDE A BUSCAR SI YA SE ENCUENTRA EN LA LISTA
            for (i = 0; i < prestamo.length; i++) {
              if (prestamo[i].nombre === formValue.nombre) {
                index_busqueda = i;
                break;
              }
            }
          }

          // RESULTADO DE RECORRER TODA LA LISTA
          if (index_busqueda !== -1) {
            // SI EL INSUMO EXISTE SE SUMA A LA CANTIDAD ANTERIOR LA NUEVA CANTIDAD
            let newState = [...prestamo];
            newState[index_busqueda].cantidad_solicitada =
              newState[index_busqueda].cantidad_solicitada +
              formValue.cantidad_solicitada;

            setPrestamo([
              ...newState.slice(0, index),
              ...newState.slice(index + 1, prestamo.length),
            ]);
          } else {
            // SI EL INSUMO NO EXISTE SE ACTUALIZA LOS DATOS DEL INSUMO
            const newState = [...prestamo];
            newState[index] = formValue;
            setPrestamo(newState);
          }
        } else {
          // SI NO SE RECIBE UN INSUMO MEDIANTE PROPS
          let index_busqueda = -1;
          // SE PROCEDE A BUSCAR SI YA SE ENCUENTRA EN LA LISTA
          for (i = 0; i < prestamo.length; i++) {
            if (prestamo[i].nombre === formValue.nombre) {
              index_busqueda = i;
              break;
            }
          }
          // RESULTADO DE RECORRER TODA LA LISTA
          if (index_busqueda !== -1) {
            // SI EL INSUMO EXISTE SE SUMA A LA CANTIDAD ANTERIOR LA NUEVA CANTIDAD
            let newState = [...prestamo];
            newState[index_busqueda].cantidad_solicitada =
              newState[index_busqueda].cantidad_solicitada +
              formValue.cantidad_solicitada;
            setPrestamo(newState);
          } else {
            // SI EL INSUMO NO EXISTE SE AGREGA A LA LISTA
            setPrestamo(prestamo.concat(formValue));
          }
        }
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  // FUNCIÓN PARA LA SELECCIÓN DEL NOMBRE DE LA CATEGORÍA A LA QUE PERTENECE EL INSUMO
  const handleChange = (event, newValue) => {
    setValueCategoria(newValue);
    formik.setFieldValue("id", newValue?.value);
    for (var i = 0; i < categorias.length; i++) {
      if (categorias[i].id === newValue?.value) {
        formik.setFieldValue("nombre", categorias[i].nombre);
        break;
      }
    }
  };

  return (
    <form className="add-edit-desechable-form" onSubmit={formik.handleSubmit}>
      {/* ====================== SELECCIÓN DE LA CATEGORÍA ====================== */}
      {categoriasFormat.length > 0 && (
        <Autocomplete
          value={
            insumo && valueCategoria === null
              ? categoriasFormat.find(
                  (categoria) => categoria.label === insumo.nombre
                )
              : valueCategoria
          }
          onChange={(event, newValue) => {
            handleChange(event, newValue);
          }}
          inputValue={inputCategoria}
          onInputChange={(event, newInputValue) => {
            setInputCategoria(newInputValue);
          }}
          options={categoriasFormat}
          renderInput={(params) => (
            <CssTextField
              {...params}
              label="Seleccione la categoría del insumo"
              size="small"
            />
          )}
        />
      )}

      {/* ====================== SE VERIFICA SI SE SELECCIONO UNA CATEGORÍA ====================== */}
      {formik.values.id !== "" ? (
        <>
          {/* ====== SOLO SE LE PIDE QUE INGRESE LA CANTIDAD DE INSUMOS ======= */}
          <CssTextField
            label="Cantidad de insumos"
            name="cantidad_solicitada"
            value={formik.values.cantidad_solicitada}
            inputProps={{
              step: 1,
              min: 1,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
            onChange={formik.handleChange}
            error={Boolean(
              formik.touched.cantidad_solicitada &&
                formik.errors.cantidad_solicitada
            )}
            helperText={
              formik.touched.cantidad_solicitada &&
              formik.errors.cantidad_solicitada
            }
            onBlur={formik.handleBlur}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
            sx={{ mt: 2 }}
          />

          <hr />
          {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="center"
            columnSpacing={{ md: 1.5 }}
          >
            <Grid item>
              <CssButtonCancelar
                startIcon={<CloseIcon fontSize="large" />}
                onClick={onClose}
              >
                Cancelar
              </CssButtonCancelar>
            </Grid>

            <Grid item>
              <CssLoadingButton
                endIcon={
                  insumo ? (
                    <ReplaySharpIcon fontSize="large" />
                  ) : (
                    <AddTaskOutlinedIcon fontSize="large" />
                  )
                }
                variant="contained"
                loading={loading}
                type="submit"
              >
                {insumo ? "Actualizar" : "Añadir"}
              </CssLoadingButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <hr></hr>
          {/* ================ BOTÓN PARA CANCELAR Y CERRAR EL MODAL ================ */}
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="center"
            columnSpacing={{ md: 1.5 }}
          >
            <Grid item>
              <Grid item>
                <CssButtonCancelar
                  startIcon={<CloseIcon fontSize="large" />}
                  onClick={onClose}
                >
                  Cancelar
                </CssButtonCancelar>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
}

// FUNCIÓN PARA SETEAR LOS DATOS DE LAS CATEGORÍAS
function formatCategorias(data) {
  return map(data, (item) => ({
    key: item.id,
    label: item.nombre,
    value: item.id,
  }));
}

// INICIALIZACIÓN DE LOS VALORES INICIALES DEL FORMULARIO DE INSUMOS
function initialValues(data) {
  return {
    id: data?.id || "",
    nombre: data?.nombre || "",
    cantidad_solicitada: data?.cantidad_solicitada || 1,
  };
}

// VALIDACIÓN DE LOS CAMPOS DEL FORMULARIO DE INSUMOS
function Schame() {
  return {
    id: Yup.number().required("Ingrese la categoría del insumos"),
    cantidad_solicitada: Yup.number().required(
      "Ingrese la cantidad de insumos"
    ),
  };
}
