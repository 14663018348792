// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getInsumosApi,
  addInsumoApi,
  updateInsumoApi,
  deleteInsumoApi,
} from "../api/insumos";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from ".";

export function useInsumo() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [insumos, setInsumos] = useState(null); // PARA ALMACENAR LOS INSUMOS

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE INSUMOS
  const getInsumos = async (codigo, categoria, disponible, ordering) => {
    try {
      setLoading(true);
      const response = await getInsumosApi(
        codigo,
        categoria,
        disponible,
        ordering,
        auth.token
      );
      setLoading(false);
      setInsumos(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UN NUEVO INSUMO
  const addInsumo = async (data) => {
    try {
      setLoading(true);
      await addInsumoApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DE LOS DATOS DE UN INSUMO
  const updateInsumo = async (id, data) => {
    try {
      setLoading(true);
      await updateInsumoApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ELIMINACIÓN DE UN INSUMO
  const deleteInsumo = async (id) => {
    try {
      setLoading(true);
      await deleteInsumoApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getInsumos,
    insumos,
    addInsumo,
    updateInsumo,
    deleteInsumo,
    loading,
    error,
  };
}
