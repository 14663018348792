// IMPORTACIÓN DE REACT
import React, { useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  Box,
  Card,
  Grid,
  Divider,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CssListItemText,
  CssButtonRegresar,
  CssTypography,
} from "../../../Common/componentesCssMui";

import { Loader } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import { SeverityPill } from "../../severity-pill";

// IMPORTACIÓN DE HOOKS CREADOS
import { usePresupuesto, useAcopio } from "../../../../hooks";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import moment from "moment";

export function DetallesPresupuesto(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA PresupuestosAdmin.js
  const { presupuesto, onClose } = props;

  // NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE MODIFICACIÓN PRESUPUESTARIA
  const columnsModificacionPresupuestaria = [
    { id: "nro1", label: "", minWidth: 50 },
    { id: "tipo", label: "Tipo", align: "center", minWidth: 250 },
    {
      id: "monto",
      label: "Monto",
      minWidth: 150,
    },
    {
      id: "descripcion",
      label: "Descripción",
    },
  ];

  // NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE ACOPIO
  const columnsAcopio = [
    { id: "nro2", label: "", minWidth: 50 },
    { id: "id", label: "ID acopio", align: "center" },
    {
      id: "fecha_hora_solicitud",
      label: "Fecha de realización",
      align: "center",
    },
    { id: "codigo", label: "Código de factura", align: "center" },
    { id: "monto", label: "Monto", align: "center" },
  ];

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { getModificacionesByPresupuesto, modificacionPresupuestaria } =
    usePresupuesto();

  const { acopios, getAcopiosByPresupuesto } = useAcopio();
  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getModificacionesByPresupuesto(presupuesto.id), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAcopiosByPresupuesto(presupuesto.id), []);

  // FUNCIÓN PARA CALCULAR EL MONTO TOTAL DE LA AMPLIACIÓN PRESUPUESTARIA
  function totalAmpliacion(ampliacion) {
    let cantidad = 0;
    for (let i = 0; i < ampliacion?.length; i++) {
      if (ampliacion[i].tipo === "AMPLIACION") {
        cantidad = cantidad + ampliacion[i].monto;
      }
    }
    return cantidad;
  }

  // FUNCIÓN PARA CALCULAR EL MONTO TOTAL DE LA REDUCCIÓN PRESUPUESTARIA
  function totalReduccion(reduccion) {
    let cantidad = 0;
    for (let i = 0; i < reduccion?.length; i++) {
      if (reduccion[i].tipo === "REDUCCION") {
        cantidad = cantidad + reduccion[i].monto;
      }
    }
    return cantidad;
  }

  // FUNCIÓN PARA CALCULAR EL TOTAL DE ACOPIOS QUE FORMAN PARTE DEL PRESUPUESTO
  function totalAcopio(acopio) {
    let cantidad = 0;
    for (let i = 0; i < acopio?.length; i++) {
      cantidad = cantidad + acopio[i].total_compra;
    }
    return cantidad;
  }

  return (
    <form>
      {/* ====================== SE VERIFICA SI YA SE OBTUVO TODAS LAS MODIFICACIONES PRESUPUESTARIA ====================== */}
      {!modificacionPresupuestaria && !acopios ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <>
          {/* == DETALLES DEL PRESUPUESTO ====================================== */}
          <Card
            style={{
              border: "1px solid",
              borderColor: "#2C6975",
              boxShadow: "none",
            }}
            sx={{ px: 1, py: 0.5 }}
          >
            <Typography sx={{ fontSize: FONTSIZE.tableCell, px: 1 }}>
              <b>ID: {presupuesto.id}</b>
            </Typography>

            <Divider sx={{ borderColor: "#2C6975" }}></Divider>

            <CssListItemText
              primary={
                <>
                  <b>Presupuesto: </b>
                  {presupuesto.anio}
                </>
              }
              secondary={
                <>
                  <b>Descripción: </b> {presupuesto.descripcion}
                </>
              }
              sx={{ px: 1 }}
            />
            <Divider sx={{ borderColor: "#2C6975" }}></Divider>

            <Grid
              container
              direction="row"
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              sx={{ px: 1 }}
            >
              <Grid item xs>
                <CssListItemText
                  primary={
                    <>
                      <b>Monto inicial: </b>
                      {"$ "}
                      {presupuesto.monto_inicial}
                    </>
                  }
                  sx={{ mb: 0 }}
                />
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                style={{ borderColor: "#2C6975" }}
                sx={{ mt: 0.5 }}
              />

              <Grid item xs>
                <CssListItemText
                  primary={
                    <>
                      <b>Monto actual: </b>
                      {"$ "}
                      {presupuesto.monto_actual}
                    </>
                  }
                  secondary={
                    <>
                      <b>Monto gastado: </b>
                      {"$ "}
                      {presupuesto.monto_gastado}
                    </>
                  }
                  sx={{ mb: 0 }}
                />
              </Grid>
            </Grid>
          </Card>

          {modificacionPresupuestaria?.length > 0 && (
            <>
              <Divider sx={{ borderColor: "#2C6975", mt: 2, mb: 0.7 }} />

              <CssTypography align="center" sx={{ pb: 0.5 }}>
                Lista de modificaciones presupuestarias
              </CssTypography>

              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 300 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    size="small"
                    className="table-acopio-admin"
                  >
                    {/* == ENCABEZADO DE LA TABLA ======================================== */}
                    <TableHead>
                      <TableRow>
                        {columnsModificacionPresupuestaria.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            colSpan={column.colSpan}
                            style={{
                              width: column.minWidth,
                              minWidth: column.minWidth,
                              background: "#2C6975",
                              fontSize: FONTSIZE.tableRow,
                              // fontWeight: "500",
                              color: "#FFFFFF",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* == CONTENIDO DE LA TABLA ======================================== */}
                    <TableBody>
                      {modificacionPresupuestaria?.map(
                        (modificacion, index) => {
                          return (
                            <TableRow tabIndex={-1} key={index}>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                {index + 1}
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                <SeverityPill
                                  color={
                                    modificacion.tipo === "AMPLIACION"
                                      ? "success"
                                      : "error"
                                  }
                                >
                                  {modificacion.tipo === "AMPLIACION"
                                    ? "AMPLIACIÓN"
                                    : "REDUCCIÓN"}
                                </SeverityPill>
                              </TableCell>

                              <TableCell
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                {"$ "}
                                {modificacion.monto}
                              </TableCell>

                              <TableCell
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                {modificacion.descripcion}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              {/* == DETALLES DE LA MODIFICACIÓN PRESUPUESTARIA ====================================== */}
              <Card
                style={{
                  border: "1px solid",
                  borderColor: "#2C6975",
                  boxShadow: "none",
                }}
                sx={{ px: 1, py: 0.5, mt: 1 }}
              >
                <Grid
                  container
                  direction="row"
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  sx={{ px: 1 }}
                >
                  <Grid item xs>
                    <CssListItemText
                      primary={
                        <>
                          <b>Total ampliación: </b>
                          {"$ "}
                          {totalAmpliacion(modificacionPresupuestaria)}
                        </>
                      }
                      sx={{ mb: 0 }}
                    />
                  </Grid>

                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ borderColor: "#2C6975" }}
                    sx={{ mt: 0.5 }}
                  />

                  <Grid item xs>
                    <CssListItemText
                      primary={
                        <>
                          <b>Total reducción: </b>
                          {"$ "}
                          {totalReduccion(modificacionPresupuestaria)}
                        </>
                      }
                    />
                  </Grid>

                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ borderColor: "#2C6975" }}
                    sx={{ mt: 0.5 }}
                  />

                  <Grid item xs>
                    <CssListItemText
                      primary={
                        <>
                          <b>Diferencia: </b>
                          {"$ "}
                          {totalAmpliacion(modificacionPresupuestaria) -
                            totalReduccion(modificacionPresupuestaria)}
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </Card>
            </>
          )}

          {acopios?.length > 0 && (
            <>
              <Divider sx={{ borderColor: "#2C6975", mt: 2, mb: 0.7 }} />
              <CssTypography align="center" sx={{ pb: 0.5 }}>
                Lista de acopios perteneciente al presupuesto
              </CssTypography>

              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 300 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    size="small"
                    className="table-acopio-admin"
                  >
                    {/* == ENCABEZADO DE LA TABLA ======================================== */}
                    <TableHead>
                      <TableRow>
                        {columnsAcopio.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            colSpan={column.colSpan}
                            style={{
                              width: column.minWidth,
                              minWidth: column.minWidth,
                              background: "#2C6975",
                              fontSize: FONTSIZE.tableRow,
                              // fontWeight: "500",
                              color: "#FFFFFF",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* == CONTENIDO DE LA TABLA ======================================== */}
                    <TableBody>
                      {acopios?.map((acopio, index) => {
                        return (
                          <TableRow tabIndex={-1} key={index}>
                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {acopio.id}
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{ py: FONTSIZE.paddingCell }}
                            >
                              <CssListItemText
                                primary={moment(acopio.fecha_hora_realizacion)
                                  .locale("es")
                                  .format("DD/MM/YYYY, HH:mm ")}
                              />
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {acopio.codigo_factura}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {"$ "}
                              {acopio.total_compra}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              {/* == DETALLES DE LOS ACOPIOS ====================================== */}
              <Card
                style={{
                  border: "1px solid",
                  borderColor: "#2C6975",
                  boxShadow: "none",
                }}
                sx={{ px: 1, py: 0.5, mt: 1 }}
              >
                <Grid
                  container
                  direction="row"
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  sx={{ px: 1 }}
                >
                  <Grid item xs>
                    <CssListItemText
                      primary={
                        <>
                          <b>Cantidad de acopios: </b>
                          {acopios?.length}
                        </>
                      }
                      sx={{ mb: 0 }}
                    />
                  </Grid>

                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ borderColor: "#2C6975" }}
                    sx={{ mt: 0.5 }}
                  />

                  <Grid item xs>
                    <CssListItemText
                      primary={
                        <>
                          <b>Total gastado en acopios: </b>
                          {"$ "}
                          {totalAcopio(acopios)}
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </Card>
            </>
          )}

          {/* == BOTÓN PARA REGRESAR ===== */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 2,
            }}
          >
            <CssButtonRegresar
              startIcon={<ArrowBackSharpIcon fontSize="large" />}
              onClick={onClose}
            >
              Regresar
            </CssButtonRegresar>
          </Box>
        </>
      )}
    </form>
  );
}
