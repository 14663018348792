// IMPORTAMOS LA URL
import { BASE_API } from "../utils/constants";

// PETICIÓN A LA API PARA OBTENER TODAS LAS PROVINCIAS
export async function getProvinciasApi(token) {
  try {
    const orderingFilter = `ordering=nombre`;
    const url = `${BASE_API}/api/provincias/?${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER TODOS LOS DEPARTAMENTOS DE ACUERDO A LA ID DE UNA PROVINCIA
export async function getDepartamentosByProvinciaApi(idProvincia, token) {
  try {
    const provinciaFilter = `provincia=${idProvincia}`;
    const orderingFilter = `ordering=nombre`;
    const url = `${BASE_API}/api/departamentos/?${provinciaFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER TODAS LAS LOCALIDADES DE ACUERDO A LA ID DE UN DEPARTAMENTO
export async function getLocalidadesByDepartamentoApi(idDepartamento, token) {
  try {
    const departamentoFilter = `departamento=${idDepartamento}`;
    const orderingFilter = `ordering=nombre`;
    const url = `${BASE_API}/api/localidades/?${departamentoFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER UNA LOCALIDAD DE ACUERDO A SU ID
export async function getLocalidadApi(idLocalidad, token) {
  try {
    const url = `${BASE_API}/api/localidades/${idLocalidad}/`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
