// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Loader } from "semantic-ui-react";

import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import {
  HeaderPage,
  TableProveedor,
  AddEditProveedorForm,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE ICONOS
import { Search as SearchIcon } from "../../components/Admin/icons/search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// IMPORTACIÓN DE HOOKS CREADOS
import { useProveedor } from "../../hooks";

export function ProveedorAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { loading, proveedores, getProveedores, deleteProveedor } =
    useProveedor();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [id, setId] = useState(""); // PARA EL TEXT FIELD PARA EL ID
  const [idFiltro, setIdFiltro] = useState(""); // FILTRAR POR ID

  const [nombre, setNombre] = useState(""); // PARA EL TEXT FIELD EL NOMBRE
  const [nombreFiltro, setNombreFiltro] = useState(""); // FILTRAR POR EL NOMBRE

  const [email, setEmail] = useState(""); // PARA EL TEXT FIELD
  const [filtroEmail, setFiltroEmail] = useState(""); // PARA FILTRAR LA BÚSQUEDA POR EMAIL

  const [buscarPor, setBuscarPor] = useState("NOMBRE"); // PARA EL TEXT FIELD DE BUSCAR POR
  const [ordering, setOrdering] = useState("nombre"); // PARA EL ORDENAMIENTO DE LOS DATOS

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  useEffect(
    () =>
      getProveedores(
        idFiltro,
        nombreFiltro.toUpperCase(),
        filtroEmail.toLowerCase(),
        ordering
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, idFiltro, nombreFiltro, filtroEmail, ordering]
  );

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // ============================== FUNCIONES PARA LA SECCIÓN DE FILTROS ==============================
  // PARA DETERMINAR CUAL SERÁ EL MÉTODO DE FILTRO
  const handleChangeBuscarPor = (event) => {
    setBuscarPor(event.target.value);
    setId("");
    setIdFiltro("");
    setNombre("");
    setNombreFiltro("");
    setEmail("");
    setFiltroEmail("");
  };

  // PARA REALIZAR LA BÚSQUEDA DE UN PROVEEDOR POR ID, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeId = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setIdFiltro(event.target.value);
    }
    setId(event.target.value);
  };

  // PARA REALIZAR LA BÚSQUEDA DE UN PROVEEDOR POR NOMBRE, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeNombre = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setNombreFiltro(event.target.value);
    }
    setNombre(event.target.value);
  };

  // PARA REALIZAR LA BÚSQUEDA DE UN PROVEEDOR POR SU EMAIL, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeEmail = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setFiltroEmail(event.target.value);
    }
    setEmail(event.target.value);
  };

  // PARA ELIMINAR TODOS LOS FILTROS
  const handleChangeFilter = () => {
    setId("");
    setIdFiltro("");
    setNombre("");
    setNombreFiltro("");
    setEmail("");
    setFiltroEmail("");
    setBuscarPor("NOMBRE");
    setOrdering("nombre");
  };
  // =======================================================================================================

  // FUNCIÓN PARA AÑADIR UN NUEVO PROVEEDOR, SE ABRIRÁ EL MODAL
  const addProveedor = () => {
    setTitleModal("Nuevo proveedor");
    setContentModal(
      <AddEditProveedorForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ACTUALIZAR LOS VALORES DE UN PROVEEDOR, SE ABRIRÁ EL MODAL
  const updateProveedor = (data) => {
    setTitleModal("Actualizar datos del proveedor");
    setContentModal(
      <AddEditProveedorForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        proveedor={data}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UN PROVEEDOR CREADO
  const onDeleteProveedor = async (data) => {
    const result = window.confirm(`¿Eliminar el proveedor: ${data.nombre}?`);
    if (result) {
      try {
        await deleteProveedor(data.id);
        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Proveedores"
        subheader="En esta sección podrás ver y añadir datos de los proveedores."
        btnTitle="Nuevo proveedor"
        btnClick={addProveedor}
      />

      {/* =================== SECCIÓN DE FILTROS ==================  */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* =========== DETERMINAR EL CAMPO POR EL CUAL BUSCAR =========== */}
            <Grid item md={2.1}>
              <CssTextField
                select
                label="Buscar por"
                value={buscarPor}
                onChange={handleChangeBuscarPor}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"NOMBRE"}>NOMBRE</MenuItem>
                <MenuItem value={"EMAIL"}>CORREO ELECTRÓNICO</MenuItem>
              </CssTextField>
            </Grid>

            {buscarPor === "ID" ? (
              <Grid item md={3}>
                {/* ======================= BÚSQUEDA POR ID ======================= */}
                <CssTextField
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    type: "number",
                  }}
                  value={id}
                  placeholder="Buscar por ID"
                  variant="outlined"
                  onChange={handleChangeId}
                  onKeyDown={handleChangeId}
                  fullWidth
                  sx={{ marginTop: 1 }}
                />
              </Grid>
            ) : (
              <>
                {buscarPor === "NOMBRE" ? (
                  <Grid item md={3}>
                    {/* ======================= BÚSQUEDA POR NOMBRE ======================= */}
                    <CssTextField
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon color="action" fontSize="small">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      value={nombre}
                      placeholder="Buscar por nombre"
                      variant="outlined"
                      onChange={handleChangeNombre}
                      onKeyDown={handleChangeNombre}
                      fullWidth
                      sx={{ marginTop: 1 }}
                    />
                  </Grid>
                ) : (
                  <Grid item md={3}>
                    {/* ======================= BÚSQUEDA POR EMAIL ======================= */}
                    <CssTextField
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon color="action" fontSize="small">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      value={email}
                      placeholder="Buscar por correo electrónico"
                      variant="outlined"
                      onChange={handleChangeEmail}
                      onKeyDown={handleChangeEmail}
                      fullWidth
                      sx={{ marginTop: 1 }}
                    />
                  </Grid>
                )}
              </>
            )}

            {/* ====================== LIMPIAR FILTROS ====================== */}
            <Grid item sx={{ marginTop: 0.25 }}>
              <Tooltip title="Limpiar filtro">
                <IconButton size="large" onClick={handleChangeFilter}>
                  <FilterAltOffIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* ========= CARGA DE LA TABLA DE PROVEEDORES ========= */}
          {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
            <TableProveedor
              proveedores={proveedores}
              updateProveedor={updateProveedor}
              deleteProveedor={onDeleteProveedor}
            />
          )}
        </CardContent>
      </Card>

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}
