// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CssTablePagination } from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./TablePersona.scss";

// OTRAS IMPORTACIONES
import moment from "moment";

// NOMBRES DE LOS ENCABEZADO DE LAS COLUMNAS DE LA TABLA DE PERSONAS
const columns = [
  { id: "id", label: "", align: "center", minWidth: 50, width: 50 },
  { id: "apellidos", label: "Apellidos" },
  { id: "nombres", label: "Nombres" },
  { id: "documento", label: "Nro. de documento", align: "center" },
  { id: "sexo", label: "Sexo", align: "center" },
  { id: "fecha_nacimiento", label: "Fecha de nacimiento", align: "center" },
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
  },
];

export function TablePersona(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA PersonaAdmin.js
  const { personas, updatePersona, onDeletePersona } = props;

  // CREACIÓN DE LAS VARIABELS DE ESTADOS
  const [page, setPage] = React.useState(0); // PARA EL MANEJO DE LA PAGINACIÓN
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // CANTIDAD DE FILAS A MOSTRAR

  // FUNCIÓN PARA EL CAMBIO DE PÁGINA DE LA TABLA
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FUNCIÓN PARA CAMBIAR LA CANTIDAD DE FILAS A MOSTRAR
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      {personas ? (
        // DATOS DE LAS PERSONAS
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 340 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              className="table-acopio-admin"
            >
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      colSpan={column.colSpan}
                      style={{
                        width: column.width,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "bold",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {personas
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((persona, index) => {
                    return (
                      <TableRow tabIndex={-1} key={persona.num_doc}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                          sx={{ py: 0, my: 0 }}
                        >
                          {index + 1 + page * rowsPerPage}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {persona.apellidos}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {persona.nombres}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {persona.num_doc}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {persona.sexo === "M"
                            ? "Masculino"
                            : persona.sexo === "F"
                            ? "Femenino"
                            : "Otro"}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {moment(persona.fecha_nacimiento).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>

                        <Actions
                          persona={persona}
                          updatePersona={updatePersona}
                          onDeletePersona={onDeletePersona}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* == CONTROL DE LA PAGINACIÓN ======================================== */}
          <CssTablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={personas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Personas por página:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES DE ACTUALIZAR O ELIMINAR DATOS DE UNA PERSONA
function Actions(props) {
  const { persona, updatePersona, onDeletePersona } = props;

  return (
    <TableCell align="center" sx={{ py: FONTSIZE.paddingCell }}>
      {/* == BOTÓN PARA MODIFICAR DATOS DE UNA PERSONA =========================== */}
      <Tooltip title="Editar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => updatePersona(persona)}
          type="button"
        >
          <EditIcon color="info" />
        </IconButton>
      </Tooltip>
      {/* == BOTÓN PARA ELIMINAR UNA PERSONA ==================================== */}
      <Tooltip title="Eliminar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => onDeletePersona(persona)}
          type="button"
        >
          <DeleteForeverIcon color="error" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
