// IMPORTACIÓN DE REACT
import React, { useState } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Grid } from "@mui/material";
import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { useFormik } from "formik";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { usePrestamo } from "../../../../hooks";

export function ModificarCantidadInsumo(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA SolicitudPrestamoAdmin.js
  const { onClose, onRefetch, insumo } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { updateCantidadInsumo } = usePrestamo();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(false);

  // DEFINICIÓN DE FORMIK Y YUP PARA LA VALIDACIÓN DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(insumo),
    validationSchema: Yup.object(Schame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        setLoading(true);
        await updateCantidadInsumo(insumo.id, formValue);
        onRefetch();
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form className="add-edit-desacopio-form" onSubmit={formik.handleSubmit}>
      {/* ====================== SELECCIÓN DE LA CATEGORIA ====================== */}
      <CssTextField
        label="Nombre de la categoría"
        name="categoria_name"
        value={formik.values.categoria_name}
        onChange={formik.handleChange}
        disabled
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      />

      {/* ====== SOLO SE LE PIDE QUE INGRESE LA CANTIDAD DE INSUMOS A AGREGAR A LA SOLICITUD======= */}
      <CssTextField
        label="Cantidad de insumos a entregar"
        name="cantidad_entregada"
        value={formik.values.cantidad_entregada}
        inputProps={{
          step: 1,
          min: 0,
          type: "number",
          "aria-labelledby": "input-slider",
        }}
        onChange={formik.handleChange}
        error={Boolean(
          formik.touched.cantidad_entregada && formik.errors.cantidad_entregada
        )}
        helperText={
          formik.touched.cantidad_entregada && formik.errors.cantidad_entregada
        }
        onBlur={formik.handleBlur}
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
        sx={{ mt: 2 }}
      />
      <hr />
      {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
      <Grid
        container
        direction="row"
        justifyContent="right"
        alignItems="center"
        columnSpacing={{ md: 1.5 }}
      >
        <Grid item>
          <CssButtonCancelar
            startIcon={<CloseIcon fontSize="large" />}
            onClick={onClose}
          >
            Cancelar
          </CssButtonCancelar>
        </Grid>

        <Grid item>
          <CssLoadingButton
            endIcon={
              insumo ? (
                <ReplaySharpIcon fontSize="large" />
              ) : (
                <AddTaskOutlinedIcon fontSize="large" />
              )
            }
            variant="contained"
            loading={loading}
            type="submit"
          >
            {"Actualizar"}
          </CssLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

// INICIALIZACIÓN DE LOS VALORES INICIALES DEL FORMULARIO DE INSUMOS
function initialValues(data) {
  return {
    id: data?.id,
    categoria_name: data?.categoria_data.nombre,
    cantidad_entregada: data?.cantidad_entregada,
  };
}

// VALIDACIÓN DE LOS CAMPOS DEL FORMULARIO DE INSUMOS
function Schame() {
  return {
    cantidad_entregada: Yup.number().required("Ingrese la cantidad de insumos"),
  };
}
