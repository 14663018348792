// IMPORTACIÓN DE REACT
import React, { useState } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Grid } from "@mui/material";
import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

// IMPORTACIÓN DE ESTILOS
import "./AddEditAreaForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { useFormik } from "formik";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { useArea } from "../../../../hooks";

export function AddEditAreaForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA AreasAdmin.js
  const { onClose, onRefetch, area } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { addArea, updateArea } = useArea();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(false);

  // VERIFICACIÓN Y VALIDACIÓN DE LOS DATOS DEL FORMULARIO, MEDIANTE EL USO DE FORMIK Y YUP
  const formik = useFormik({
    initialValues: initialValues(area),
    validationSchema: Yup.object(Schame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        setLoading(true);
        // CONVERSIÓN DATOS IMPORTANTES A MAYÚSCULA
        formValue.nombre = formValue.nombre.toUpperCase();

        // if (formValue.descripcion.replace(/ /g, "") === "")
        //   formValue.descripcion = "S/D";

        if (area) await updateArea(area.id, formValue);
        else await addArea(formValue);

        onRefetch();
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form className="add-edit-area-form" onSubmit={formik.handleSubmit}>
      {/* =================== NOMBRE DEL ÁREA  ===================*/}
      <CssTextField
        // inputProps={{ style: { textTransform: "uppercase" } }}
        label="Nombre del área"
        name="nombre"
        value={formik.values.nombre}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.nombre && formik.errors.nombre)}
        helperText={formik.touched.nombre && formik.errors.nombre}
        onBlur={formik.handleBlur}
        // placeholder="Nombre del Area"
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      />

      {/* =================== DESCRIPCIÓN DEL ÁREA  ===================*/}
      <CssTextField
        label="Descripción"
        name="descripcion"
        value={formik.values.descripcion}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.descripcion && formik.errors.descripcion)}
        helperText={formik.touched.descripcion && formik.errors.descripcion}
        onBlur={formik.handleBlur}
        // placeholder="Descripcion"
        fullWidth
        variant="outlined"
        margin="dense"
        multiline
        rows={4}
      />
      <hr />

      {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
      <Grid
        container
        direction="row"
        justifyContent="right"
        alignItems="center"
        columnSpacing={{ md: 1.5 }}
      >
        <Grid item>
          <CssButtonCancelar
            startIcon={<CloseIcon fontSize="large" />}
            onClick={onClose}
          >
            Cancelar
          </CssButtonCancelar>
        </Grid>

        <Grid item>
          <CssLoadingButton
            endIcon={
              area ? (
                <ReplaySharpIcon fontSize="large" />
              ) : (
                <AddTaskOutlinedIcon fontSize="large" />
              )
            }
            variant="contained"
            loading={loading}
            type="submit"
          >
            {area ? "Actualizar" : "Crear"}
          </CssLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

// INICIALIZACIÓN DE LOS VALORES DEL FORMULARIO
function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    descripcion: data?.descripcion || "",
  };
}

// VALIDACIÓN DEL FORMULARIO PARA LA CREACIÓN O MODIFICACIÓN DE LOS DATOS DEL ÁREA
function Schame() {
  return {
    nombre: Yup.string()
      .required("Ingrese el nombre del área")
      .min(3, "El nombre debe que tener al menos 3 caracteres"),
    descripcion: Yup.string(),
  };
}
