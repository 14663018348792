// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Loader } from "semantic-ui-react";

import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import {
  HeaderPage,
  TableInsumoAdmin,
  AddEditInsumoForm,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE ICONOS
import { Search as SearchIcon } from "../../components/Admin/icons/search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// IMPORTACIÓN DE HOOKS CREADOS
import { useInsumo, useCategoria } from "../../hooks";

export function InsumosAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { loading, insumos, getInsumos, deleteInsumo } = useInsumo();
  const { categorias, getCategorias } = useCategoria();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [codigo, setCodigo] = useState(""); // PARA EL TEXT FIELD
  const [codigoFiltro, setCodigoFiltro] = useState(""); // FILTRAR POR EL CÓDIGO DEL INSUMO

  const [selectCategoria, setSelectCategoria] = useState("VER_TODOS"); // PARA EL TEXT FIELD
  const [filtroCategoria, setFiltroCategoria] = useState(""); // FILTRA POR CATEGORÍA A LA QUE PERTENECE EL INSUMO

  const [selectDisponible, setSelectDisponible] = useState("VER_TODOS"); // PARA EL TEXT FIELD
  const [filtroDisponible, setFiltroDisponible] = useState(""); // FILTRA INSUMOS QUE ESTÁN O NO ESTÁN DISPONIBLES

  const [buscarPor, setBuscarPor] = useState("CODIGO"); // PARA EL TEXT FIELD

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  useEffect(
    () =>
      getInsumos(
        codigoFiltro.toUpperCase(),
        filtroCategoria,
        filtroDisponible,
        "categoria__nombre"
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, codigoFiltro, filtroCategoria, filtroDisponible]
  ); // FUNCIÓN PARA OBTENER LOS INSUMOS CREADAS, CON ALGUNOS FILTROS

  // PARA OBTENER LAS CATEGORÍAS DE INSUMOS NO DESECHABLES
  useEffect(
    () => getCategorias(false, "", "", "", "nombre"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, filtroCategoria]
  );

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // ============================== FUNCIONES PARA LA SECCIÓN DE FILTROS ==============================
  // PARA DETERMINAR CUAL SERÁ EL MÉTODO DE FILTRO
  const handleChangeBuscarPor = (event) => {
    setBuscarPor(event.target.value);
    setFiltroCategoria("");
    setSelectCategoria("VER_TODOS");
    setCodigo("");
    setCodigoFiltro("");
  };

  // PARA REALIZAR LA BÚSQUEDA DE UN INSUMO POR SU CÓDIGO, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeCodigo = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setCodigoFiltro(event.target.value);
    }
    setCodigo(event.target.value);
  };

  // PARA VISUALIZAR LOS INSUMOS QUE PERTENECEN A UNA CATEGORÍA
  const handleChangeCategoria = (event) => {
    setSelectCategoria(event.target.value);
    if (event.target.value === "VER_TODOS") {
      setFiltroCategoria("");
    } else {
      setFiltroCategoria(event.target.value);
    }
  };

  // PARA VISUALIZAR LOS INSUMOS QUE ESTÁN O NO DISPONIBLES
  const handleChangeDisponible = (event) => {
    setSelectDisponible(event.target.value);
    if (event.target.value === "VER_TODOS") {
      setFiltroDisponible("");
    } else {
      if (event.target.value === "DISPONIBLES") {
        setFiltroDisponible(true);
      } else {
        setFiltroDisponible(false);
      }
    }
  };

  // PARA ELIMINAR TODOS LOS FILTROS
  const handleChangeFilter = () => {
    setCodigo("");
    setCodigoFiltro("");
    setSelectCategoria("VER_TODOS");
    setFiltroCategoria("");
    setSelectDisponible("VER_TODOS");
    setFiltroDisponible("");
    setBuscarPor("CODIGO");
  };
  // =======================================================================================================

  // FUNCIÓN PARA ACTUALIZAR LOS VALORES DE UN INSUMO, SE ABRIRÁ EL MODAL
  const updateInsumo = (data) => {
    setTitleModal("Actualizar datos del insumo");
    setContentModal(
      <AddEditInsumoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        insumo={data}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UN INSUMO
  const onDeleteInsumo = async (data) => {
    const result = window.confirm(
      `¿Eliminar insumo con el código: ${data.codigo}?`
    );
    if (result) {
      try {
        await deleteInsumo(data.id);
        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Insumos"
        subheader="En esta sección se encuentran todos los insumos no desechables que han sido agregados en los acopios."
      />

      {/* =================== SECCIÓN DE FILTROS ==================  */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* =========== DETERMINAR EL CAMPO POR EL CUAL BUSCAR =========== */}
            <Grid item md={2.1}>
              <CssTextField
                select
                label="Buscar por"
                value={buscarPor}
                onChange={handleChangeBuscarPor}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"CODIGO"}>CÓDIGO</MenuItem>
                <MenuItem value={"CATEGORIA"}>CATEGORÍA</MenuItem>
              </CssTextField>
            </Grid>

            {buscarPor === "CODIGO" ? (
              <Grid item md={3}>
                {/* ======================= BÚSQUEDA POR CÓDIGO ======================= */}
                <CssTextField
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  value={codigo}
                  placeholder="Buscar por código"
                  variant="outlined"
                  onChange={handleChangeCodigo}
                  onKeyDown={handleChangeCodigo}
                  fullWidth
                  sx={{ marginTop: 1 }}
                />
              </Grid>
            ) : (
              <Grid item md={3}>
                {/* ===================== BÚSQUEDA POR CATEGORÍA ==================== */}
                <CssTextField
                  select
                  search="true"
                  label="Categoría"
                  value={selectCategoria}
                  onChange={handleChangeCategoria}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                >
                  <MenuItem value={"VER_TODOS"}>VER TODOS</MenuItem>

                  {categorias?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.nombre}
                    </MenuItem>
                  ))}
                </CssTextField>
              </Grid>
            )}

            {/* ========== MOSTRAR INSUMOS DE ACUERDO A SU DISPONIBILIDAD ========== */}
            <Grid item md={3}>
              <CssTextField
                select
                search="true"
                label="Filtrar por disponibilidad"
                value={selectDisponible}
                onChange={handleChangeDisponible}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"DISPONIBLES"}>INSUMOS DISPONIBLES</MenuItem>
                <MenuItem value={"NO_DISPONIBLE"}>
                  INSUMOS NO DISPONIBLES
                </MenuItem>
                <MenuItem value={"VER_TODOS"}>VER TODOS</MenuItem>
              </CssTextField>
            </Grid>

            {/* ====================== LIMPIAR FILTROS ====================== */}
            <Grid item md={2} sx={{ marginTop: 0.25 }}>
              <Tooltip title="Limpiar filtro">
                <IconButton size="large" onClick={handleChangeFilter}>
                  <FilterAltOffIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* ========= CARGA DE LA TABLA DE INSUMOS ========= */}
          {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
            <TableInsumoAdmin
              insumos={insumos}
              updateInsumo={updateInsumo}
              onDeleteInsumo={onDeleteInsumo}
            />
          )}
        </CardContent>
      </Card>

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size="small"
      />
    </>
  );
}
