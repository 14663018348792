// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import { Card, Box, Divider } from "@mui/material";

import { CssTextField, CssButtonPrimary } from "../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

// IMPORTACIÓN DE ESTILOS
import "./FootPage.scss";

export function FootPage(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS
  const { tfLabel, tfValue, tfOnChange, btnTitle, btnClick } = props;

  return (
    <Card sx={{ marginTop: 2 }}>
      <Box sx={{ marginLeft: 2, marginRight: 2, marginBottom: 1 }}>
        {/* === TEXT FIELD PARA INGRESAR DATOS POR TECLADO ============ */}
        <CssTextField
          label={tfLabel}
          value={tfValue}
          onChange={tfOnChange}
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
        />
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        {/* === BOTÓN PARA REALIZAR ALGUNA ACCIÓN =========================== */}
        <CssButtonPrimary
          endIcon={
            <ArrowCircleRightOutlinedIcon fontSize="large" sx={{ mr: 1 }} />
          }
          size="medium"
          onClick={btnClick}
        >
          {btnTitle}
        </CssButtonPrimary>
      </Box>
    </Card>
  );
}
