// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getProveedoresApi,
  addProveedorApi,
  updateProveedorApi,
  deleteProveedorApi,
} from "../api/proveedor";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from "./";

export function useProveedor() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [proveedores, setProveedores] = useState(null); // PARA ALMACENAR LOS PROVEEDORES

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE PROVEEDORES
  const getProveedores = async (id, nombre, email, ordering) => {
    try {
      setLoading(true);
      const response = await getProveedoresApi(
        id,
        nombre,
        email,
        ordering,
        auth.token
      );
      setLoading(false);
      setProveedores(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UN NUEVO PROVEEDOR
  const addProveedor = async (data) => {
    try {
      setLoading(true);
      await addProveedorApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DE LOS DATOS DE UN PROVEEDOR
  const updateProveedor = async (id, data) => {
    try {
      setLoading(true);
      await updateProveedorApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ELIMINACIÓN DE UN PROVEEDOR
  const deleteProveedor = async (id) => {
    try {
      setLoading(true);
      await deleteProveedorApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getProveedores,
    proveedores,
    addProveedor,
    updateProveedor,
    deleteProveedor,
    loading,
    error,
  };
}
