// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER LOS PRESUPUESTOS
// SE PUEDE FILTRAR POR:
// EL AÑO, EL USUARIO QUE LO REALIZÓ Y DE ACUERDO A UN ORDEN
export async function getPresupuestosApi(
  anio = "",
  usuario = "",
  ordering = "",
  token
) {
  try {
    const anioFilter = `anio=${anio}`;
    const usuarioFilter = `usuario=${usuario}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/presupuestos/?${anioFilter}&${usuarioFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AÑADIR UN NUEVO PRESUPUESTO
export async function addPresupuestoApi(data, token) {
  try {
    const url = `${BASE_API}/api/presupuestos/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un error al añadir el nuevo presupuesto, es probable que ya exista uno con el mismo año",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente el nuevo presupuesto", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LOS DATOS DE UN PRESUPUESTO
export async function updatePresupuestoApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/presupuestos/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error("Hubo un error al actualizar los datos del presupuesto", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
      });
    } else {
      toast.success("Se ha registrado correctamente los cambios", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER LAS MODIFICACIONES DE UN PRESUPUESTO
export async function getModificacionesByPresupuestoApi(id_presupuesto, token) {
  try {
    const presupuestoFilter = `presupuesto=${id_presupuesto}`;
    const url = `${BASE_API}/api/modificacionPresupuestaria/?${presupuestoFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AÑADIR UNA NUEVA MODIFICACIÓN PRESUPUESTARIA
export async function addModificacionPresupuestariaApi(data, token) {
  try {
    const url = `${BASE_API}/api/modificacionPresupuestaria/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un problema al realizar la modificación presupuestaria",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    } else {
      toast.success(
        "Se registró correctamente la modificación presupuestaria",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        }
      );
    }

    return result;
  } catch (error) {
    throw error;
  }
}
