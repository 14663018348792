// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getDesacopiosApi,
  addDesacopioApi,
  getCategoriasByDesacopioApi,
  updateEstadoDesacopioApi,
  updateCantidadInsumoApi,
} from "../api/desacopio";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from ".";

export function useDesacopio() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [categorias, setCategorias] = useState(null); // PARA ALMACENAR LAS CATEGORÍAS
  const [desacopios, setDesacopios] = useState(null); // PARA ALMACENAR LOS DESACOPIO

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE DESACOPIOS
  const getDesacopios = async (id, estado, usuario, ordering) => {
    try {
      setLoading(true);
      const response = await getDesacopiosApi(
        id,
        estado,
        usuario,
        ordering,
        auth.token
      );
      setLoading(false);
      setDesacopios(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UNA NUEVA SOLICITUD DE DESACOPIO
  const addDesacopio = async (data, setDesacopio, setDescripcion) => {
    try {
      setLoading(true);
      await addDesacopioApi(data, setDesacopio, setDescripcion, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DEL ESTADO DE LA SOLICITUD DE UN DESACOPIO
  const updateEstadoDesacopio = async (id, data, onClose) => {
    try {
      setLoading(true);
      await updateEstadoDesacopioApi(id, data, onClose, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE CATEGORÍAS DE UN DESACOPIO
  const getCategoriasByDesacopio = async (id_desacopio, ordering) => {
    try {
      setLoading(true);
      const response = await getCategoriasByDesacopioApi(
        id_desacopio,
        ordering,
        auth.token
      );
      setLoading(false);
      setCategorias(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DE LA CANTIDAD DE UN INSUMO EN UN DESACOPIO
  const updateCantidadInsumo = async (id, data) => {
    try {
      setLoading(true);
      await updateCantidadInsumoApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getDesacopios,
    desacopios,
    addDesacopio,
    updateEstadoDesacopio,
    getCategoriasByDesacopio,
    categorias,
    updateCantidadInsumo,
    loading,
    error,
  };
}
