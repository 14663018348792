export const BASE_API = "http://" + String(process.env.REACT_APP_HOST);

export const TOKEN = "token";

export const FONTSIZE = {
  tableRow: 16,
  tableCell: 14,
  paddingCell: 0.2,
  sizeIcon: "medium",
  listItem: 14,
  severityPill: 14,
};
