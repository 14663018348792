// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getProvinciasApi,
  getDepartamentosByProvinciaApi,
  getLocalidadesByDepartamentoApi,
  getLocalidadApi,
} from "../api/georeferencias";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from ".";

export function useGeoreferencias() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [provincias, setProvincias] = useState(null); // PARA ALMACENAR LAS PROVINCIAS
  const [departamentos, setDepartamentos] = useState(null); // PARA ALMACENAR LOS DEPARTAMENTOS
  const [localidades, setLocalidades] = useState(null); // PARA ALMACENAR LAS LOCALIDADES
  const [localidad, setLocalidad] = useState(null); // PARA ALMACENAR UNA LOCALIDAD

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE PROVINCIAS
  const getProvincias = async () => {
    try {
      setLoading(true);
      const response = await getProvinciasApi(auth.token);
      setLoading(false);
      setProvincias(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE DEPARTAMENTOS
  const getDepartamentos = async (idProvincia) => {
    try {
      if (idProvincia !== "") {
        setLoading(true);
        const response = await getDepartamentosByProvinciaApi(
          idProvincia,
          auth.token
        );
        setLoading(false);
        setDepartamentos(response);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE LOCALIDADES
  const getLocalidades = async (idDepartamento) => {
    try {
      if (idDepartamento !== "") {
        setLoading(true);
        const response = await getLocalidadesByDepartamentoApi(
          idDepartamento,
          auth.token
        );
        setLoading(false);
        setLocalidades(response);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE UNA LOCALIDAD
  const getLocalidad = async (idLocalidad) => {
    try {
      setLoading(true);
      const response = await getLocalidadApi(idLocalidad, auth.token);
      setLoading(false);
      setLocalidad(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getProvincias,
    provincias,
    getDepartamentos,
    departamentos,
    getLocalidades,
    localidades,
    getLocalidad,
    localidad,
    loading,
    error,
  };
}
