// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Loader } from "semantic-ui-react";

import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  // MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import { HeaderPage, TableArea, AddEditAreaForm } from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE ICONOS
import { Search as SearchIcon } from "../../components/Admin/icons/search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// IMPORTACIÓN DE HOOKS CREADOS
import { useArea } from "../../hooks";

export function AreasAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { loading, areas, getAreas, deleteArea } = useArea();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [nombre, setNombre] = useState(""); // PARA EL TEXT FIELD EL NOMBRE
  const [nombreFiltro, setNombreFiltro] = useState(""); // FILTRAR POR EL NOMBRE

  const [ordering, setOrdering] = useState("nombre"); // PARA EL ORDENAMIENTO DE LOS DATOS

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  useEffect(
    () => getAreas("", nombreFiltro.toUpperCase(), ordering),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, "", nombreFiltro, ordering]
  );

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // ============================== FUNCIONES PARA LA SECCIÓN DE FILTROS ==============================
  // PARA REALIZAR LA BÚSQUEDA DE UN ÁREA POR NOMBRE, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeNombre = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setNombreFiltro(event.target.value);
    }
    setNombre(event.target.value);
  };

  // PARA ELIMINAR TODOS LOS FILTROS
  const handleChangeFilter = () => {
    setNombre("");
    setNombreFiltro("");
    setOrdering("nombre");
  };
  // =======================================================================================================

  // FUNCIÓN PARA AÑADIR UNA NUEVA ÁREA, SE ABRIRÁ EL MODAL
  const addArea = () => {
    setTitleModal("Nueva área");
    setContentModal(
      <AddEditAreaForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ACTUALIZAR LOS VALORES DE UN ÁREA, SE ABRIRÁ EL MODAL
  const updateArea = (data) => {
    setTitleModal("Actualizar datos del área");
    setContentModal(
      <AddEditAreaForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        area={data}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UN ÁREA CREADA
  const onDeleteArea = async (data) => {
    const result = window.confirm(`¿Eliminar el área: ${data.nombre}?`);
    if (result) {
      try {
        await deleteArea(data.id);
        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Áreas"
        subheader="En esta sección se encuentran todas las áreas en la cual los trabajadores pueden desempeñar sus actividades."
        btnTitle="Nueva área"
        btnClick={addArea}
      />

      {/* =================== SECCIÓN DE FILTROS ==================  */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* ==================== BÚSQUEDA POR NOMBRE ==================== */}
            <Grid item md={3}>
              <CssTextField
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                value={nombre}
                placeholder="Buscar por nombre"
                variant="outlined"
                onChange={handleChangeNombre}
                onKeyDown={handleChangeNombre}
                fullWidth
                sx={{ marginTop: 1 }}
              />
            </Grid>

            {/* ====================== LIMPIAR FILTROS ====================== */}
            <Grid item sx={{ marginTop: 0.25 }}>
              <Tooltip title="Limpiar filtro">
                <IconButton size="large" onClick={handleChangeFilter}>
                  <FilterAltOffIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* ========= CARGA DE LA TABLA DE ÁREAS ========= */}
          {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
            <TableArea
              areas={areas}
              updateArea={updateArea}
              deleteArea={onDeleteArea}
            />
          )}
        </CardContent>
      </Card>

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}
