// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER LOS DESACOPIOS
// SE PUEDE FILTRAR POR:
// EL ID, EL ESTADO DE LA SOLICITUD DEL DESACOPIO Y DE ACUERDO A UN ORDEN
export async function getDesacopiosApi(
  id = "",
  estado = "",
  usuario = "",
  ordering = "",
  token
) {
  try {
    const idFilter = `id=${id}`;
    const estadoFilter = `estado=${estado}`;
    const usuarioFilter = `usuario_solicitud=${usuario}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/desacopios/?${idFilter}&${estadoFilter}&${usuarioFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AÑADIR UNA NUEVA SOLICITUD DE DESACOPIO
export async function addDesacopioApi(
  data,
  setDesacopio,
  setDescripcion,
  token
) {
  try {
    const url = `${BASE_API}/api/desacopios/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    } else {
      setDesacopio([]);
      setDescripcion("");
      toast.success(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR EL ESTADO DE LA SOLICITUD DE DESACOPIO
export async function updateEstadoDesacopioApi(id, data, onClose, token) {
  try {
    const url = `${BASE_API}/api/desacopios/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
      });
    } else {
      onClose();
      toast.success(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER LAS CATEGORÍAS ASOCIADOS A UN DESACOPIO
export async function getCategoriasByDesacopioApi(
  id_desacopio,
  ordering = "",
  token
) {
  try {
    const desacopioFilter = `desacopio=${id_desacopio}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/desacopioCategoriaTea/?${desacopioFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LA CANTIDAD DE INSUMOS A DAR
export async function updateCantidadInsumoApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/desacopioCategoriaTea/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error("Hubo un problema en actualizar la nueva cantidad", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      toast.success("Se actualizó la nueva cantidad", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
    return result;
  } catch (error) {
    throw error;
  }
}
