// IMPORTACIÓN DE REACT
import React, { useEffect, useState } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Grid, Autocomplete } from "@mui/material";
import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

import { Loader } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

// IMPORTACIÓN DE ESTILOS
import "./AddEditTrabajadorForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { map } from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { useTrabajador, useArea, useUser, usePersona } from "../../../../hooks";

export function AddEditTrabajadorForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA TrabajadorAdmin.js
  const { onClose, onRefetch, trabajador } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { addTrabajador, updateTrabajador } = useTrabajador();
  const { personas, getPersonas } = usePersona();
  const { areas, getAreas } = useArea();
  const { users, getUsers } = useUser();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [usersFormat, setUsersFormat] = useState([]); // SETEA LOS DATOS DE USUARIO
  const [personasFormat, setPersonasFormat] = useState([]); // SETEA LOS DATOS DE PERSONA
  const [areasFormat, setAreasFormat] = useState([]); // SETEA LOS DATOS DE ÁREAS

  const [valueU, setValueU] = useState(null); // PARA EL VALOR DEL AUTOCOMPLETE
  const [inputValueU, setInputValueU] = useState(""); // PARA EL VALOR DE LA OPCIÓN SELECCIONADA DEL AUTOCOMPLETE

  const [valueP, setValueP] = useState(null); // PARA EL VALOR DEL AUTOCOMPLETE
  const [inputValueP, setInputValueP] = useState(""); // PARA EL VALOR DE LA OPCIÓN SELECCIONADA DEL AUTOCOMPLETE

  const [valueA, setValueA] = useState(null); // PARA EL VALOR DEL AUTOCOMPLETE
  const [inputValueA, setInputValueA] = useState(""); // PARA EL VALOR DE LA OPCIÓN SELECCIONADA DEL AUTOCOMPLETE

  const [loading, setLoading] = useState(false);

  // CREACIÓN DE LOS useEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPersonas(), []); // OBTENCIÓN DE LAS PERSONAS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setPersonasFormat(formatDDPersona(personas)), [personas]); // SETEO DE LOS DATOS DE PERSONAS

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getUsers(), []); // OBTENCIÓN DE LOS USUARIOS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setUsersFormat(formatDDUsers(users)), [users]); // SETEO DE LOS DATOS DE USUARIOS

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAreas(), []); // OBTENCIÓN DE LAS ÁREAS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setAreasFormat(formatDDAreas(areas)), [areas]); // SETEO DE LOS DATOS DE ÁREAS

  // VERIFICACIÓN Y VALIDACIÓN DE LOS DATOS DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(trabajador),
    validationSchema: Yup.object(Schame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        setLoading(true);
        if (trabajador) await updateTrabajador(trabajador.id, formValue);
        else await addTrabajador(formValue);
        onRefetch();
        onClose();
        // console.log("Usuario Creado Correctamente");
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form className="add-edit-trabajador-form" onSubmit={formik.handleSubmit}>
      {personas == null || areas == null || users == null ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <>
          {/* =================== SELECCIÓN DE USUARIO  ===================*/}
          {usersFormat.length > 0 ? (
            <Autocomplete
              value={
                trabajador && valueU === null
                  ? usersFormat.find((user) => user.key === trabajador.usuario)
                  : valueU
              }
              onChange={(event, newValue) => {
                setValueU(newValue);
                formik.setFieldValue("usuario", newValue?.value);
              }}
              inputValue={inputValueU}
              onInputChange={(event, newInputValue) => {
                setInputValueU(newInputValue);
              }}
              options={usersFormat}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Seleccione el usuario de acuerdo a su email"
                  error={Boolean(
                    formik.touched.usuario && formik.errors.usuario
                  )}
                  helperText={formik.touched.usuario && formik.errors.usuario}
                  onBlur={formik.handleBlur}
                  size="small"
                />
              )}
            />
          ) : (
            "Necesita agregar usuarios"
          )}

          {/* =================== SELECCIÓN DE PERSONA  ===================*/}
          {personasFormat.length > 0 ? (
            <Autocomplete
              value={
                trabajador && valueP === null
                  ? personasFormat.find(
                      (persona) => persona.key === trabajador.persona
                    )
                  : valueP
              }
              onChange={(event, newValue) => {
                setValueP(newValue);
                formik.setFieldValue("persona", newValue?.value);
              }}
              inputValue={inputValueP}
              onInputChange={(event, newInputValue) => {
                setInputValueP(newInputValue);
              }}
              options={personasFormat}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Seleccione la persona de acuerdo a su DNI"
                  error={Boolean(
                    formik.touched.persona && formik.errors.persona
                  )}
                  helperText={formik.touched.persona && formik.errors.persona}
                  onBlur={formik.handleBlur}
                  size="small"
                />
              )}
              sx={{ mt: 2 }}
            />
          ) : (
            <p></p>
          )}

          {/* =================== SELECCIÓN DEL ÁREA  ===================*/}
          {areasFormat.length > 0 ? (
            <Autocomplete
              value={
                trabajador && valueA === null
                  ? areasFormat.find((area) => area.key === trabajador.area)
                  : valueA
              }
              onChange={(event, newValue) => {
                setValueA(newValue);
                formik.setFieldValue("area", newValue?.value);
              }}
              inputValue={inputValueA}
              onInputChange={(event, newInputValue) => {
                setInputValueA(newInputValue);
              }}
              options={areasFormat}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Seleccione el área donde desempeñará su actividad"
                  error={Boolean(formik.touched.area && formik.errors.area)}
                  helperText={formik.touched.area && formik.errors.area}
                  onBlur={formik.handleBlur}
                  size="small"
                />
              )}
              sx={{ my: 2 }}
            />
          ) : (
            <p>Es necesario que agregue las áreas de trabajo</p>
          )}

          <hr />
          {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="center"
            columnSpacing={{ md: 1.5 }}
          >
            <Grid item>
              <CssButtonCancelar
                startIcon={<CloseIcon fontSize="large" />}
                onClick={onClose}
              >
                Cancelar
              </CssButtonCancelar>
            </Grid>

            <Grid item>
              <CssLoadingButton
                endIcon={
                  trabajador ? (
                    <ReplaySharpIcon fontSize="large" />
                  ) : (
                    <AddTaskOutlinedIcon fontSize="large" />
                  )
                }
                variant="contained"
                loading={loading}
                type="submit"
              >
                {trabajador ? "Actualizar" : "Crear"}
              </CssLoadingButton>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
}

// FUNCIÓN PARA SETEAR LOS DATOS DE USUARIOS
function formatDDUsers(data) {
  return map(data, (item) => ({
    key: item.id,
    label: `${item.email}: ${item.last_name}, ${item.first_name}`,
    value: item.id,
  }));
}

// FUNCIÓN PARA SETEAR LOS DATOS DE PERSONAS
function formatDDPersona(data) {
  return map(data, (item) => ({
    key: item.id,
    label: `${item.num_doc}: ${item.apellidos}, ${item.nombres}`,
    value: item.id,
  }));
}

// FUNCIÓN PARA SETEAR LOS DATOS DE ÁREAS
function formatDDAreas(data) {
  return map(data, (item) => ({
    key: item.id,
    label: item.nombre,
    value: item.id,
  }));
}

// INICIALIZACIÓN DE LOS VALORES DEL FORMULARIO
function initialValues(data) {
  return {
    usuario: data?.usuario || "",
    persona: data?.persona || "",
    area: data?.area || "",
  };
}

// VALIDACIÓN DEL FORMULARIO PARA LA CREACIÓN O MODIFICACIÓN DE LOS DATOS DE UN TRABAJADOR
function Schame() {
  return {
    usuario: Yup.number().required("Seleccione un usuario"),
    // persona: Yup.number().required("Seleccione una persona"),
    area: Yup.number().required("Seleccione el área"),
  };
}
