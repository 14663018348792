// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER PERSONAS, SE PUEDE FILTRAR LA OBTENCIÓN DE LOS REGISTROS
export async function getPersonasApi(dni = "", ordering = "", token) {
  try {
    const dniFilter = `num_doc=${dni}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/personas/?${dniFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AGREGAR UNA NUEVA PERSONA
export async function addPersonaApi(data, token) {
  try {
    const url = `${BASE_API}/api/personas/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un error al añadir una nueva persona, es probable que el DNI ya este registrado",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente la nueva persona", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LOS DATOS DE UNA PERSONA
export async function updatePersonaApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/personas/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un error al actualizar los datos de la persona, es probable que el DNI ya este registrado",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente los cambios", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ELIMINAR UNA PERSONA
export async function deletePersonaApi(id, token) {
  try {
    const url = `${BASE_API}/api/personas/${id}/`;

    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);

    if (response.status === 400) {
      toast.error("Hubo un error al eliminar los datos de la persona", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
      });
    } else {
      toast.success("Se ha eliminado los datos de la persona", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
