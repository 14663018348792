// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Loader } from "semantic-ui-react";

import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import {
  HeaderPage,
  TableSolDesAdmin,
  AddEditSolDesForm,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE ICONOS
import { Search as SearchIcon } from "../../components/Admin/icons/search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// IMPORTACIÓN DE HOOKS CREADOS
import { useDesacopio } from "../../hooks";

export function SolicitudDesacopioAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { loading, desacopios, getDesacopios } = useDesacopio();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [id, setId] = useState(""); // PARA EL TEXT FIELD
  const [filtroId, setFiltroId] = useState(""); // PARA FILTRAR LA BÚSQUEDA POR ID

  const [selectEstado, setSelectEstado] = useState("VER_TODOS"); // PARA EL TEXT FIELD
  const [filtroEstado, setFiltroEstado] = useState(""); // PARA FILTRAR POR EL ESTADO DE LA SOLICITUD DE DESACOPIO

  const [ordering, setOrdering] = useState("-fecha_hora_solicitud"); // PARA EL ORDENAMIENTO DE LOS DATOS
  const [selectOrdering, setSelectOrdering] = useState("recientes"); // PARA EL TEXT FIELD

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  useEffect(
    () => getDesacopios(filtroId, filtroEstado, "", ordering),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, filtroId, filtroEstado, ordering, selectOrdering]
  ); // FUNCIÓN PARA OBTENER LAS SOLICITUDES DE DESACOPIOS, CON ALGUNOS FILTROS

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // ============================== FUNCIONES PARA LA SECCIÓN DE FILTROS ==============================
  // PARA REALIZAR LA BÚSQUEDA DE UN DESACOPIO POR ID, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeId = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setFiltroId(event.target.value);
    }
    setId(event.target.value);
  };

  // PARA VISUALIZAR LOS DESACOPIOS EN FUNCIÓN DEL ESTADO DEL DESACOPIO
  const handleChangeEstado = (event) => {
    setSelectEstado(event.target.value);
    if (event.target.value === "VER_TODOS") {
      setFiltroEstado("");
    } else {
      setFiltroEstado(event.target.value);
    }
  };

  // PARA DETERMINAR POR QUE VALOR SE VA A ORDENAR
  const handleChangeOrden = (event) => {
    setSelectOrdering(event.target.value);
    if (event.target.value === "recientes") {
      if (selectEstado === "VER_TODOS") {
        setOrdering("-fecha_hora_solicitud");
      } else {
        setOrdering("-fecha_hora_actualizacion");
      }
    } else {
      if (selectEstado === "VER_TODOS") {
        setOrdering("fecha_hora_solicitud");
      } else {
        setOrdering("fecha_hora_actualizacion");
      }
    }
  };

  // PARA ELIMINAR TODOS LOS FILTROS
  const handleChangeFilter = () => {
    setId("");
    setFiltroId("");
    setSelectEstado("VER_TODOS");
    setFiltroEstado("");
    setSelectOrdering("recientes");
    setOrdering("-fecha_hora_solicitud");
  };
  // =======================================================================================================

  // FUNCIÓN PARA VER TODOS LOS DETALLES DEL DESACOPIO
  const infDesacopio = (data) => {
    setTitleModal("Información del desacopio");
    setContentModal(
      <AddEditSolDesForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        desacopio={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Solicitudes de desacopios"
        subheader="En esta sección se encuentran todas las solicitudes de desacopios que se han realizado con su correspondiente estado actual."
      />

      {/* =================== SECCIÓN DE FILTROS ==================  */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* ======================= BÚSQUEDA POR ID ======================= */}
            <Grid md={2.1} item>
              <CssTextField
                size="small"
                inputProps={{
                  type: "number",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                value={id}
                placeholder="Buscar por ID"
                variant="outlined"
                onKeyDown={handleChangeId}
                onChange={handleChangeId}
                fullWidth
                sx={{ marginTop: 1 }}
              />
            </Grid>

            {/* ========= MOSTRAR SOLICITUDES DE ACUERDO A SU ESTADO  ========= */}
            <Grid item md={3}>
              <CssTextField
                select
                search="true"
                label="Filtrar por estado"
                value={selectEstado}
                onChange={handleChangeEstado}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"PENDIENTE"}>PENDIENTE</MenuItem>
                <MenuItem value={"APROBADO"}>APROBADO</MenuItem>
                <MenuItem value={"DESAPROBADO"}>DESAPROBADO</MenuItem>
                <MenuItem value={"VER_TODOS"}>VER TODOS</MenuItem>
              </CssTextField>
            </Grid>

            {/* ================ ORDENAMIENTO DE LOS REGISTROS ================ */}
            <Grid item md={3}>
              <CssTextField
                select
                search="true"
                label="Ordenar por"
                value={selectOrdering}
                onChange={handleChangeOrden}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"recientes"}>SOLICITUDES RECIENTES</MenuItem>
                <MenuItem value={"antiguas"}>SOLICITUDES ANTIGUAS</MenuItem>
              </CssTextField>
            </Grid>

            {/* ====================== LIMPIAR FILTROS ====================== */}
            <Grid item md={2} sx={{ marginTop: 0.25 }}>
              <Tooltip title="Limpiar filtro">
                <IconButton size="large" onClick={handleChangeFilter}>
                  <FilterAltOffIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* ========= CARGA DE LA TABLA DE SOLICITUDES DE DESACOPIOS ========= */}
          {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
            <TableSolDesAdmin
              desacopios={desacopios}
              infDesacopio={infDesacopio}
            />
          )}
        </CardContent>
      </Card>

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size="large"
      />
    </>
  );
}
