// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Loader } from "semantic-ui-react";

import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  IconButton,
  Tooltip,
} from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import {
  HeaderPage,
  TablePersona,
  AddEditPersonaForm,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE ICONOS
import { Search as SearchIcon } from "../../components/Admin/icons/search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// IMPORTACIÓN DE HOOKS CREADOS
import { usePersona } from "../../hooks";

export function PersonaAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { loading, personas, getPersonas, deletePersona } = usePersona();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [dni, setDni] = useState(""); // PARA EL TEXT FIELD
  const [filtroDni, setFiltroDni] = useState(""); // PARA FILTRAR LA BÚSQUEDAA POR DNI
  const [ordering] = useState("apellidos"); // PARA EL ORDENAMIENTO DE LOS DATOS

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  useEffect(
    () => getPersonas(filtroDni, ordering),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, filtroDni, ordering]
  );

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // ============================== FUNCIONES PARA LA SECCIÓN DE FILTROS ==============================
  // PARA REALIZAR LA BÚSQUEDA DE UNA PERSONA POR SU DNI, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeDni = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setFiltroDni(event.target.value);
    }
    setDni(event.target.value);
  };

  // PARA ELIMINAR TODOS LOS FILTROS
  const handleChangeFilter = () => {
    setDni("");
    setFiltroDni("");
  };
  // =======================================================================================================

  // FUNCIÓN PARA AÑADIR UNA NUEVA PERSONA, SE ABRIRÁ EL MODAL
  const addPersona = () => {
    setTitleModal("Nueva persona");
    setContentModal(
      <AddEditPersonaForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ACTUALIZAR LOS DATOS DE UNA PERSONAS, SE ABRIRÁ EL MODAL
  const updatePersona = (data) => {
    setTitleModal("Actualizar datos de la persona");
    setContentModal(
      <AddEditPersonaForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        persona={data}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UNA PERSONA CREADA
  const onDeletePersona = async (data) => {
    const result = window.confirm(
      `¿Eliminar a: ${data.nombres} ${data.apellidos}?`
    );
    if (result) {
      try {
        await deletePersona(data.id);
        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Personas"
        subheader="En esta sección podrás ver y modificar los datos de todas las personas."
        btnTitle="Nueva persona"
        btnClick={addPersona}
      />

      {/* =================== SECCIÓN DE FILTROS ==================  */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* ======================= BÚSQUEDA POR DNI ======================= */}
            <Grid item md={2.1}>
              <CssTextField
                size="small"
                inputProps={{
                  type: "number",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                value={dni}
                onKeyDown={handleChangeDni}
                placeholder="Buscar por DNI"
                variant="outlined"
                onChange={handleChangeDni}
                fullWidth
                sx={{ marginTop: 1 }}
              />
            </Grid>

            {/* ====================== LIMPIAR FILTROS ====================== */}
            <Grid item sx={{ marginTop: 0.25 }}>
              <Tooltip title="Limpiar filtro">
                <IconButton size="large" onClick={handleChangeFilter}>
                  <FilterAltOffIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* ========= CARGA DE LA TABLA DE PERSONAS ========= */}
          {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
            <TablePersona
              personas={personas}
              updatePersona={updatePersona}
              onDeletePersona={onDeletePersona}
            />
          )}
        </CardContent>
      </Card>

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size="large"
      />
    </>
  );
}
