// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CssListItemText,
  CssTablePagination,
} from "../../../Common/componentesCssMui";

import { SeverityPill } from "../../severity-pill";

// IMPORTACIÓN DE ICONOS
import WysiwygIcon from "@mui/icons-material/Wysiwyg";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./TableSolPrestamoSoli.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import moment from "moment";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE LAS SOLICITUDES DE PRESTAMOS
const columns = [
  { id: "id", label: "ID", minWidth: 100, align: "center" },
  {
    id: "fecha_hora_solicitud",
    label: "Fecha de solicitud",
    minWidth: 180,
    align: "center",
  },
  { id: "descripcion", label: "Descripción", minWidth: 300 },
  { id: "estado", label: "Estado actual", minWidth: 200, align: "center" },
  {
    id: "otras",
    label: "Otras fechas",
    align: "center",
    colSpan: 2,
  },
  {
    id: "acciones",
    label: "Acciones",
    minWidth: 100,
    align: "center",
  },
];

export function TableSolPrestamoSoli(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA SolicitudPrestamoSolicitante.js
  const { prestamos, infPrestamo } = props;

  // CREACIÓN DE LAS VARIABELS DE ESTADOS
  const [page, setPage] = React.useState(0); // PARA EL MANEJO DE LA PAGINACIÓN
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // CANTIDAD DE FILAS A MOSTRAR

  // FUNCIÓN PARA EL CAMBIO LA PÁGINA DE LA TABLA
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FUNCIÓN PARA CAMBIAR LA CANTIDAD DE FILAS A MOSTRAR
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      {prestamos ? (
        // DETALLES DE LAS SOLICITUDES DE LOS PRESTAMOS
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      colSpan={column.colSpan}
                      style={{
                        width: column.minWidth,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "500",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {prestamos
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((prestamo) => {
                    return (
                      <TableRow tabIndex={-1} key={prestamo.id}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {prestamo.id}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ py: FONTSIZE.paddingCell }}
                        >
                          <CssListItemText
                            primary={moment(prestamo.fecha_hora_solicitud)
                              .locale("es")
                              .format("DD/MM/YYYY")}
                            secondary={
                              "Hora:" +
                              moment(prestamo.fecha_hora_solicitud)
                                .locale("es")
                                .format("HH:mm")
                            }
                          />
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {prestamo.descripcion_solicitud.length > 70
                            ? prestamo.descripcion_solicitud.substr(0, 70) +
                              "..."
                            : prestamo.descripcion_solicitud
                            ? prestamo.descripcion_solicitud
                            : "S/D"}
                          {/* {prestamo.descripcion_solicitud} */}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          <SeverityPill
                            color={
                              prestamo.estado === "DEVUELTO"
                                ? "success"
                                : prestamo.estado === "PENDIENTE"
                                ? "warning"
                                : prestamo.estado === "APROBADO"
                                ? "info"
                                : "error"
                            }
                          >
                            {prestamo.estado}
                          </SeverityPill>
                        </TableCell>

                        <TableCell sx={{ py: FONTSIZE.paddingCell }}>
                          {prestamo.fecha_hora_actualizacion ? (
                            <CssListItemText
                              primary={
                                prestamo.estado === "DESAPROBADO"
                                  ? `Desaprobado por: ` +
                                    (prestamo.data_user_act?.last_name
                                      ? prestamo.data_user_act.last_name +
                                        ", " +
                                        prestamo.data_user_act.first_name
                                      : prestamo.data_user_act.email)
                                  : `Aprobado por: ` +
                                    (prestamo.data_user_act?.last_name
                                      ? prestamo.data_user_act.last_name +
                                        ", " +
                                        prestamo.data_user_act.first_name
                                      : prestamo.data_user_act.email)
                              }
                              secondary={
                                "Fecha: " +
                                moment(prestamo.fecha_hora_actualizacion)
                                  .locale("es")
                                  .format("DD/MM/YYYY, HH:mm")
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        <TableCell sx={{ py: FONTSIZE.paddingCell }}>
                          {prestamo.fecha_hora_devolucion ? (
                            <CssListItemText
                              primary={
                                "Devolición: " +
                                (prestamo.data_user_dev?.last_name
                                  ? prestamo.data_user_dev.last_name +
                                    ", " +
                                    prestamo.data_user_dev.first_name
                                  : prestamo.data_user_dev.email)
                              }
                              secondary={
                                "Fecha: " +
                                moment(prestamo.fecha_hora_devolucion)
                                  .locale("es")
                                  .format("DD/MM/YYYY, HH:mm")
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        <Actions
                          prestamo={prestamo}
                          infPrestamo={infPrestamo}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* == CONTROL DE LA PAGINACIÓN ======================================== */}
          <CssTablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={prestamos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Solicitudes por página:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES PARA VER LOS DETALLES DE LA SOLICITUD DEL PRÉSTAMO
function Actions(props) {
  const { prestamo, infPrestamo } = props;
  return (
    <>
      <TableCell align="center">
        <Tooltip title={"Ver detalles"}>
          <IconButton
            size={FONTSIZE.sizeIcon}
            onClick={() => infPrestamo(prestamo)}
            type="button"
          >
            <WysiwygIcon
              fontSize="inherit"
              color={
                prestamo.estado === "DESAPROBADO"
                  ? "error"
                  : prestamo.estado === "APROBADO"
                  ? "info"
                  : prestamo.estado === "PENDIENTE"
                  ? "warning"
                  : "success"
              }
            />
          </IconButton>
        </Tooltip>
      </TableCell>
    </>
  );
}
