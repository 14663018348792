// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CssTablePagination } from "../../../Common/componentesCssMui";

import { Icon } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import EditIcon from "@mui/icons-material/Edit";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./TableInsumoAdmin.scss";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE INSUMOS
const columns = [
  { id: "id", label: "", minWidth: 50, width: 50 },
  { id: "codigo", label: "Código" },
  { id: "categoria", label: "Categoría" },
  { id: "marca", label: "Marca", align: "center" },
  { id: "modelo", label: "Modelo", align: "center" },
  { id: "serie", label: "Serie", align: "center" },
  { id: "disponible", label: "Disponibilidad", align: "center" },

  {
    id: "acciones",
    label: "Acciones",
    align: "center",
  },
];

export function TableInsumoAdmin(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA InsumosAdmin.js
  const { insumos, onDeleteInsumo, updateInsumo } = props;

  // CREACIÓN DE LAS VARIABLES DE ESTADOS
  const [page, setPage] = React.useState(0); // PARA EL MANEJO DE LA PAGINACIÓN
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // CANTIDAD DE FILAS A MOSTRAR

  // FUNCIÓN PARA EL CAMBIO DE PÁGINA DE LA TABLA
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FUNCIÓN PARA CAMBIAR LA CANTIDAD DE FILAS A MOSTRAR
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {insumos ? (
        // DETALLES DE LOS INSUMOS
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              className="table-insumo-admin"
            >
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        width: column.width,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "500",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {insumos
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((insumo, index) => {
                    return (
                      <TableRow tabIndex={-1} key={insumo.id}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {index + 1 + page * rowsPerPage}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {insumo.codigo}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {insumo.categoria_data.nombre}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {insumo.marca}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {insumo.modelo ? insumo.modelo : "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {insumo.serie ? insumo.serie : "-"}
                        </TableCell>
                        <TableCell
                          className={"status"}
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {insumo.disponibilidad ? (
                            <Icon name="check" />
                          ) : (
                            <Icon name="close" />
                          )}
                        </TableCell>

                        <Actions
                          insumo={insumo}
                          updateInsumo={updateInsumo}
                          onDeleteInsumo={onDeleteInsumo}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* == CONTROL DE LA PAGINACIÓN ======================================== */}
          <CssTablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={insumos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Insumos por página:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES DE ACTUALIZAR O ELIMINAR DATOS DE UN INSUMO
function Actions(props) {
  const { insumo, updateInsumo } = props;
  return (
    <TableCell align="center" sx={{ py: FONTSIZE.paddingCell }}>
      {/* == BOTÓN PARA MODIFICAR DATOS DE UN INSUMO ============================= */}
      <Tooltip title="Editar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => updateInsumo(insumo)}
          type="button"
        >
          <EditIcon color="info" />
        </IconButton>
      </Tooltip>
      {/* BOTÓN PARA ELIMINAR UN INSUMO */}
      {/* <Tooltip title="Borrar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => onDeleteInsumo(insumo)}
          type="button"
        >
          <DeleteForeverIcon color="error" />
        </IconButton>
      </Tooltip> */}
    </TableCell>
  );
}
