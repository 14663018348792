// IMPORTACIÓN DE REACT
import React, { useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CssButtonRegresar } from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";

// IMPORTACIÓN DE ESTILOS
import "./AddEditSolPrestamoForm.scss";

// IMPORTACIÓN DE HOOKS CREADOS
import { usePrestamo } from "../../../../hooks";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE LOS INSUMOS QUE SE HAN PRESTADO
const columns2 = [
  { id: "nro2", label: "", minWidth: 50 },
  { id: "codigo", label: "Código" },
  { id: "categoria2", label: "Categoría" },
  { id: "marca", label: "Marca", align: "center" },
  { id: "modelo", label: "Modelo", align: "center" },
  { id: "serie", label: "Serie", align: "center" },
];

export function ListaDeInsumosAPrestar(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA SolicitudPrestamoAdmin.js
  const { prestamo, onClose } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { getInsumosByPrestamo, insumos } = usePrestamo();

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getInsumosByPrestamo(prestamo.id), []);

  return (
    //* ====================== SE VERIFICA SI YA SE OBTUVO TODAS LAS CATEGORÍAS ====================== */}
    <>
      {insumos && (
        <>
          {insumos.length > 0 ? (
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                marginTop: 2,
              }}
            >
              <TableContainer sx={{ maxHeight: 300 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  size="small"
                  className="table-acopio-admin"
                >
                  <TableHead>
                    <TableRow>
                      {columns2.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          colSpan={column.colSpan}
                          style={{
                            width: column.minWidth,
                            minWidth: column.minWidth,
                            background: "#2C6975",
                            fontSize: 16,
                            // fontWeight: "500",
                            color: "#FFFFFF",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {insumos.map((insumo, index) => {
                      return (
                        <TableRow tabIndex={-1} key={index}>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {insumo.insumos_data.codigo}
                          </TableCell>

                          <TableCell
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {insumo.insumos_data.categoria_data.nombre}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {insumo.insumos_data.marca}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {insumo.insumos_data.modelo}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {insumo.insumos_data.serie}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <h3>NO HAY INSUMO PARA PRESTAR</h3>
            </Box>
          )}
        </>
      )}

      {/* == BOTÓN PARA REGRESAR ===== */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 2,
        }}
      >
        <CssButtonRegresar
          startIcon={<ArrowBackSharpIcon fontSize="large" />}
          onClick={onClose}
        >
          Regresar
        </CssButtonRegresar>
      </Box>
    </>
  );
}
