// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getAreasApi,
  addAreaApi,
  updateAreaApi,
  deleteAreaApi,
} from "../api/area";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from "./";

export function useArea() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [areas, setAreas] = useState(null); // PARA ALMACENAR LAS ÁREAS

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE ÁREAS
  const getAreas = async (id, nombre, ordering) => {
    try {
      setLoading(true);
      const response = await getAreasApi(id, nombre, ordering, auth.token);
      setLoading(false);
      setAreas(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UNA NUEVA ÁREA
  const addArea = async (data) => {
    try {
      setLoading(true);
      await addAreaApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DE LOS DATOS DE UNA ÁREA
  const updateArea = async (id, data) => {
    try {
      setLoading(true);
      await updateAreaApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ELIMINACIÓN DE UNA ÁREA
  const deleteArea = async (id) => {
    try {
      setLoading(true);
      await deleteAreaApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getAreas,
    areas,
    addArea,
    updateArea,
    deleteArea,
    loading,
    error,
  };
}
