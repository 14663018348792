// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Loader } from "semantic-ui-react";

import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import {
  HeaderPage,
  TableTrabajador,
  AddEditTrabajadorForm,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE ICONOS
import { Search as SearchIcon } from "../../components/Admin/icons/search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// IMPORTACIÓN DE HOOKS CREADOS
import { useTrabajador, useArea } from "../../hooks";

export function TrabajadorAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { loading, trabajadores, getTrabajadores, deleteTrabajador } =
    useTrabajador();
  const { getAreas, areas } = useArea();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [dni, setDni] = useState(""); // PARA EL TEXT FIELD
  const [filtroDni, setFiltroDni] = useState(""); // PARA FILTRAR LA BÚSQUEDA POR DNI

  const [email, setEmail] = useState(""); // PARA EL TEXT FIELD
  const [filtroEmail, setFiltroEmail] = useState(""); // PARA FILTRAR LA BÚSQUEDA POR EMAIL

  const [selectArea, setSelectArea] = useState("VER_TODOS"); // PARA EL TEXT FIELD
  const [filtroArea, setFiltroArea] = useState(""); // FILTRA POR ÁREA A LA QUE PERTENECE EL TRABAJADOR

  const [buscarPor, setBuscarPor] = useState("DNI"); // PARA EL TEXT FIELD
  const [ordering] = useState("usuario__last_name"); // PARA EL ORDENAMIENTO DE LOS DATOS

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  useEffect(
    () =>
      getTrabajadores(
        filtroDni,
        filtroEmail.toLowerCase(),
        filtroArea,
        "",
        ordering
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, filtroDni, filtroEmail, filtroArea, ordering]
  );

  // PARA OBTENER LAS ÁREAS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAreas(), [refetch, filtroArea]);

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // ============================== FUNCIONES PARA LA SECCIÓN DE FILTROS ==============================
  // PARA DETERMINAR CUAL SERÁ EL MÉTODO DE FILTRO
  const handleChangeBuscarPor = (event) => {
    setBuscarPor(event.target.value);
    setDni("");
    setFiltroDni("");
    setEmail("");
    setFiltroEmail("");
  };

  // PARA REALIZAR LA BÚSQUEDA DE UN TRABAJADOR POR SU DNI, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeDni = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setFiltroDni(event.target.value);
    }
    setDni(event.target.value);
  };

  // PARA REALIZAR LA BÚSQUEDA DE UN TRABAJADOR POR SU EMAIL, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeEmail = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setFiltroEmail(event.target.value);
    }
    setEmail(event.target.value);
  };

  // PARA VISUALIZAR LOS INSUMOS QUE PERTENECEN A UNA CATEGORÍA
  const handleChangeAreas = (event) => {
    setSelectArea(event.target.value);
    if (event.target.value === "VER_TODOS") {
      setFiltroArea("");
    } else {
      setFiltroArea(event.target.value);
    }
  };

  // PARA ELIMINAR TODOS LOS FILTROS
  const handleChangeFilter = () => {
    setDni("");
    setFiltroDni("");
    setEmail("");
    setFiltroEmail("");
    setSelectArea("VER_TODOS");
    setFiltroArea("");
    setBuscarPor("DNI");
  };
  // =======================================================================================================

  // FUNCIÓN PARA AÑADIR UN NUEVO TRABAJADOR, SE ABRIRÁ UN MODAL
  const addTrabajador = () => {
    setTitleModal("Nueva trabajador");
    setContentModal(
      <AddEditTrabajadorForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ACTUALIZAR LOS DATOS DE UN TRABAJADOR, SE ABRIRÁ UN MODAL
  const updateTrabajador = (data) => {
    setTitleModal("Actualizar datos del trabajador");
    setContentModal(
      <AddEditTrabajadorForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        trabajador={data}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UN TRABAJADOR
  const onDeleteTrabajador = async (data) => {
    const result = window.confirm(
      `¿Eliminar a: ${data.user_data.last_name}, ${data.user_data.first_name}?`
    );
    if (result) {
      try {
        await deleteTrabajador(data.id);
        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Trabajadores"
        subheader="En esta sección podrás ver y modificar los datos de todos los trabajadores."
        btnTitle="Nuevo trabajador"
        btnClick={addTrabajador}
      />

      {/* =================== SECCIÓN DE FILTROS ==================  */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* =========== DETERMINAR EL CAMPO POR EL CUAL BUSCAR =========== */}
            <Grid item md={2.1}>
              <CssTextField
                select
                label="Buscar por"
                value={buscarPor}
                onChange={handleChangeBuscarPor}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"DNI"}>DNI</MenuItem>
                <MenuItem value={"EMAIL"}>CORREO ELECTRÓNICO</MenuItem>
              </CssTextField>
            </Grid>
            {buscarPor === "DNI" ? (
              <Grid item md={3}>
                {/* ======================= BÚSQUEDA POR DNI ======================= */}
                <CssTextField
                  size="small"
                  inputProps={{
                    type: "number",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  value={dni}
                  placeholder="Buscar por DNI"
                  variant="outlined"
                  onChange={handleChangeDni}
                  onKeyDown={handleChangeDni}
                  fullWidth
                  sx={{ marginTop: 1 }}
                />
              </Grid>
            ) : (
              <Grid item md={3}>
                {/* ======================= BÚSQUEDA POR EMAIL ======================= */}
                <CssTextField
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  value={email}
                  placeholder="Buscar por correo electrónico"
                  variant="outlined"
                  onChange={handleChangeEmail}
                  onKeyDown={handleChangeEmail}
                  fullWidth
                  sx={{ marginTop: 1 }}
                />
              </Grid>
            )}

            {/* ========== MOSTRAR TRABAJADORES POR ÁREA ========== */}
            <Grid item md={3}>
              <CssTextField
                select
                search="true"
                label="Filtrar por área"
                value={selectArea}
                onChange={handleChangeAreas}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"VER_TODOS"}>VER TODOS</MenuItem>

                {areas?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.nombre}
                  </MenuItem>
                ))}
              </CssTextField>
            </Grid>

            {/* ====================== LIMPIAR FILTROS ====================== */}
            <Grid item sx={{ marginTop: 0.25 }}>
              <Tooltip title="Limpiar filtro">
                <IconButton size="large" onClick={handleChangeFilter}>
                  <FilterAltOffIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* ========= CARGA DE LA TABLA DE TRABAJADORES ========= */}
          {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
            <TableTrabajador
              trabajadores={trabajadores}
              updateTrabajador={updateTrabajador}
              deleteTrabajador={onDeleteTrabajador}
            />
          )}
        </CardContent>
      </Card>

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}
