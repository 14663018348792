// IMPORTACIÓN DE REACT
import React, { useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  Box,
  Card,
  Grid,
  Divider,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CssListItemText,
  CssButtonRegresar,
  CssTypography,
} from "../../../Common/componentesCssMui";

import { Loader } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./AddEditSolPrestamoSoliForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import moment from "moment";

// IMPORTACIÓN DE HOOKS CREADOS
import { usePrestamo, useTrabajador } from "../../../../hooks";

export function AddEditSolPrestamoSoliForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA SolicitudPrestamoSolicitante.js
  const { prestamo, onClose } = props;

  // NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE SOLICITUD DE PRÉSTAMO
  const columns1 = [
    { id: "nro1", label: "", minWidth: 50 },
    { id: "categoria1", label: "Categoría" },
    {
      id: "cantidad_solicitada",
      label: "Cantidad solicitada",
      align: "center",
    },
    {
      id: "cantidad_entregada",
      label:
        prestamo.estado !== "PENDIENTE"
          ? "Cantidad entregada"
          : "Cantidad a entregar",
      align: "center",
    },
  ];

  // NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE LOS INSUMOS QUE SE HAN PRESTADO
  const columns2 = [
    { id: "nro2", label: "", minWidth: 50 },
    { id: "codigo", label: "Código" },
    { id: "categoria2", label: "Categoría" },
    { id: "marca", label: "Marca", align: "center" },
    { id: "modelo", label: "Modelo", align: "center" },
    { id: "serie", label: "Serie", align: "center" },
  ];

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const {
    getCategoriasByPrestamo,
    categorias,
    loading,
    getInsumosByPrestamo,
    insumos,
  } = usePrestamo();

  const {
    trabajadorAct,
    getTrabajadorActualizador,
    trabajadorDev,
    getTrabajadorDevolucion,
  } = useTrabajador();

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCategoriasByPrestamo(prestamo.id), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getInsumosByPrestamo(prestamo.id), []);

  useEffect(
    () =>
      prestamo.usuario_actualizacion &&
      getTrabajadorActualizador(prestamo.usuario_actualizacion),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prestamo]
  ); // DATOS DEL TRABAJADOR QUE ACTUALIZA EL ESTADO DEL PRÉSTAMO

  useEffect(
    () =>
      prestamo.usuario_devolucion &&
      getTrabajadorDevolucion(prestamo.usuario_devolucion),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prestamo]
  ); // DATOS DEL TRABAJADOR QUE MARCA COMO DEVUELTO EL PRÉSTAMO

  return (
    <form className="add-edit-soldes-form">
      {/* ====================== SE VERIFICA SI YA SE OBTUVO TODAS LAS CATEGORÍAS ====================== */}
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <>
          {categorias && (
            <>
              {/* == DETALLES DEL PRÉSTAMO REALIZADO ====================================== */}
              <Card
                style={{
                  border: "1px solid",
                  borderColor: "#2C6975",
                  boxShadow: "none",
                }}
                sx={{ px: 1, py: 0.5 }}
              >
                <Typography sx={{ fontSize: FONTSIZE.tableCell, px: 1 }}>
                  <b>ID: {prestamo.id}</b>
                </Typography>

                <Divider sx={{ borderColor: "#2C6975" }}></Divider>

                <Grid
                  container
                  direction="row"
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  sx={{ px: 1 }}
                >
                  <Grid item xs>
                    <CssListItemText
                      primary={
                        <>
                          <b>Fecha y hora de solicitud: </b>{" "}
                          {moment(prestamo.fecha_hora_solicitud)
                            .locale("es")
                            .format("DD/MM/YYYY, HH:mm")}{" "}
                        </>
                      }
                      secondary={
                        <>
                          <b>Descripción: </b>{" "}
                          {prestamo.descripcion_solicitud
                            ? prestamo.descripcion_solicitud
                            : "S/D"}
                        </>
                      }
                      sx={{ mb: 0 }}
                    />
                  </Grid>
                </Grid>
              </Card>

              {/* == DETALLES DE LOS INSUMOS DEL PRÉSTAMO ================================= */}
              <Divider sx={{ borderColor: "#2C6975", mt: 2, mb: 0.7 }} />

              <CssTypography align="center" sx={{ pb: 0.5 }}>
                Lista de insumos solicitados en el préstamo
              </CssTypography>

              <Paper sx={{ width: "100%", overflow: "hidden", mt: 0.1 }}>
                <TableContainer sx={{ maxHeight: 300 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    size="small"
                    className="table-acopio-admin"
                  >
                    {/* == ENCABEZADO DE LA TABLA ======================================== */}
                    <TableHead>
                      <TableRow>
                        {columns1.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            colSpan={column.colSpan}
                            style={{
                              width:
                                prestamo.estado === "PENDIENTE" &&
                                column.id === "cantidad_entregada"
                                  ? 0
                                  : column.minWidth,
                              minWidth:
                                prestamo.estado === "PENDIENTE" &&
                                column.id === "cantidad_entregada"
                                  ? 0
                                  : column.minWidth,
                              background: "#2C6975",
                              fontSize: FONTSIZE.tableRow,
                              // fontWeight: "500",
                              color: "#FFFFFF",
                            }}
                          >
                            {prestamo.estado === "PENDIENTE" &&
                            column.id === "cantidad_entregada"
                              ? ""
                              : column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* == CONTENIDO DE LA TABLA ======================================== */}
                    <TableBody>
                      {categorias?.map((categoria, index) => {
                        return (
                          <TableRow tabIndex={-1} key={index}>
                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {categoria.categoria_data.nombre}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {categoria.cantidad_solicitada}
                            </TableCell>

                            {prestamo.estado !== "PENDIENTE" && (
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                {categoria.cantidad_entregada}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          )}

          {prestamo.estado !== "PENDIENTE" && (
            <>
              {/* == SI EL PRÉSTAMO ESTA APROBADO O DESAPROBADO SE MUESTRA LOS DETALLES */}
              {trabajadorAct && (
                <Card
                  style={{
                    border: "1px solid",
                    borderColor: "#2C6975",
                    boxShadow: "none",
                  }}
                  sx={{ px: 1, py: 0.5, mt: 2 }}
                >
                  <Typography
                    sx={
                      prestamo.estado === "DESAPROBADO"
                        ? { fontSize: FONTSIZE.tableCell, color: "red", px: 1 }
                        : { fontSize: FONTSIZE.tableCell, color: "blue", px: 1 }
                    }
                  >
                    <b>
                      {prestamo.estado === "DESAPROBADO"
                        ? "Estado actual: DESAPROBADO"
                        : "APROBADO"}
                    </b>
                  </Typography>

                  <Divider sx={{ borderColor: "#2C6975" }}></Divider>

                  <Grid item xs sx={{ px: 1 }}>
                    <CssListItemText
                      primary={
                        <>
                          <b>Fecha y hora de actualización: </b>{" "}
                          {moment(prestamo.fecha_hora_actualizacion)
                            .locale("es")
                            .format("DD/MM/YYYY, HH:mm")}{" "}
                        </>
                      }
                      secondary={
                        <>
                          <b>Descripción: </b>{" "}
                          {prestamo.descripcion_actualizacion
                            ? prestamo.descripcion_actualizacion
                            : "S/D"}
                        </>
                      }
                      sx={{ mb: 0 }}
                    />
                  </Grid>

                  <Divider sx={{ borderColor: "#2C6975", mt: 0.5 }}></Divider>

                  <Grid
                    container
                    direction="row"
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    sx={{ px: 1 }}
                  >
                    <Grid item xs>
                      <CssListItemText
                        primary={
                          <>
                            <b>
                              {prestamo.estado === "DESAPROBADO"
                                ? "Desaprobado por: "
                                : "Aprobado por: "}
                            </b>
                            {prestamo.data_user_act?.last_name
                              ? prestamo.data_user_act.last_name +
                                ", " +
                                prestamo.data_user_act.first_name
                              : prestamo.data_user_act.email}
                          </>
                        }
                        sx={{ mb: 0 }}
                      />
                    </Grid>

                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ borderColor: "#2C6975" }}
                      sx={{ mt: 0.5 }}
                    />

                    <Grid item xs>
                      <CssListItemText
                        primary={
                          <>
                            <b>Trabaja en el área de: </b>{" "}
                            {trabajadorAct?.length !== 0
                              ? trabajadorAct[0].area_data.nombre
                              : "No identificado"}
                          </>
                        }
                        sx={{ mb: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              )}

              {/* == SE VERIFICA SI EL ESTADO ES DESAPROBADO  */}
              {prestamo.estado !== "DESAPROBADO" && (
                <>
                  {/* == SI EL ESTADO NO ES DESAPROBADO, ES PORQUE ESTA APROBADO */}
                  {insumos ? (
                    <>
                      {/* == SE MUESTRA EL LISTADO DE LOS INSUMOS QUE FORMAN PARTE DEL PRÉSTAMO */}
                      <Divider
                        sx={{ borderColor: "#2C6975", mt: 2, mb: 0.7 }}
                      />

                      <CssTypography align="center" sx={{ pb: 0.5 }}>
                        Lista de insumos prestados
                      </CssTypography>

                      <Paper
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          marginTop: 0.1,
                        }}
                      >
                        <TableContainer sx={{ maxHeight: 300 }}>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            size="small"
                            className="table-acopio-admin"
                          >
                            {/* == ENCABEZADO DE LA TABLA ======================================== */}
                            <TableHead>
                              <TableRow>
                                {columns2.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    colSpan={column.colSpan}
                                    style={{
                                      width: column.minWidth,
                                      minWidth: column.minWidth,
                                      background: "#2C6975",
                                      fontSize: FONTSIZE.tableRow,
                                      // fontWeight: "500",
                                      color: "#FFFFFF",
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* == CONTENIDO DE LA TABLA ======================================== */}
                            <TableBody>
                              {insumos?.map((insumo, index) => {
                                return (
                                  <TableRow tabIndex={-1} key={index}>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: FONTSIZE.tableCell,
                                      }}
                                    >
                                      {index + 1}
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        fontSize: FONTSIZE.tableCell,
                                      }}
                                    >
                                      {insumo.insumos_data.codigo}
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        fontSize: FONTSIZE.tableCell,
                                      }}
                                    >
                                      {
                                        insumo.insumos_data.categoria_data
                                          .nombre
                                      }
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: FONTSIZE.tableCell,
                                      }}
                                    >
                                      {insumo.insumos_data.marca}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: FONTSIZE.tableCell,
                                      }}
                                    >
                                      {insumo.insumos_data.modelo}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: FONTSIZE.tableCell,
                                      }}
                                    >
                                      {insumo.insumos_data.serie}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* == SE VERIFICA SI EL ESTADO ES DEVUELTO ================ */}
                  {prestamo.estado === "DEVUELTO" && (
                    <>
                      {/* == SI EL ESTADO ES DEVUELTO SE MUESTRAN LOS DETALLES DE LA DEVOLUCIÓN */}
                      {trabajadorDev && (
                        <Card
                          style={{
                            border: "1px solid",
                            borderColor: "#2C6975",
                            boxShadow: "none",
                          }}
                          sx={{ px: 1, py: 0.5, mt: 2 }}
                        >
                          <Typography
                            sx={{
                              fontSize: FONTSIZE.tableCell,
                              color: "green",
                              px: 1,
                            }}
                          >
                            <b>Estado actual: {prestamo.estado}</b>
                          </Typography>

                          <Divider sx={{ borderColor: "#2C6975" }}></Divider>

                          <Grid item xs sx={{ px: 1 }}>
                            <CssListItemText
                              primary={
                                <>
                                  <b>Fecha y hora de devolución: </b>{" "}
                                  {moment(prestamo.fecha_hora_devolucion)
                                    .locale("es")
                                    .format("DD/MM/YYYY, HH:mm")}{" "}
                                </>
                              }
                              secondary={
                                <>
                                  <b>Descripción: </b>{" "}
                                  {prestamo.descripcion_devolucion
                                    ? prestamo.descripcion_devolucion
                                    : "S/D"}
                                </>
                              }
                              sx={{ mb: 0 }}
                            />
                          </Grid>

                          <Divider
                            sx={{ borderColor: "#2C6975", mt: 0.5 }}
                          ></Divider>

                          <Grid
                            container
                            direction="row"
                            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                            sx={{ px: 1 }}
                          >
                            <Grid item xs>
                              <CssListItemText
                                primary={
                                  <>
                                    <b>Reportado como devuelto por: </b>{" "}
                                    {prestamo.data_user_dev?.last_name
                                      ? prestamo.data_user_dev.last_name +
                                        ", " +
                                        prestamo.data_user_dev.first_name
                                      : prestamo.data_user_dev.email}
                                  </>
                                }
                                sx={{ mb: 0 }}
                              />
                            </Grid>
                            <Divider
                              orientation="vertical"
                              flexItem
                              style={{ borderColor: "#2C6975" }}
                              sx={{ mt: 0.5 }}
                            />

                            <Grid item xs>
                              <CssListItemText
                                primary={
                                  <>
                                    <b>Trabaja en el área de: </b>{" "}
                                    {trabajadorDev?.length !== 0
                                      ? trabajadorDev[0].area_data.nombre
                                      : "No identificado"}
                                  </>
                                }
                                sx={{ mb: 0 }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}

          {/* == BOTÓN PARA REGRESAR ===== */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 2,
            }}
          >
            <CssButtonRegresar
              startIcon={<ArrowBackSharpIcon fontSize="large" />}
              onClick={onClose}
            >
              Regresar
            </CssButtonRegresar>
          </Box>
        </>
      )}
    </form>
  );
}
