// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA PODER INICIAR SESIÓN
export async function loginApi(formValue) {
  try {
    const url = `${BASE_API}/api/auth/login/`;

    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValue),
    };

    const response = await fetch(url, params);
    if (response.status !== 200) {
      throw new Error("Correo electrónico o contraseña incorrecta");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA PODER OBTENER LOS DATOS DEL USUARIO LOGUEADO
export async function getMeApi(token) {
  try {
    const url = `${BASE_API}/api/auth/me/`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER USUARIOS
export async function getUsersApi(
  username = "",
  email = "",
  permiso = "",
  is_active = "",
  ordering = "",
  token
) {
  try {
    const usernameFilter = `username=${username}`;
    const emailFilter = `email=${email}`;
    const permisoFilter = `permiso=${permiso}`;
    const activeFilter = `is_active=${is_active}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/usuarios/?${usernameFilter}&${emailFilter}&${permisoFilter}&${activeFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AGREGAR UN NUEVO USUARIO
export async function addUserApi(data, token) {
  try {
    const url = `${BASE_API}/api/usuarios/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (response.status === 400) {
      toast.error(
        "Hubo un error al añadir un nuevo usuario, es probable que el nombre de usuario o correo electrónico ya estén registrado",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente el nuevo usuario", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LOS DATOS DE UN USUARIO
export async function updateUserApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/usuarios/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (response.status === 400) {
      toast.error(
        "Hubo un error al actualizar los datos del usuario, es probable que el nombre de usuario o correo electrónico ya estén registrado",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente los cambios", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ELIMINAR UN USUARIO
export async function deleteUserApi(id, token) {
  try {
    const url = `${BASE_API}/api/usuarios/${id}/`;

    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);

    if (response.status === 400) {
      toast.error("Hubo un error al eliminar al usuario", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
      });
    } else {
      toast.success("Se ha eliminado al usuario", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
