// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Loader } from "semantic-ui-react";

import {
  Grid,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import {
  HeaderPage,
  TableUsers,
  AddEditUserForm,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE ICONOS
import { Search as SearchIcon } from "../../components/Admin/icons/search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

// IMPORTACIÓN DE HOOKS CREADOS
import { useUser } from "../../hooks";

export function UsersAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { loading, users, getUsers, deleteUser } = useUser();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [username, setUsername] = useState(""); // PARA EL TEXT FIELD
  const [filtroUsername, setFiltroUsername] = useState(""); // PARA FILTRAR LA BÚSQUEDA POR EL NOMBRE DE USUARIO

  const [email, setEmail] = useState(""); // PARA EL TEXT FIELD
  const [filtroEmail, setFiltroEmail] = useState(""); // PARA FILTRAR LA BÚSQUEDA POR EMAIL

  const [selectPermiso, setSelectPermiso] = useState("VER_TODOS"); // PARA EL TEXT FIELD
  const [filtroPermiso, setFiltroPermiso] = useState(""); // PARA FILTRAR POR PERMISO DEL USUARIO

  const [selectActivo, setSelectActivo] = useState("ACTIVO"); // PARA EL TEXT FIELD
  const [filtroActivo, setFiltroActivo] = useState(true); // PARA FILTRAR SI ESTAN O NO ACTIVOS LOS USUARIOS

  const [buscarPor, setBuscarPor] = useState("USERNAME"); // PARA EL TEXT FIELD
  const [ordering] = useState("last_name"); // PARA EL ORDENAMIENTO DE LOS DATOS

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  useEffect(
    () =>
      getUsers(
        filtroUsername.toLowerCase(),
        filtroEmail.toLowerCase(),
        filtroPermiso,
        filtroActivo,
        ordering
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      refetch,
      filtroUsername,
      filtroEmail,
      filtroPermiso,
      filtroActivo,
      ordering,
    ]
  );

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // ============================== FUNCIONES PARA LA SECCIÓN DE FILTROS ==============================
  // PARA DETERMINAR CUAL SERÁ EL MÉTODO DE FILTRO
  const handleChangeBuscarPor = (event) => {
    setBuscarPor(event.target.value);
    setUsername("");
    setFiltroUsername("");
    setEmail("");
    setFiltroEmail("");
  };

  // PARA REALIZAR LA BÚSQUEDA DE UN USUARIO POR SU USERNAME, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeUsername = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setFiltroUsername(event.target.value);
    }
    setUsername(event.target.value);
  };

  // PARA REALIZAR LA BÚSQUEDA DE UN USUARIO POR SU EMAIL, SE BUSCARÁ EN EL MOMENTO EN QUE SE APRIETA LA TECLA ENTER
  const handleChangeEmail = (event) => {
    if (event.keyCode === 13 || event.target.value === "") {
      setFiltroEmail(event.target.value);
    }
    setEmail(event.target.value);
  };

  // PARA VISUALIZAR LOS USUARIOS EN FUNCIÓN DE SUS PERMISOS
  const handleChangePermiso = (event) => {
    setSelectPermiso(event.target.value);
    if (event.target.value === "VER_TODOS") {
      setFiltroPermiso("");
    } else {
      setFiltroPermiso(event.target.value);
    }
  };

  // PARA VISUALIZAR LOS USUARIOS EN FUNCIÓN DE QUE SI ESTÁN ACTIVOS O NO
  const handleChangeActivo = (event) => {
    setSelectActivo(event.target.value);
    if (event.target.value === "VER_TODOS") {
      setFiltroActivo("");
    } else {
      if (event.target.value === "ACTIVO") {
        setFiltroActivo(true);
      } else {
        setFiltroActivo(false);
      }
    }
  };

  // PARA ELIMINAR TODOS LOS FILTROS
  const handleChangeFilter = () => {
    setUsername("");
    setFiltroUsername("");
    setEmail("");
    setFiltroEmail("");
    setSelectPermiso("VER_TODOS");
    setFiltroPermiso("");
    setSelectActivo("ACTIVO");
    setFiltroActivo(true);
    setBuscarPor("USERNAME");
  };
  // =======================================================================================================

  // FUNCIÓN PARA AÑADIR UN NUEVO USUARIO, SE ABRIRÁ EL MODAL
  const addUser = () => {
    setTitleModal("Nuevo usuario");
    setContentModal(
      <AddEditUserForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ACTUALIZAR LOS DATOS DE UN USUARIO, SE ABRIRÁ EL MODAL
  const updateUser = (data) => {
    setTitleModal("Actualizar datos del usuario");
    setContentModal(
      <AddEditUserForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        user={data}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UN USUARIO
  const onDeleteUser = async (data) => {
    const result = window.confirm(`¿Eliminar usuario: ${data.email}?`);
    if (result) {
      try {
        await deleteUser(data.id);
        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Usuarios"
        subheader="En esta sección podrás ver y modificar los datos de todos los usuarios."
        btnTitle="Nuevo usuario"
        btnClick={addUser}
      />

      {/* =================== SECCIÓN DE FILTROS ==================  */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* =========== DETERMINAR EL CAMPO POR EL CUAL BUSCAR =========== */}
            <Grid item md={2.1}>
              <CssTextField
                select
                label="Buscar por"
                value={buscarPor}
                onChange={handleChangeBuscarPor}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"USERNAME"}>NOMBRE DE USUARIO</MenuItem>
                <MenuItem value={"EMAIL"}>CORREO ELECTRÓNICO</MenuItem>
              </CssTextField>
            </Grid>
            {buscarPor === "USERNAME" ? (
              <Grid item md={3}>
                {/* ================ BÚSQUEDA POR NOMBRE DE USUARIO ================ */}
                <CssTextField
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  value={username}
                  placeholder="Buscar por nombre de usuario"
                  variant="outlined"
                  onChange={handleChangeUsername}
                  onKeyDown={handleChangeUsername}
                  fullWidth
                  sx={{ marginTop: 1 }}
                />
              </Grid>
            ) : (
              <Grid item md={3}>
                {/* ======================= BÚSQUEDA POR EMAIL ======================= */}
                <CssTextField
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  value={email}
                  placeholder="Buscar por correo electrónico"
                  variant="outlined"
                  onChange={handleChangeEmail}
                  onKeyDown={handleChangeEmail}
                  fullWidth
                  sx={{ marginTop: 1 }}
                />
              </Grid>
            )}

            {/* ========== MOSTRAR USUARIOS DE ACUERDO A LOS PERMISOS ========== */}
            <Grid item md={3}>
              <CssTextField
                select
                search="true"
                label="Filtrar por permisos"
                value={selectPermiso}
                onChange={handleChangePermiso}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"ADMIN"}>ADMINISTRADOR</MenuItem>
                <MenuItem value={"COMPRAS"}>COMPRAS</MenuItem>
                <MenuItem value={"SOLICITANTE"}>SOLICITANTE</MenuItem>
                <MenuItem value={"VER_TODOS"}>VER TODOS</MenuItem>
              </CssTextField>
            </Grid>

            {/* ======= MOSTRAR USUARIOS DE ACUERDO A SI ES ACTIVO O NO ======= */}
            <Grid item md={3}>
              <CssTextField
                select
                search="true"
                label="Estado del usuario"
                value={selectActivo}
                onChange={handleChangeActivo}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={"ACTIVO"}>ACTIVO</MenuItem>
                <MenuItem value={"INACTIVO"}>INACTIVO</MenuItem>
                <MenuItem value={"VER_TODOS"}>VER TODOS</MenuItem>
              </CssTextField>
            </Grid>

            {/* ====================== LIMPIAR FILTROS ====================== */}
            <Grid item sx={{ marginTop: 0.25 }}>
              <Tooltip title="Limpiar filtro">
                <IconButton size="large" onClick={handleChangeFilter}>
                  <FilterAltOffIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* ========= CARGA DE LA TABLA DE USUARIOS ========= */}
          {loading ? (
            <Loader active inline="centered">
              Cargando...
            </Loader>
          ) : (
            <TableUsers
              users={users}
              updateUser={updateUser}
              onDeleteUser={onDeleteUser}
            />
          )}
        </CardContent>
      </Card>

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}
