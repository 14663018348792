// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER LOS ACOPIOS, SE PUEDE FILTRAR LA OBTENCIÓN DE LOS REGISTROS
export async function getAcopiosApi(
  id = "",
  usuario = "",
  id_presupuesto = "",
  ordering = "",
  token
) {
  try {
    const idFilter = `id=${id}`;
    const usuarioFilter = `usuario=${usuario}`;
    const presupuestoFilter = `presupuesto=${id_presupuesto}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/acopios/?${idFilter}&${usuarioFilter}&${presupuestoFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AGREGAR NUEVOS ACOPIOS
export async function addAcopioApi(data, token) {
  try {
    const url = `${BASE_API}/api/acopios/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 201) {
      toast.success("Se ha registrado correctamente el acopio", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER LOS INSUMOS ASOCIADOS A UN ACOPIO
export async function getInsumosByAcopioApi(acopio, ordering = "", token) {
  try {
    const acopiosFilter = `acopio=${acopio}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/acopiosInsumosTea/?${acopiosFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER LOS ACOPIOS ASOCIADOS A UN PRESUPUESTO
export async function getAcopiosByPresupuestoApi(
  id_presupuesto = "",
  ordering = "",
  token
) {
  try {
    const presupuestoFilter = `presupuesto=${id_presupuesto}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/acopios/?${presupuestoFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
