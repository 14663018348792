// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import { MenuItem, Grid } from "@mui/material";
import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

import { Checkbox } from "semantic-ui-react";

// IMPORTACIÓN DE ESTILOS
import "./AddEditUserForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { useFormik } from "formik";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { useUser } from "../../../../hooks";

export function AddEditUserForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA UsersAdmin.js
  const { onClose, onRefetch, user } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { addUser, updateUser } = useUser();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = React.useState(false);

  // DEFINICIÓN DEL FORMIK Y YUP PARA LA VALIDACIÓN DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(user),
    validationSchema: Yup.object(user ? updateSchame() : newSchame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        setLoading(true);
        // CONVERSIÓN DATOS IMPORTANTES A MINÚSCULA
        formValue.email = formValue.email.toLowerCase();
        formValue.username = formValue.username.toLowerCase();

        // console.log(formValue);
        if (user) await updateUser(user.id, formValue);
        else await addUser(formValue);
        onRefetch();
        onClose();
        // console.log("Usuario Creado Correctamente");
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form className="add-edit-user-form" onSubmit={formik.handleSubmit}>
      {/* =================== GRILLA DE 2 COLUMNAS [NOMBRE Y APELLIDO] ===================*/}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        {/* =============== NOMBRE =============== */}
        <Grid item xs>
          <CssTextField
            label="Nombres"
            name="first_name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={Boolean(
              formik.touched.first_name && formik.errors.first_name
            )}
            helperText={formik.touched.first_name && formik.errors.first_name}
            onBlur={formik.handleBlur}
            // placeholder="Nombre"
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          />
        </Grid>

        {/* =============== APELLIDO =============== */}
        <Grid item xs>
          <CssTextField
            label="Apellidos"
            name="last_name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.last_name && formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
            onBlur={formik.handleBlur}
            // placeholder="Apellido"
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          />
        </Grid>
      </Grid>

      {/* =============== CORREO ELECTRÓNICO =============== */}
      <CssTextField
        // inputProps={{ style: { textTransform: "lowercase" } }}
        label="Correo electrónico"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.email && formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        onBlur={formik.handleBlur}
        // placeholder="Email"
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      />

      {/* =============== NOMBRE DE USUARIO =============== */}
      <CssTextField
        // inputProps={{ style: { textTransform: "lowercase" } }}
        label="Nombre de usuario"
        name="username"
        value={formik.values.username}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.username && formik.errors.username)}
        helperText={formik.touched.username && formik.errors.username}
        onBlur={formik.handleBlur}
        // placeholder="Nombre de Usuario"
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      />

      {/* =============== CONTRASEÑA =============== */}
      <CssTextField
        label={"Contraseña"}
        name="password"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.password && formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        onBlur={formik.handleBlur}
        // placeholder="Contraseña"
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      />

      {/* =============== CHECK SI ES UN USUARIO ACTIVO =============== */}
      <div className="add-edit-user-form__active">
        <Checkbox
          toggle
          checked={formik.values.is_active}
          onChange={(_, data) =>
            formik.setFieldValue("is_active", data.checked)
          }
        />{" "}
        Usuario activo
      </div>

      {/* =============== CHECK SI ES UN USUARIO ADMINISTRADOR =============== */}
      <div className="add-edit-user-form__staff">
        <Checkbox
          toggle
          checked={formik.values.is_staff}
          onChange={(_, data) => formik.setFieldValue("is_staff", data.checked)}
        />{" "}
        Usuario administrador
      </div>

      {/* =============== SELECT DE PERMISOS =============== */}
      <CssTextField
        name="permiso"
        select
        search="true"
        label="Nivel de permiso"
        value={formik.values.permiso}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.permiso && formik.errors.permiso)}
        helperText={formik.touched.permiso && formik.errors.permiso}
        onBlur={formik.handleBlur}
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      >
        <MenuItem value={"ADMIN"}>ADMINISTRADOR</MenuItem>
        <MenuItem value={"COMPRAS"}>COMPRAS</MenuItem>
        <MenuItem value={"SOLICITANTE"}>SOLICITANTE</MenuItem>
      </CssTextField>

      <hr />

      {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
      <Grid
        container
        direction="row"
        justifyContent="right"
        alignItems="center"
        columnSpacing={{ md: 1.5 }}
      >
        <Grid item>
          <CssButtonCancelar
            startIcon={<CloseIcon fontSize="large" />}
            onClick={onClose}
          >
            Cancelar
          </CssButtonCancelar>
        </Grid>
        <Grid item>
          <CssLoadingButton
            endIcon={
              user ? (
                <ReplaySharpIcon fontSize="large" />
              ) : (
                <AddTaskOutlinedIcon fontSize="large" />
              )
            }
            variant="contained"
            loading={loading}
            type="submit"
          >
            {user ? "Actualizar" : "Crear"}
          </CssLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

// INICIALIZACIÓN DE LOS VALORES INICIALES DEL FORMULARIO
function initialValues(data) {
  return {
    username: data?.username || "",
    email: data?.email || "",
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    password: "",
    is_active: data?.is_active ? true : false,
    is_staff: data?.is_staff ? true : false,
    permiso: data?.permiso || "",
  };
}

// VALIDACIÓN DE LOS CAMPOS DEL FORMULARIO PARA NUEVOS USUARIOS
function newSchame() {
  return {
    username: Yup.string()
      .required("Ingrese un nombre de usuario")
      .min(3, "El nombre de usuario debe que tener al menos 3 caracteres"),
    email: Yup.string()
      .email("Ingrese un correo electrónico valido")
      .required("Ingrese un correo electrónico"),
    first_name: Yup.string()
      .required("Ingrese un nombre")
      .min(3, "El nombre debe que tener al menos 3 letras"),
    last_name: Yup.string()
      .required("Ingrese el apellido")
      .min(3, "El apellido debe que tener al menos 3 letras"),
    password: Yup.string().required("Ingrese una contraseña"),
    is_active: Yup.bool().required(true),
    is_staff: Yup.bool().required(true),
    permiso: Yup.string().required("Ingrese el nivel de permiso"),
  };
}

// VALIDACIÓN DE LOS CAMPOS DEL FORMULARIO PARA USUARIOS EXISTENTES
function updateSchame() {
  return {
    username: Yup.string()
      .required("Ingrese un nombre de usuario")
      .min(3, "El nombre de usuario debe que tener al menos 3 caracteres"),
    email: Yup.string()
      .email("Ingrese un correo electrónico valido")
      .required("Ingrese un correo electrónico"),
    first_name: Yup.string()
      .required("Ingrese un nombre")
      .min(3, "El nombre debe que tener al menos 3 letras"),
    last_name: Yup.string()
      .required("Ingrese el apellido")
      .min(3, "El apellido debe que tener al menos 3 letras"),
    password: Yup.string(),
    is_active: Yup.bool().required(true),
    is_staff: Yup.bool().required(true),
    permiso: Yup.string().required("Ingrese el nivel de permiso"),
  };
}
