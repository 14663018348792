// IMPORTACIÓN DE REACT
import React from "react";
import { Link } from "react-router-dom";

// IMPORTACIÓN DE COMPONENTES
import { Box, Button, Container, Typography } from "@mui/material";

// IMPORTACIÓN DE ICONOS
import MarkEmailReadSharpIcon from "@mui/icons-material/MarkEmailReadSharp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// IMPORTACIÓN DE ESTILOS
import "./Validacion.scss";

export function Validacion() {
  return (
    <Box
      className="validacion"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100%",
      }}
    >
      <Container maxWidth="md" className="validacion__content">
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* ======================= TEXTOS ======================= */}
          <div className="validacion__content__logo">
            <b>SAME</b>
          </div>

          <Typography align="center" color="rgb(32, 235, 36)" variant="h2">
            ¡Validación correcta!
          </Typography>

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography align="center" color="white" variant="h4">
              Su correo electrónico fue validado correctamente, ahora puede
              acceder a la página
            </Typography>

            <Box sx={{ textAlign: "center" }}>
              <MarkEmailReadSharpIcon
                sx={{ fontSize: 300, color: "rgb(21, 217, 24)" }}
              />
            </Box>
          </Box>

          {/* ================== BOTÓN PARA REGRESAR ================ */}
          <Link to="/admin">
            <Button
              className="validacion__content__button"
              startIcon={<ArrowBackIcon fontSize="small" />}
              variant="contained"
              sx={{
                background: "#296686",
                "&:hover": {
                  background: "#26788b",
                },
              }}
            >
              Volver a la página principal
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
