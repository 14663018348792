// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN PARA EL SISTEMA DE RUTAS
import { BrowserRouter, Routes, Route } from "react-router-dom";

// IMPORTACIÓN DE LAS ROUTES
import routesAdministradores from "./routes.administrador";
import routesComercios from "./routes.comercio";
import routesSolicitante from "./routes.solicitante";

// IMPORTACIÓN DE HOOKS
import { useAuth } from "../hooks";

// IMPORTACIÓN DE UTILIDADES
import { map } from "lodash";

export function Navigation() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { auth } = useAuth();

  // RENDERIZACIÓN DE LAS RUTAS PARA EL USUARIO ADMINISTRADOR
  if (auth?.me.permiso === "ADMIN" || auth?.me.is_staff) {
    return (
      <BrowserRouter>
        <Routes>
          {map(routesAdministradores, (route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <route.layout>
                  <route.component />
                </route.layout>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    );
  } else {
    // RENDERIZACIÓN DE LAS RUTAS PARA EL USUARIO DE COMPRAS
    if (auth?.me.permiso === "COMPRAS") {
      return (
        <BrowserRouter>
          <Routes>
            {map(routesComercios, (route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <route.layout>
                    <route.component />
                  </route.layout>
                }
              />
            ))}
          </Routes>
        </BrowserRouter>
      );
    } else {
      // RENDERIZACIÓN DE LAS RUTAS PARA EL USUARIO SOLICITANTE
      return (
        <BrowserRouter>
          <Routes>
            {map(routesSolicitante, (route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <route.layout>
                    <route.component />
                  </route.layout>
                }
              />
            ))}
          </Routes>
        </BrowserRouter>
      );
    }
  }
}
