// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Autocomplete, Card, Grid, MenuItem } from "@mui/material";

import { CssTextField } from "../../components/Common/componentesCssMui";

import {
  HeaderPage,
  FootPage,
  AddEditAcopioForm,
  TableAcopioAdmin,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { map } from "lodash";

// IMPORTACIÓN DE HOOKS CREADOS
import {
  useCategoria,
  useAcopio,
  useAuth,
  useProveedor,
  usePresupuesto,
} from "../../hooks";

export function AcopioAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { categorias, getCategorias } = useCategoria();
  const { addAcopio } = useAcopio();
  const { getProveedores, proveedores } = useProveedor();
  const { presupuestos, getPresupuestos } = usePresupuesto();
  const { auth } = useAuth();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA
  const [acopio, setAcopio] = useState([]); // PARA ALMACENAR LOS INSUMOS DEL ACOPIO
  const [descripcion, setDescripcion] = useState(""); // PARA ALMACENAR LA DESCRIPCIÓN DEL ACOPIO

  const [codigo, setCodigo] = useState(""); // PARA ALMACENAR EL CÓDIGO DE FACTURA DEL ACOPIO
  const [errorCodigo, setErrorCodigo] = useState(false);

  const [montoTotal, setMontoTotal] = useState(0); // PARA ALMACENAR EL VALOR TOTAL DEL ACOPIO

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [proveedoresFormat, setProveedoresFormat] = useState([]); // SETEA LOS DATOS DE PROVEEDORES
  const [selectPresupuesto, setSelectPresupuesto] = useState(""); // PARA EL TEXT FIELD
  const [errorPresupuesto, setErrorPresupuesto] = useState(false);

  const [value, setValue] = useState(null); // PARA EL VALOR DEL AUTOCOMPLETE
  const [inputValue, setInputValue] = useState(""); // PARA EL VALOR DE LA OPCIÓN SELECCIONADA DEL AUTOCOMPLETE

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCategorias("", "", "", "", "nombre"), [refetch]); // FUNCIÓN PARA OBTENER TODAS LAS CATEGORÍAS CREADAS

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getProveedores("", "", "", "nombre"), []); // FUNCIÓN PARA OBTENER TODOS LOS PROVEEDORES CREADOS

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPresupuestos("", "", "-anio"), [refetch]); // FUNCIÓN PARA OBTENER TODOS LOS PRESUPUESTOS CREADOS

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => setProveedoresFormat(formatProveedores(proveedores)),
    [proveedores]
  ); // SETEO DE LOS DATOS DE PERSONAS

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  const handleChange = (event) => {
    setDescripcion(event.target.value);
  }; // PARA ALMACENAR LOS VALORES DEL TEXT FIELD DE LA DESCRIPCIÓN

  const handleChangeCodigo = (event) => {
    setCodigo(event.target.value);
    if (event.target.value === "") {
      setErrorCodigo(true);
    } else {
      setErrorCodigo(false);
    }
  }; // PARA ALMACENAR LOS VALORES DEL TEXT FIELD DEL CÓDIGO DE FACTURA

  const handleChangePresupuesto = (event) => {
    setSelectPresupuesto(event.target.value);
    setErrorPresupuesto(false);
  };

  // FUNCIÓN PARA AÑADIR UN NUEVO INSUMO AL ACOPIO, SE ABRIRÁ EL MODAL
  const addInsumo = () => {
    setTitleModal("Añadir un nuevo insumo");
    setContentModal(
      <AddEditAcopioForm
        onClose={openCloseModal}
        acopio={acopio}
        setAcopio={setAcopio}
        setMontoTotal={setMontoTotal}
        montoTotal={montoTotal}
        categorias={categorias}
        onRefetch={onRefetch}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA CAMBIAR LOS DATOS DE UN INSUMO DEL ACOPIO, SE ABRIRÁ EL MODAL
  const updateInsumo = (data, index) => {
    setTitleModal("Actualizar datos del insumo");
    setContentModal(
      <AddEditAcopioForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        insumo={data}
        acopio={acopio}
        index={index}
        setAcopio={setAcopio}
        setMontoTotal={setMontoTotal}
        montoTotal={montoTotal}
        categorias={categorias}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UN INSUMO CREADO EN EL ACOPIO
  const onDeleteInsumo = (data, index) => {
    const result = window.confirm(
      `¿Eliminar insumo: ${data.categoria_data.nombre}?`
    );
    if (result) {
      setMontoTotal(
        montoTotal - acopio[index].precio_unitario * acopio[index].cantidad
      );

      setAcopio([
        ...acopio.slice(0, index),
        ...acopio.slice(index + 1, acopio.length),
      ]);
    }
  };

  // FUNCIÓN PARA REALIZAR EL ACOPIO
  const enviarAcopio = async () => {
    if (selectPresupuesto === "") {
      if (codigo === "") {
        setErrorCodigo(true);
      }
      setErrorPresupuesto(true);
    } else {
      if (codigo === "") {
        setErrorCodigo(true);
      } else {
        const result = window.confirm(`¿Enviar el acopio?`);
        if (result) {
          try {
            const enviar = {
              descripcion: descripcion,
              // descripcion.replace(/ /g, "") === "" ? "S/D" : descripcion,
              insumos: acopio,
              id_usuario: auth?.me.id,
              id_proveedor: value.value,
              presupuesto: selectPresupuesto,
              codigo_factura: codigo,
            };

            await addAcopio(enviar);
            setAcopio([]);
            setDescripcion("");
            setErrorCodigo(false);
            setErrorPresupuesto(false);
            setValue(null);
            setMontoTotal(0);
            setCodigo("");
            setSelectPresupuesto("");
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  };

  return (
    <form className="add-edit-acopio-form">
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Realizar acopio"
        subheader="En esta sección podrás realizar acopios de insumos desechables y no desechables."
        btnTitle="Añadir insumo"
        btnClick={addInsumo}
      />

      {/* ========= INSUMOS QUE SE VAN CARGANDO DE MANERA DINÁMICA ========= */}
      <TableAcopioAdmin
        insumos={acopio}
        updateInsumo={updateInsumo}
        onDeleteInsumo={onDeleteInsumo}
      />

      {/* ============= SECCIÓN PARA AÑADIR UNA DESCRIPCIÓN Y ENVIAR EL ACOPIO ============= */}
      {acopio.length > 0 && (
        <>
          <Card sx={{ marginTop: 2, p: 2 }}>
            <Grid
              alignItems={"center"}
              container
              direction="row"
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            >
              {/* ======================== SELECCIÓN DEL PROVEEDOR ======================== */}
              <Grid item xs>
                {proveedoresFormat.length > 0 ? (
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    options={proveedoresFormat}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Seleccione el proveedor"
                        size="small"
                        margin="dense"
                      />
                    )}
                  />
                ) : (
                  "No hay proveedores añadidos"
                )}
              </Grid>

              {/* ======================== SELECCIÓN DEL PRESUPUESTO ====================== */}
              <Grid item xs>
                <CssTextField
                  select
                  search="true"
                  label="Presupuesto"
                  value={selectPresupuesto}
                  onChange={handleChangePresupuesto}
                  error={errorPresupuesto}
                  helperText={errorPresupuesto && "Seleccione el presupuesto"}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                >
                  {presupuestos?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.anio}
                    </MenuItem>
                  ))}
                </CssTextField>
              </Grid>

              {/* ======================== INGRESO DEL CÓDIGO ====================== */}
              <Grid item xs>
                <CssTextField
                  label="Código de factura"
                  value={codigo}
                  onChange={handleChangeCodigo}
                  error={errorCodigo}
                  helperText={errorCodigo && "Ingrese el código de la factura"}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                />
              </Grid>

              {/* ============ VISUALIZACIÓN DEL MONTO TOTAL DEL ACOPIO ============ */}
              <Grid item xs>
                <CssTextField
                  label="Monto total del acopio"
                  value={`$ ${montoTotal}`}
                  disabled
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                />
              </Grid>
            </Grid>
          </Card>

          {/* ============= SECCIÓN PARA AÑADIR UNA DESCRIPCIÓN Y ENVIAR EL ACOPIO ============= */}
          <FootPage
            tfLabel="Descripción del acopio"
            tfValue={descripcion}
            tfOnChange={handleChange}
            btnTitle="Realizar acopio"
            btnClick={enviarAcopio}
          />
        </>
      )}

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size="small"
      />
    </form>
  );
}

// FUNCIÓN PARA SETEAR LOS DATOS DE USUARIOS
function formatProveedores(data) {
  return map(data, (item) => ({
    key: item.id,
    label: `${item.nombre}`,
    value: item.id,
  }));
}
