// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CssListItemText,
  CssTablePagination,
} from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import WysiwygIcon from "@mui/icons-material/Wysiwyg";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import { SeverityPill } from "../../severity-pill";
import "./TableSolDesSoli.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import moment from "moment";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE LAS SOLICITUDES DE DESEACOPIOS
const columns = [
  { id: "id", label: "ID", minWidth: 100, align: "center" },
  {
    id: "fecha_hora_solicitud",
    label: "Fecha de solicitud",
    minWidth: 180,
    align: "center",
  },
  { id: "descripcion", label: "Descripción", minWidth: 100 },
  { id: "estado", label: "Estado", minWidth: 100, align: "center" },
  {
    id: "fecha_hora_actualizacion",
    label: "Fecha de actualización del estado",
    minWidth: 280,
  },
  {
    id: "acciones",
    label: "Acciones",
    minWidth: 100,
    align: "center",
  },
];

export function TableSolDesSoli(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA SolicitudDesacopioSolicitante.js
  const { desacopios, infDesacopio } = props;

  // CREACIÓN DE LAS VARIABELS DE ESTADOS
  const [page, setPage] = React.useState(0); // PARA EL MANEJO DE LA PAGINACIÓN
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // CANTIDAD DE FILAS A MOSTRAR

  // FUNCIÓN PARA EL CAMBIO LA PÁGINA DE LA TABLA
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FUNCIÓN PARA CAMBIAR LA CANTIDAD DE FILAS A MOSTRAR
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {desacopios ? (
        // DETALLES DE LAS SOLICITUDES DE DESACOPIOS
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        // width: column.minWidth,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "500",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {desacopios
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((desacopio) => {
                    return (
                      <TableRow tabIndex={-1} key={desacopio.id}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {desacopio.id}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ py: FONTSIZE.paddingCell }}
                        >
                          <CssListItemText
                            primary={moment(desacopio.fecha_hora_solicitud)
                              .locale("es")
                              .format("DD/MM/YYYY")}
                            secondary={
                              "Hora: " +
                              moment(desacopio.fecha_hora_solicitud)
                                .locale("es")
                                .format("HH:mm")
                            }
                          />
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {desacopio.descripcion_solicitud.length > 140
                            ? desacopio.descripcion_solicitud.substr(0, 140) +
                              "..."
                            : desacopio.descripcion_solicitud}
                          {/* {desacopio.descripcion_solicitud} */}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          <SeverityPill
                            color={
                              desacopio.estado === "APROBADO"
                                ? "success"
                                : desacopio.estado === "PENDIENTE"
                                ? "warning"
                                : "error"
                            }
                          >
                            {desacopio.estado}
                          </SeverityPill>
                        </TableCell>

                        <TableCell sx={{ py: FONTSIZE.paddingCell }}>
                          {desacopio.fecha_hora_actualizacion ? (
                            <CssListItemText
                              primary={moment(
                                desacopio.fecha_hora_actualizacion
                              )
                                .locale("es")
                                .format("DD/MM/YYYY, HH:mm")}
                              secondary={
                                desacopio.estado === "DESAPROBADO"
                                  ? `Desaprobado por: ` +
                                    (desacopio.data_user_act?.last_name
                                      ? desacopio.data_user_act.last_name +
                                        ", " +
                                        desacopio.data_user_act.first_name
                                      : desacopio.data_user_act.email)
                                  : `Aprobado por: ` +
                                    (desacopio.data_user_act?.last_name
                                      ? desacopio.data_user_act.last_name +
                                        ", " +
                                        desacopio.data_user_act.first_name
                                      : desacopio.data_user_act.email)
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <Actions
                          desacopio={desacopio}
                          infDesacopio={infDesacopio}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* == CONTROL DE LA PAGINACIÓN ======================================== */}
          <CssTablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={desacopios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Solicitudes por página:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES DE VER LOS DETALLES DE UNA SOLICITUD DE DESACOPIO
function Actions(props) {
  const { desacopio, infDesacopio } = props;

  return (
    <TableCell align="center">
      <Tooltip title={"Ver detalles"}>
        <IconButton
          aria-label="delete"
          size={FONTSIZE.sizeIcon}
          onClick={() => infDesacopio(desacopio)}
          type="button"
        >
          <WysiwygIcon
            fontSize="inherit"
            color={
              desacopio.estado === "PENDIENTE"
                ? "warning"
                : desacopio.estado === "APROBADO"
                ? "success"
                : "error"
            }
          />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
