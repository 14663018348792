// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  HeaderPage,
  FootPage,
  AddEditPrestamoForm,
  TablePrestamoAdmin,
} from "../../components/Admin";

import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE HOOKS CREADOS
import { useCategoria, usePrestamo, useAuth } from "../../hooks";

export function PrestamosAdmin() {
  // DATOS Y/O FUNCIONES DE HOOKS
  const { categorias, getCategorias } = useCategoria();
  const { addPrestamo } = usePrestamo();
  const { auth } = useAuth();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [prestamo, setPrestamo] = useState([]); // PARA ALMACENAR LOS INSUMOS DEL PRÉSTAMO
  const [descripcion, setDescripcion] = useState(""); // PARA ALMACENAR LA DESCRIPCIÓN DEL PRÉSTAMO

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCategorias(false, "", "", "", "nombre"), [refetch]); // PARA OBTENER TODAS CATEGORÍAS DE INSUMOS DESECHABLES

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  const handleChange = (event) => {
    setDescripcion(event.target.value);
  }; // PARA ALMACENAR LOS VALORES DEL TEXT FIELD DE LA DESCRIPCIÓN

  // FUNCIÓN PARA AÑADIR UN NUEVO INSUMO AL PRÉSTAMO, SE ABRIRÁ EL MODAL
  const addInsumo = () => {
    setTitleModal("Añadir un nuevo insumo");
    setContentModal(
      <AddEditPrestamoForm
        onClose={openCloseModal}
        prestamo={prestamo}
        setPrestamo={setPrestamo}
        categorias={categorias}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ACTUALIZAR LOS VALORES DE UN INSUMO DEL PRÉSTAMO, SE ABRIRÁ EL MODAL
  const updateInsumo = (data, index) => {
    setTitleModal("Actualizar datos del insumo");
    setContentModal(
      <AddEditPrestamoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        insumo={data}
        prestamo={prestamo}
        index={index}
        setPrestamo={setPrestamo}
        categorias={categorias}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UN INSUMO CREADO EN EL PRÉSTAMO
  const onDeleteInsumo = (data, index) => {
    const result = window.confirm(`¿Eliminar insumo: ${data.nombre}?`);
    if (result) {
      setPrestamo([
        ...prestamo.slice(0, index),
        ...prestamo.slice(index + 1, prestamo.length),
      ]);
    }
  };

  // FUNCIÓN PARA ELIMINAR EL PRÉSTAMO
  const enviarPrestamo = async () => {
    const result = window.confirm(`¿Enviar la solicitud del préstamo?`);

    if (result) {
      try {
        const enviar = {
          estado: "PENDIENTE",
          descripcion_solicitud: descripcion,
          // descripcion.replace(/ /g, "") === "" ? "S/D" : descripcion,
          insumos: prestamo,
          id_usuario: auth?.me.id,
        };

        await addPrestamo(enviar, setPrestamo, setDescripcion);

        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <form className="add-edit-acopio-form">
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Solicitar préstamos"
        subheader="En esta sección podrás realizar las solicitudes de préstamos de insumos no desechables."
        btnTitle="Añadir insumo"
        btnClick={addInsumo}
      />

      {/* ========= INSUMOS QUE SE VAN CARGANDO DE MANERA DINÁMICA ========= */}
      <TablePrestamoAdmin
        insumos={prestamo}
        updateInsumo={updateInsumo}
        onDeleteInsumo={onDeleteInsumo}
      />

      {/* ============= SECCIÓN PARA AÑADIR UNA DESCRIPCIÓN Y ENVIAR EL ACOPIO ============= */}
      {prestamo.length > 0 && (
        <FootPage
          tfLabel="Descripción de la solicitud del préstamo"
          tfValue={descripcion}
          tfOnChange={handleChange}
          btnTitle="Enviar la solicitud de préstamo"
          btnClick={enviarPrestamo}
        />
      )}

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size="small"
      />
    </form>
  );
}
