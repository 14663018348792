// IMPORTACIÓN DE PÁGINAS
import {
  CategoriasAdmin,
  InsumosAdmin,
  AcopioAdmin,
  AcopiosRealizadosCompras,
  ProveedorAdmin,
} from "../pages/Admin";

import { Error404, Validacion } from "../pages";

// IMPORTACIÓN DE LAYOUTS
import { AdminLayout, BasicLayout } from "../layouts";

// LISTADO DE LAS URL PARA EL USUARIO DE COMERCIO
const routesComercios = [
  {
    path: "/",
    layout: AdminLayout,
    component: CategoriasAdmin,
    exact: true,
  },
  {
    path: "/proveedores",
    layout: AdminLayout,
    component: ProveedorAdmin,
    exact: true,
  },
  {
    path: "/categorias",
    layout: AdminLayout,
    component: CategoriasAdmin,
    exact: true,
  },
  {
    path: "/insumos",
    layout: AdminLayout,
    component: InsumosAdmin,
    exact: true,
  },
  {
    path: "/acopios",
    layout: AdminLayout,
    component: AcopioAdmin,
    exact: true,
  },
  {
    path: "/acopios_realizados",
    layout: AdminLayout,
    component: AcopiosRealizadosCompras,
    exact: true,
  },
  {
    path: "/validado",
    layout: BasicLayout,
    component: Validacion,
  },
  {
    path: "*",
    layout: BasicLayout,
    component: Error404,
  },
];

export default routesComercios;
