// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  Box,
  Grid,
  Card,
  Divider,
  Typography,
  IconButton,
  Tooltip,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CssTextField,
  CssListItemText,
  CssLoadingButton,
  CssButtonRegresar,
  CssButtonCancelar,
  CssTypography,
} from "../../../Common/componentesCssMui";

import { Loader } from "semantic-ui-react";

import { ModalBasic } from "../../../Common";

import { ModificarCantidadInsumo } from "./ModificarCantidadInsumo";

// IMPORTACIÓN DE ICONOS
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import CloseIcon from "@mui/icons-material/Close";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";
import EditIcon from "@mui/icons-material/Edit";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./AddEditSolDesForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import moment from "moment";

// IMPORTACIÓN DE HOOKS CREADOS
import { useDesacopio, useAuth, useTrabajador } from "../../../../hooks";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { useFormik } from "formik";
import * as Yup from "yup";

export function AddEditSolDesForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA SolicitudDesacopioAdmin.js
  const { desacopio, onRefetch, onClose } = props;

  // NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE INSUMOS QUE VAN A FORMAR PARTE DEL DESACOPIO
  const columns = [
    { id: "nro", label: "", minWidth: 50 },
    { id: "categoria", label: "Categoría" },
    {
      id: "cantidad_solicitada",
      label: "Cantidad solicitada",
      align: "center",
    },
    {
      id: "cantidad_entregada",
      label:
        desacopio.estado !== "PENDIENTE"
          ? "Cantidad entregada"
          : "Cantidad a entregar",
      align: "center",
    },
    { id: "accion", label: "", align: "center", minWidth: 50 },
  ];

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const {
    getCategoriasByDesacopio,
    categorias,
    loading,
    updateEstadoDesacopio,
  } = useDesacopio();

  const { auth } = useAuth();

  const {
    trabajadorSol,
    getTrabajadorSolicitante,
    trabajadorAct,
    getTrabajadorActualizador,
  } = useTrabajador();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch2, setRefetch2] = useState(false); // PARA REFRESCAR LA PÁGINA

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCategoriasByDesacopio(desacopio.id), [refetch2]); // PARA OBTENER LOS INSUMOS DE UN DESACOPIO ESPECIFICO
  useEffect(
    () => getTrabajadorSolicitante(desacopio.usuario_solicitud),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [desacopio]
  ); // DATOS DEL TRABAJADOR QUE SOLICITA EL PRÉSTAMO

  useEffect(
    () =>
      desacopio.usuario_actualizacion &&
      getTrabajadorActualizador(desacopio.usuario_actualizacion),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [desacopio]
  ); // DATOS DEL TRABAJADOR QUE ACTUALIZA EL ESTADO DEL PRÉSTAMO

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch2 = () => setRefetch2((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  // FUNCIÓN PARA MODIFICAR LA CANTIDAD DE INSUMOS A ENTREGAR
  const modificarCantidad = (data) => {
    setTitleModal("Modificar la cantidad solicitada del insumo");
    setContentModal(
      <ModificarCantidadInsumo
        onClose={openCloseModal}
        onRefetch={onRefetch2}
        insumo={data}
      />
    );
    openCloseModal();
  };

  // DEFINICIÓN DEL FORMIK Y YUP PARA LA VALIDACIÓN DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(desacopio),
    validationSchema: Yup.object(Schame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        formValue.id_usuario = auth?.me.id;

        // if (formValue.descripcion_actualizacion.replace(/ /g, "") === "")
        //   formValue.descripcion_actualizacion = "S/D";

        await updateEstadoDesacopio(desacopio.id, formValue, onClose);
        onRefetch();
        // onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form className="add-edit-soldes-form" onSubmit={formik.handleSubmit}>
      {/* ====================== SE VERIFICA SI YA SE OBTUVO TODOS LOS INSUMOS ====================== */}
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <>
          {categorias && trabajadorSol ? (
            <>
              {/* == DETALLES DEL DESACOPIO REALIZADO ====================================== */}
              <Card
                style={{
                  border: "1px solid",
                  borderColor: "#2C6975",
                  boxShadow: "none",
                }}
                sx={{ px: 1, py: 0.5 }}
              >
                <Typography sx={{ fontSize: FONTSIZE.tableCell, px: 1 }}>
                  <b>ID: {desacopio.id}</b>
                </Typography>

                <Divider sx={{ borderColor: "#2C6975" }}></Divider>

                <Grid item xs sx={{ px: 1 }}>
                  <CssListItemText
                    primary={
                      <>
                        <b>Fecha y hora de solicitud: </b>{" "}
                        {moment(desacopio.fecha_hora_solicitud)
                          .locale("es")
                          .format("DD/MM/YYYY, HH:mm")}{" "}
                      </>
                    }
                    secondary={
                      <>
                        <b>Descripción: </b>{" "}
                        {desacopio.descripcion_solicitud
                          ? desacopio.descripcion_solicitud
                          : "S/D"}
                      </>
                    }
                    sx={{ mb: 0 }}
                  />
                </Grid>

                <Divider sx={{ borderColor: "#2C6975", mt: 0.5 }}></Divider>

                <Grid
                  container
                  direction="row"
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  sx={{ px: 1 }}
                >
                  <Grid item xs>
                    <CssListItemText
                      primary={
                        <>
                          <b>Solicitante: </b>{" "}
                          {desacopio.data_user_sol?.last_name
                            ? desacopio.data_user_sol.last_name +
                              ", " +
                              desacopio.data_user_sol.first_name
                            : desacopio.data_user_sol.email}
                        </>
                      }
                      sx={{ mb: 0 }}
                    />
                  </Grid>

                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ borderColor: "#2C6975" }}
                    sx={{ mt: 0.5 }}
                  />

                  <Grid item xs>
                    <CssListItemText
                      primary={
                        <>
                          <b>Trabaja en el área de: </b>{" "}
                          {trabajadorSol?.length !== 0
                            ? trabajadorSol[0].area_data.nombre
                            : "No identificado"}
                        </>
                      }
                      sx={{ mb: 0 }}
                    />
                  </Grid>
                </Grid>
              </Card>

              {/* == DETALLES DE LOS INSUMOS DEL DESACOPIO ================================= */}
              <Divider sx={{ borderColor: "#2C6975", mt: 2, mb: 0.7 }} />

              <CssTypography align="center" sx={{ pb: 0.5 }}>
                Lista de insumos solicitados en el desacopio
              </CssTypography>

              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Paper sx={{ width: "100%", overflow: "hidden", mt: 0.1 }}>
                  <TableContainer sx={{ maxHeight: 300 }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      size="small"
                      className="table-acopio-admin"
                    >
                      {/* == ENCABEZADO DE LA TABLA ======================================== */}
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              colSpan={column.colSpan}
                              style={{
                                width: column.minWidth,
                                minWidth: column.minWidth,
                                background: "#2C6975",
                                fontSize: FONTSIZE.tableRow,
                                // fontWeight: "500",
                                color: "#FFFFFF",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      {/* == CONTENIDO DE LA TABLA ======================================== */}
                      <TableBody>
                        {categorias?.map((categoria, index) => {
                          return (
                            <TableRow tabIndex={-1} key={index}>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                {index + 1}
                              </TableCell>

                              <TableCell
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                {categoria.categoria_data.nombre}
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                {categoria.cantidad_solicitada}
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                {categoria.cantidad_entregada}
                              </TableCell>

                              <Actions
                                insumo={categoria}
                                updateInsumo={modificarCantidad}
                                desacopio={desacopio}
                              />
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                {desacopio.estado === "PENDIENTE" && (
                  <Paper
                    sx={{
                      width: "20%",
                      overflow: "hidden",
                      marginTop: 0.1,
                      marginLeft: 2,
                    }}
                  >
                    <TableContainer sx={{ maxHeight: 300 }}>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        size="small"
                        className="table-acopio-admin"
                      >
                        {/* == ENCABEZADO DE LA TABLA ======================================== */}
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align={"center"}
                              style={{
                                // width: column.minWidth,
                                // minWidth: column.minWidth,
                                background: "#2C6975",
                                fontSize: FONTSIZE.tableRow,
                                // fontWeight: "500",
                                color: "#FFFFFF",
                              }}
                            >
                              {"Stock disponible"}
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {/* == CONTENIDO DE LA TABLA ======================================== */}
                        <TableBody>
                          {categorias?.map((categoria, index) => {
                            return (
                              <TableRow tabIndex={-1} key={index}>
                                <TableCell
                                  align="center"
                                  style={{
                                    fontSize: FONTSIZE.tableCell,
                                  }}
                                >
                                  {categoria.categoria_data.stock_disponible}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                )}
              </Box>
            </>
          ) : (
            <></>
          )}

          {/* SI EL ESTADO DEL DESACOPIO ESTA EN PENDIENTE SE PROCEDE A ACTUALIZAR SU ESTADO ======== */}
          {desacopio.estado === "PENDIENTE" ? (
            <>
              <Card sx={{ marginTop: 2, p: 1.5 }}>
                <CssTextField
                  name="estado"
                  select
                  search="true"
                  label="Actualizar el estado del desacopio"
                  value={formik.values.estado}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.estado && formik.errors.estado)}
                  helperText={formik.touched.estado && formik.errors.estado}
                  onBlur={formik.handleBlur}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                >
                  <MenuItem value={"PENDIENTE"}>PENDIENTE</MenuItem>
                  <MenuItem value={"APROBADO"}>APROBADO</MenuItem>
                  <MenuItem value={"DESAPROBADO"}>DESAPROBADO</MenuItem>
                </CssTextField>

                {/* == SE HABILITA EL TEXT FIELD PARA AGREGAR UNA DESCRIPCIÓN ==================================== */}
                {formik.values.estado !== "PENDIENTE" ? (
                  <CssTextField
                    label={
                      formik.values.estado === "APROBADO"
                        ? "Descripción de la aprobación"
                        : "Descripción de la desaprobación"
                    }
                    name="descripcion_actualizacion"
                    value={formik.values.descripcion_actualizacion}
                    onChange={formik.handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={2}
                  />
                ) : (
                  <></>
                )}
              </Card>

              {formik.values.estado === "PENDIENTE" ? (
                // == BOTÓN PARA REGRESAR ====================================
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 2,
                  }}
                >
                  <CssButtonRegresar
                    startIcon={<ArrowBackSharpIcon fontSize="large" />}
                    onClick={onClose}
                  >
                    Regresar
                  </CssButtonRegresar>
                </Box>
              ) : (
                // =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
                <Grid
                  container
                  direction="row"
                  justifyContent="right"
                  alignItems="center"
                  columnSpacing={{ md: 1.5 }}
                  sx={{ mt: 2 }}
                >
                  <Grid item>
                    <CssButtonCancelar
                      startIcon={<CloseIcon fontSize="large" />}
                      onClick={onClose}
                    >
                      Cancelar
                    </CssButtonCancelar>
                  </Grid>

                  <Grid item>
                    <CssLoadingButton
                      endIcon={<ReplaySharpIcon fontSize="large" />}
                      variant="contained"
                      loading={loading}
                      type="submit"
                    >
                      Actualizar estado
                    </CssLoadingButton>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              {/* == SI EL DESACOPIO ESTA APROBADO O DESAPROBADO SE MUESTRA LOS DETALLES */}
              {trabajadorAct && (
                <Card
                  style={{
                    border: "1px solid",
                    borderColor: "#2C6975",
                    boxShadow: "none",
                  }}
                  sx={{ px: 1, py: 0.5, mt: 2 }}
                >
                  <Typography
                    sx={
                      desacopio.estado === "DESAPROBADO"
                        ? { fontSize: FONTSIZE.tableCell, color: "red", px: 1 }
                        : {
                            fontSize: FONTSIZE.tableCell,
                            color: "green",
                            px: 1,
                          }
                    }
                  >
                    <b>Estado actual: {desacopio.estado}</b>
                  </Typography>

                  <Divider sx={{ borderColor: "#2C6975" }}></Divider>

                  <Grid item xs sx={{ px: 1 }}>
                    <CssListItemText
                      primary={
                        <>
                          <b>Fecha y hora de actualización: </b>{" "}
                          {moment(desacopio.fecha_hora_actualizacion)
                            .locale("es")
                            .format("DD/MM/YYYY, HH:mm")}{" "}
                        </>
                      }
                      secondary={
                        <>
                          <b>Descripción: </b>{" "}
                          {desacopio.descripcion_actualizacion
                            ? desacopio.descripcion_actualizacion
                            : "S/D"}
                        </>
                      }
                      sx={{ mb: 0 }}
                    />
                  </Grid>

                  <Divider sx={{ borderColor: "#2C6975", mt: 0.5 }}></Divider>

                  <Grid
                    container
                    direction="row"
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    sx={{ px: 1 }}
                  >
                    <Grid item xs>
                      <CssListItemText
                        primary={
                          <>
                            <b>
                              {desacopio.estado === "DESAPROBADO"
                                ? "Desaprobado por: "
                                : "Aprobado por: "}
                            </b>
                            {desacopio.data_user_act?.last_name
                              ? desacopio.data_user_act.last_name +
                                ", " +
                                desacopio.data_user_act.first_name
                              : desacopio.data_user_act.email}
                          </>
                        }
                        sx={{ mb: 0 }}
                      />
                    </Grid>

                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ borderColor: "#2C6975" }}
                      sx={{ mt: 0.5 }}
                    />

                    <Grid item xs>
                      <CssListItemText
                        primary={
                          <>
                            <b>Trabaja en el área de: </b>{" "}
                            {trabajadorAct?.length !== 0
                              ? trabajadorAct[0].area_data.nombre
                              : "No identificado"}
                          </>
                        }
                        sx={{ mb: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              )}

              {/* == BOTÓN PARA REGRESAR ==================================== */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <CssButtonRegresar
                  startIcon={<ArrowBackSharpIcon fontSize="large" />}
                  onClick={onClose}
                >
                  Regresar
                </CssButtonRegresar>
              </Box>
            </>
          )}
        </>
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size={"tiny"}
      />
    </form>
  );
}

// INICIALIZACIÓN DE LOS VALORES INICIALES DEL FORMULARIO DE INSUMOS
function initialValues(data) {
  return {
    estado: data?.estado || "",
    fecha_solicitud: data?.fecha_solicitud || "",
    descripcion: data?.descripcion || "",
    descripcion_actualizacion: data?.descripcion_actualizacion || "",
    id_usuario: 0,
  };
}

// VALIDACIÓN DE LOS CAMPOS DEL FORMULARIO DE INSUMOS
function Schame() {
  return {
    estado: Yup.string().required(
      "Es necesario que ingrese el estado del desacopio"
    ),
  };
}

// ACCIONES DE ACTUALIZAR LA CANTIDAD DEL INSUMO
function Actions(props) {
  const { insumo, updateInsumo, desacopio } = props;

  return (
    <TableCell align="center" sx={{ py: 0 }}>
      {desacopio?.estado === "PENDIENTE" && (
        // == BOTÓN PARA MODIFICAR DATOS DE UN INSUMO ===================
        <Tooltip title="Modificar">
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => updateInsumo(insumo)}
            type="button"
            sx={{ p: 0 }}
          >
            <EditIcon color="info" />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
}
