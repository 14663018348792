// IMPORTACIÓN DE REACT
import React, { useState } from "react";

// IMPORTACIÓN DE COMPONENTES
import { Grid } from "@mui/material";
import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { useFormik } from "formik";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { usePresupuesto, useAuth } from "../../../../hooks";

export function ModificarPresupuestoForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA PresupuestosAdmin.js
  const { onClose, onRefetch, presupuesto, tipo } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { addModificacionPresupuestaria } = usePresupuesto();
  const { auth } = useAuth();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(false);

  // VERIFICACIÓN Y VALIDACIÓN DE LOS DATOS DEL FORMULARIO, MEDIANTE EL USO DE FORMIK Y YUP
  const formik = useFormik({
    initialValues: initialValues(tipo),
    validationSchema: Yup.object(Schame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        setLoading(true);

        formValue.usuario = auth?.me.id;
        formValue.presupuesto = presupuesto.id;

        await addModificacionPresupuestaria(formValue);

        onRefetch();
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form className="add-edit-presupuesto-form" onSubmit={formik.handleSubmit}>
      {/* =================== MONTO DE LA MODIFICACIÓN PRESUPUESTARIA ===================*/}
      <CssTextField
        label={
          tipo === "AMPLIACION"
            ? "Monto de la apliación"
            : "Monto de la reducción"
        }
        name="monto"
        inputProps={{
          type: "number",
        }}
        value={formik.values.monto}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.monto && formik.errors.monto)}
        helperText={formik.touched.monto && formik.errors.monto}
        onBlur={formik.handleBlur}
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      />

      {/* =================== DESCRIPCIÓN DE LA MODIFICACIÓN PRESUPUESTARIA  ===================*/}
      <CssTextField
        label="Descripción"
        name="descripcion"
        value={formik.values.descripcion}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.descripcion && formik.errors.descripcion)}
        helperText={formik.touched.descripcion && formik.errors.descripcion}
        onBlur={formik.handleBlur}
        // placeholder="Descripcion"
        fullWidth
        variant="outlined"
        margin="dense"
        multiline
        rows={7}
      />
      <hr />

      {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
      <Grid
        container
        direction="row"
        justifyContent="right"
        alignItems="center"
        columnSpacing={{ md: 1.5 }}
      >
        <Grid item>
          <CssButtonCancelar
            startIcon={<CloseIcon fontSize="large" />}
            onClick={onClose}
          >
            Cancelar
          </CssButtonCancelar>
        </Grid>

        <Grid item>
          <CssLoadingButton
            endIcon={<AddTaskOutlinedIcon fontSize="large" />}
            variant="contained"
            loading={loading}
            type="submit"
          >
            Modificar
          </CssLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

// INICIALIZACIÓN DE LOS VALORES DEL FORMULARIO
function initialValues(tipo) {
  return {
    tipo: tipo,
    monto: "",
    descripcion: "",
    presupuesto: 0,
    usuario: 0,
  };
}

// VALIDACIÓN DEL FORMULARIO PARA LA CREACIÓN O MODIFICACIÓN DE LOS DATOS DEL ÁREA
function Schame() {
  return {
    monto: Yup.number()
      .typeError("Ingrese un número de teléfono valido")
      .required("Ingrese el monto"),
    descripcion: Yup.string().required("Ingrese la descripción"),
  };
}
