// IMPORTACIÓN DE PÁGINAS
import {
  DesacopioAdmin,
  PrestamosAdmin,
  SolicitudPrestamoSolicitante,
  SolicitudDesacopioSolicitante,
} from "../pages/Admin";

import { Error404, Validacion } from "../pages";

// IMPORTACIÓN DE LAYOUTS
import { AdminLayout, BasicLayout } from "../layouts";

// LISTADO DE LAS URL PARA EL USUARIO SOLICITANTE
const routesSolicitante = [
  {
    path: "/",
    layout: AdminLayout,
    component: DesacopioAdmin,
    exact: true,
  },
  {
    path: "/desacopios",
    layout: AdminLayout,
    component: DesacopioAdmin,
    exact: true,
  },
  {
    path: "/solicitud_desacopios",
    layout: AdminLayout,
    component: SolicitudDesacopioSolicitante,
    exact: true,
  },
  {
    path: "/prestamos",
    layout: AdminLayout,
    component: PrestamosAdmin,
    exact: true,
  },
  {
    path: "/solicitud_prestamos",
    layout: AdminLayout,
    component: SolicitudPrestamoSolicitante,
    exact: true,
  },
  {
    path: "/validado",
    layout: BasicLayout,
    component: Validacion,
  },
  {
    path: "*",
    layout: BasicLayout,
    component: Error404,
  },
];

export default routesSolicitante;
