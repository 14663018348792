// IMPORTACIÓN DE REACT
import React, { useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  Box,
  Card,
  Grid,
  Divider,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CssListItemText,
  CssButtonRegresar,
  CssTypography,
} from "../../../Common/componentesCssMui";

import { Loader, Icon } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./AcopiosRealizadosForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import moment from "moment";

// IMPORTACIÓN DE HOOKS CREADOS
import { useAcopio, useTrabajador } from "../../../../hooks";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA
const columns = [
  { id: "nro", label: "", minWidth: 50 },
  { id: "categoria", label: "Categoría" },
  { id: "desechable", label: "Desechable", align: "center" },
  { id: "cantidad", label: "Cantidad", align: "center" },
  { id: "codigo", label: "Código", align: "center" },
  { id: "marca", label: "Marca", align: "center" },
  { id: "modelo", label: "Modelo", align: "center" },
  { id: "precio", label: "Precio unitario", align: "center" },
  { id: "subtotal", label: "Subtotal", align: "center" },
];

export function AcopiosRealizadosForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA AcopiosRealizadosAdmin.js
  const { acopio, onClose } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { getInsumosByAcopio, insumos } = useAcopio();
  const { trabajadorSol, getTrabajadorSolicitante } = useTrabajador();

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getInsumosByAcopio(acopio.id), []); // PARA OBTENER LOS INSUMOS DE UN ACOPIO ESPECÍFICO
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getTrabajadorSolicitante(acopio.usuario), [acopio]); // PARA OBTENER LOS DATOS DEL TRABAJADOR QUE REALIZÓ EL ACOPIO

  // FUNCIÓN PARA CALCULAR LA CANTIDAD TOTAL DE INSUMOS QUE TIENE EL ACOPIO
  function totalInsumo(insumos) {
    let cantidad = 0;
    for (let i = 0; i < insumos.length; i++) {
      cantidad = cantidad + insumos[i].cantidad;
    }
    return cantidad;
  }

  return (
    <form className="add-edit-soldes-form">
      {/* ====================== SE VERIFICA SI SE OBTUVO TODOS LOS INSUMOS Y DATOS DEL TRABAJADOR ====================== */}
      {insumos && trabajadorSol ? (
        <>
          {/* == DETALLES DEL ACOPIO REALIZADO ========================================= */}
          <Card
            style={{
              border: "1px solid",
              borderColor: "#2C6975",
              boxShadow: "none",
            }}
            sx={{ px: 1, py: 0.5 }}
          >
            <Typography sx={{ fontSize: FONTSIZE.tableCell, px: 1 }}>
              <b>ID: {acopio.id}</b>
            </Typography>

            <Divider sx={{ borderColor: "#2C6975" }}></Divider>

            <Grid item xs sx={{ px: 1 }}>
              <CssListItemText
                primary={
                  <>
                    <b>Fecha y hora de realización: </b>{" "}
                    {moment(acopio.fecha_hora_realizacion)
                      .locale("es")
                      .format("DD/MM/YYYY, HH:mm")}{" "}
                  </>
                }
                secondary={
                  <>
                    <b>Descripción: </b>{" "}
                    {acopio.descripcion ? acopio.descripcion : "S/D"}
                  </>
                }
                sx={{ mb: 0 }}
              />
            </Grid>

            <Divider sx={{ borderColor: "#2C6975", mt: 0.5 }}></Divider>

            <Grid
              container
              direction="row"
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              sx={{ px: 1 }}
            >
              <Grid item xs>
                <CssListItemText
                  primary={
                    <>
                      <b>Lo realizó: </b>{" "}
                      {acopio.data_usuario.last_name
                        ? acopio.data_usuario.last_name +
                          ", " +
                          acopio.data_usuario.first_name
                        : acopio.data_usuario.email}
                    </>
                  }
                  sx={{ mb: 0 }}
                />
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                style={{ borderColor: "#2C6975" }}
                sx={{ mt: 0.5 }}
              />

              <Grid item xs>
                <CssListItemText
                  primary={
                    <>
                      <b>Trabaja en el área de: </b>{" "}
                      {trabajadorSol?.length !== 0
                        ? trabajadorSol[0].area_data.nombre
                        : "No identificado"}
                    </>
                  }
                  sx={{ mb: 0 }}
                />
              </Grid>
            </Grid>
          </Card>

          {/* == DETALLES DE LOS INSUMOS DEL ACOPIO ==================================== */}
          <Divider sx={{ borderColor: "#2C6975", mt: 2, mb: 0.7 }} />

          <CssTypography align="center" sx={{ pb: 0.5 }}>
            Lista de insumos del acopio
          </CssTypography>

          {/* == TABLA DE INSUMOS  ==================================================== */}
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              // mt: 0.1,
            }}
          >
            <TableContainer sx={{ maxHeight: 300 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                size="small"
                className="table-acopio-admin"
              >
                {/* == ENCABEZADO DE LA TABLA ======================================== */}
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        colSpan={column.colSpan}
                        style={{
                          width: column.minWidth,
                          minWidth: column.minWidth,
                          background: "#2C6975",
                          fontSize: FONTSIZE.tableRow,
                          // fontWeight: "500",
                          color: "#FFFFFF",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {/* == CONTENIDO DE LA TABLA ======================================== */}
                <TableBody>
                  {insumos?.map((insumo, index) => {
                    return (
                      <TableRow tabIndex={-1} key={index}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {insumo.categoria
                            ? insumo.categoria_data.nombre
                            : insumo.insumos_data.categoria_data.nombre}
                        </TableCell>

                        <TableCell
                          className={"status"}
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {insumo.categoria ? (
                            <Icon name="check" />
                          ) : (
                            <Icon name="close" />
                          )}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {insumo.cantidad}
                        </TableCell>

                        {insumo.categoria ? (
                          <>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {insumo.categoria
                                ? "-"
                                : insumo.insumos_data.codigo}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {insumo.categoria
                                ? "-"
                                : insumo.insumos_data.marca}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {insumo.categoria
                                ? "-"
                                : insumo.insumos_data.modelo}
                            </TableCell>
                          </>
                        )}

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          $ {insumo.precio_unitario}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          $ {insumo.subtotal}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {/* == DETALLES DEL PRESUPUESTO ========================================= */}
          <Card
            style={{
              border: "1px solid",
              borderColor: "#2C6975",
              boxShadow: "none",
            }}
            sx={{ px: 1, py: 0.5, mt: 2 }}
          >
            <Typography sx={{ fontSize: FONTSIZE.tableCell, px: 1 }}>
              <b>DETALLES DE LA FACTURACIÓN</b>
            </Typography>

            <Divider sx={{ borderColor: "#2C6975" }}></Divider>

            <Grid
              container
              direction="row"
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              sx={{ px: 1 }}
            >
              <Grid item xs>
                <CssListItemText
                  primary={
                    <>
                      <b>Número de factura: </b> {acopio.codigo_factura}
                    </>
                  }
                  secondary={
                    <>
                      <b>Año del presupuesto: </b>{" "}
                      {acopio.data_presupuesto?.anio}
                    </>
                  }
                  sx={{ mb: 0 }}
                />
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                style={{ borderColor: "#2C6975" }}
                sx={{ mt: 0.5 }}
              />

              <Grid item xs>
                <CssListItemText
                  primary={
                    <>
                      <b>Monto total del acopio: </b> {"$ "}
                      {acopio.total_compra}
                    </>
                  }
                  secondary={
                    <>
                      <b>Cantitad total de insumos: </b>
                      {totalInsumo(insumos)}
                    </>
                  }
                  sx={{ mb: 0 }}
                />
              </Grid>
            </Grid>
          </Card>

          {/* == DETALLES DEL PROVEEDOR ========================================= */}
          <Card
            style={{
              border: "1px solid",
              borderColor: "#2C6975",
              boxShadow: "none",
            }}
            sx={{ px: 1, py: 0.5, mt: 2 }}
          >
            <Typography sx={{ fontSize: FONTSIZE.tableCell, px: 1 }}>
              <b>DETALLES DEL PROVEEDOR</b>
            </Typography>

            <Divider sx={{ borderColor: "#2C6975" }}></Divider>

            <Grid
              container
              direction="row"
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              sx={{ px: 1 }}
            >
              <Grid item xs>
                <CssListItemText
                  primary={
                    <>
                      <b>Nombre: </b>{" "}
                      {acopio.data_proveedor?.nombre
                        ? acopio.data_proveedor?.nombre
                        : "-"}
                    </>
                  }
                  secondary={
                    <>
                      <b>Dirección: </b>{" "}
                      {acopio.data_proveedor?.direccion
                        ? acopio.data_proveedor?.direccion
                        : "-"}
                    </>
                  }
                  sx={{ mb: 0 }}
                />
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                style={{ borderColor: "#2C6975" }}
                sx={{ mt: 0.5 }}
              />

              <Grid item xs>
                <CssListItemText
                  primary={
                    <>
                      <b>Teléfono: </b>{" "}
                      {acopio.data_proveedor?.telefono
                        ? acopio.data_proveedor?.telefono
                        : "-"}
                    </>
                  }
                  secondary={
                    <>
                      <b>Correo electrónico: </b>{" "}
                      {acopio.data_proveedor?.email
                        ? acopio.data_proveedor?.email
                        : "-"}
                    </>
                  }
                  sx={{ mb: 0 }}
                />
              </Grid>
            </Grid>
          </Card>

          {/* == BOTÓN PARA REGRESAR ==================================== */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 2,
            }}
          >
            <CssButtonRegresar
              startIcon={<ArrowBackSharpIcon fontSize="large" />}
              onClick={onClose}
            >
              Regresar
            </CssButtonRegresar>
          </Box>
        </>
      ) : (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      )}
    </form>
  );
}
