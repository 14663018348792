// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER LOS PRESTAMOS, SE PUEDE FILTRAR LA OBTENCIÓN DE LOS REGISTROS
export async function getPrestamosApi(
  id = "",
  estado = "",
  usuario = "",
  ordering = "",
  token
) {
  try {
    const idFilter = `id=${id}`;
    const estadoFilter = `estado=${estado}`;
    const usuarioFilter = `usuario_solicitud=${usuario}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/prestamos/?${idFilter}&${estadoFilter}&${usuarioFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AÑADIR UNA NUEVA SOLICITUD DE PRÉSTAMO
export async function addPrestamoApi(data, setPrestamo, setDescripcion, token) {
  try {
    const url = `${BASE_API}/api/prestamos/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    } else {
      setPrestamo([]);
      setDescripcion("");
      toast.success(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR EL ESTADO DE LA SOLICITUD DE UN PRÉSTAMO
export async function updateEstadoPrestamoApi(
  id,
  data,
  onClose,
  insumosAPrestar,
  token
) {
  try {
    const url = `${BASE_API}/api/prestamos/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
      });
    } else {
      if (data.estado === "APROBADO") {
        insumosAPrestar();
      } else {
        onClose();
      }
      toast.success(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER LAS CATEGORÍAS ASOCIADOS A UN PRÉSTAMO
export async function getCategoriasByPrestamoApi(id_prestamo, token) {
  try {
    const prestamoFilter = `prestamo=${id_prestamo}`;
    const url = `${BASE_API}/api/prestamoCategoriaTea/?${prestamoFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA OBTENER LAS INSUMOS ASOCIADOS A UN PRÉSTAMO
export async function getInsumosByPrestamoApi(id_prestamo, token) {
  try {
    const prestamoFilter = `prestamo=${id_prestamo}`;
    const url = `${BASE_API}/api/prestamoInsumoTea/?${prestamoFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LA CANTIDAD DE UN INSUMO A PRESTAR
export async function updateCantidadInsumoApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/prestamoCategoriaTea/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error("Hubo un problema en actualizar la nueva cantidad", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      toast.success("Se actualizó la nueva cantidad", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
    return result;
  } catch (error) {
    throw error;
  }
}
