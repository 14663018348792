import { TOKEN } from "../utils/constants";

// SE ALMACENA EL TOKEN EN LA LOCAL STORAGE
export function setToken(token) {
  localStorage.setItem(TOKEN, token);
}

// SE OBTIENE EL TOKEN ALMACENADO EN LA LOCAL STORAGE
export function getToken() {
  return localStorage.getItem(TOKEN);
}

// SE ELIMINA EL TOKEN DE LA LOCAL STORAGE
export function removeToken() {
  localStorage.removeItem(TOKEN);
}
