// IMPORTACIÓN DE COMPONENTES
import {
  Button,
  FormControl,
  TextField,
  ListItemText,
  TablePagination,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../utils/constants";
import { styled } from "@mui/material/styles";

export const CssTypography = styled(Typography)({
  // marginTop: 16,
  // background: "#2C6975",
  // color: "#FFFFFF",
  fontSize: FONTSIZE.tableRow,
  fontWeight: "bold",
});

export const CssFormControl = styled(FormControl)({
  "& label.Mui-focused": {
    color: "#2C6975",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2C6975",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#2C6975",
    },
  },
});

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#2C6975",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2C6975",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#2C6975",
    },
  },
});

export const CssLoadingButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  lineHeight: 1.75,
  background: "#2C6975",
  "&:hover": {
    background: "#468693cf",
    boxShadow: "none",
  },
});

export const CssButtonPrimary = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  backgroundColor: "#2C6975",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#468693cf",
    boxShadow: "none",
  },
});

export const CssButtonCancelar = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  border: "2px solid",
  lineHeight: 1.5,
  color: "#ff0000",
  "&:hover": {
    backgroundColor: "#ff0000",
    color: "#FFFFFF",
    borderColor: "#ff0000",
    boxShadow: "none",
  },
});

export const CssButtonRegresar = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  border: "2px solid",
  lineHeight: 1.5,
  color: "#505050",
  "&:hover": {
    backgroundColor: "#999999",
    color: "#FFFFFF",
    borderColor: "#999999",
    boxShadow: "none",
  },
});

export const CssButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  fontWeight: "bold",
  // padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#ffffff",
  borderColor: "#ffffff",
  color: "#2E7D32",
  "&:hover": {
    backgroundColor: "#1f9b0023",
    boxShadow: "none",
  },
});

export const CssListItemText = styled(ListItemText)({
  "& .MuiListItemText-primary": {
    fontSize: FONTSIZE.listItem,
  },
  "& .MuiListItemText-secondary": {
    fontSize: FONTSIZE.listItem,
  },
});

export const CssTablePagination = styled(TablePagination)({
  "& .MuiTablePagination-selectLabel": {
    margin: 0,
    fontSize: 13,
  },
  "& .MuiTablePagination-displayedRows": {
    margin: 0,
    fontSize: 13,
  },

  "& .MuiInputBase-input": {
    fontSize: 13,
  },
});
