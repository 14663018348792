// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getAcopiosApi,
  addAcopioApi,
  getInsumosByAcopioApi,
  getAcopiosByPresupuestoApi,
} from "../api/acopio";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from ".";

export function useAcopio() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [acopios, setAcopios] = useState(null); // PARA ALMACENAR LOS ACOPIOS
  const [insumos, setInsumos] = useState(null); // PARA ALMACENAR LOS INSUMOS DE UN ACOPIO

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE LOS ACOPIOS
  const getAcopios = async (id, usuario, id_presupuesto, ordering) => {
    try {
      setLoading(true);
      const response = await getAcopiosApi(
        id,
        usuario,
        id_presupuesto,
        ordering,
        auth.token
      );
      setLoading(false);
      setAcopios(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UN NUEVO ACOPIO
  const addAcopio = async (data) => {
    try {
      setLoading(true);
      await addAcopioApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE INSUMOS DE UN ACOPIO
  const getInsumosByAcopio = async (acopio, ordering) => {
    try {
      setLoading(true);
      const response = await getInsumosByAcopioApi(
        acopio,
        ordering,
        auth.token
      );
      setLoading(false);
      setInsumos(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE LOS ACOPIOS DE UN PRESUPUESTO
  const getAcopiosByPresupuesto = async (id_presupuesto, ordering) => {
    try {
      setLoading(true);
      const response = await getAcopiosByPresupuestoApi(
        id_presupuesto,
        ordering,
        auth.token
      );
      setLoading(false);
      setAcopios(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getAcopios,
    acopios,
    addAcopio,
    getInsumosByAcopio,
    insumos,
    getAcopiosByPresupuesto,
    loading,
    error,
  };
}
