// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getPrestamosApi,
  getCategoriasByPrestamoApi,
  updateEstadoPrestamoApi,
  addPrestamoApi,
  getInsumosByPrestamoApi,
  updateCantidadInsumoApi,
} from "../api/prestamo";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from ".";

export function usePrestamo() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [loading2, setLoading2] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [prestamos, setPrestamos] = useState(null); // PARA ALMACENAR PRÉSTAMOS
  const [categorias, setCategorias] = useState(null); // PARA ALMACENAR CATEGORÍAS
  const [insumos, setInsumos] = useState(null); // PARA ALMACENAR INSUMOS

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE PRÉSTAMOS
  const getPrestamos = async (id, estado, usuario, ordering) => {
    try {
      setLoading(true);
      const response = await getPrestamosApi(
        id,
        estado,
        usuario,
        ordering,
        auth.token
      );
      setLoading(false);
      setPrestamos(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UNA NUEVA SOLICITUD DE PRÉSTAMO
  const addPrestamo = async (data, setPrestamo, setDescripcion) => {
    try {
      setLoading(true);
      await addPrestamoApi(data, setPrestamo, setDescripcion, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DEL ESTADO DE LA SOLICITUD DE UN PRÉSTAMO
  const updateEstadoPrestamo = async (id, data, onClose, insumosAPrestar) => {
    try {
      setLoading(true);
      await updateEstadoPrestamoApi(
        id,
        data,
        onClose,
        insumosAPrestar,
        auth.token
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE CATEGORÍAS DE UN PRÉSTAMO
  const getCategoriasByPrestamo = async (desechable) => {
    try {
      setLoading(true);
      const response = await getCategoriasByPrestamoApi(desechable, auth.token);
      setLoading(false);
      setCategorias(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE INSUMOS DE UN PRÉSTAMO
  const getInsumosByPrestamo = async (idPrestamo) => {
    try {
      setLoading2(true);
      const response = await getInsumosByPrestamoApi(idPrestamo, auth.token);
      setLoading2(false);
      setInsumos(response);
    } catch (error) {
      setLoading2(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DE LA CANTIDAD DE UN INSUMO EN UN PRÉSTAMO
  const updateCantidadInsumo = async (id, data) => {
    try {
      setLoading(true);
      await updateCantidadInsumoApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getPrestamos,
    prestamos,
    addPrestamo,
    updateEstadoPrestamo,
    getCategoriasByPrestamo,
    categorias,
    getInsumosByPrestamo,
    insumos,
    updateCantidadInsumo,
    loading,
    loading2,
    error,
  };
}
