// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  HeaderPage,
  FootPage,
  AddEditDesacopioForm,
  TableDesacopioAdmin,
} from "../../components/Admin";
import { ModalBasic } from "../../components/Common";

// IMPORTACIÓN DE HOOKS CREADOS
import { useCategoria, useDesacopio, useAuth } from "../../hooks";

export function DesacopioAdmin() {
  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { categorias, getCategorias } = useCategoria();
  const { addDesacopio } = useDesacopio();
  const { auth } = useAuth();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [showModal, setShowModal] = useState(false); // PARA LA APERTURA Y CIERRE DEL MODAL
  const [titleModal, setTitleModal] = useState(null); // PARA EL TÍTULO DEL MODAL
  const [contentModal, setContentModal] = useState(null); // PARA EL CONTENIDO DEL MODAL
  const [refetch, setRefetch] = useState(false); // PARA REFRESCAR LA PÁGINA

  const [desacopio, setDesacopio] = useState([]); // PARA ALMACENAR LOS INSUMOS DEL DESACOPIO
  const [descripcion, setDescripcion] = useState(""); // PARA ALMACENAR LA DESCRIPCIÓN DEL DESACOPIO

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCategorias(true, "", "", "", "nombre"), [refetch]); // PARA OBTENER TODAS CATEGORÍAS DE INSUMOS DESECHABLES

  // CREACIÓN DE FUNCIONES ADICIONALES
  const openCloseModal = () => setShowModal((prev) => !prev); // PARA LA APERTURA Y CIERRE DEL MODAL
  const onRefetch = () => setRefetch((prev) => !prev); // PARA REFRESCAR LA PÁGINA

  const handleChange = (event) => {
    setDescripcion(event.target.value);
  }; // PARA ALMACENAR LOS VALORES DEL TEXT FIELD DE LA DESCRIPCIÓN

  // FUNCIÓN PARA AÑADIR UN NUEVO INSUMO AL DESACOPIO, SE ABRIRÁ EL MODAL
  const addInsumo = () => {
    setTitleModal("Añadir un nuevo insumo");
    setContentModal(
      <AddEditDesacopioForm
        onClose={openCloseModal}
        desacopio={desacopio}
        setDesacopio={setDesacopio}
        categorias={categorias}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ACTUALIZAR LOS VALORES DE UN INSUMO DEL DESACOPIO, SE ABRIRÁ EL MODAL
  const updateInsumo = (data, index) => {
    setTitleModal("Actualizar datos del insumo");
    setContentModal(
      <AddEditDesacopioForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        insumo={data}
        desacopio={desacopio}
        index={index}
        setDesacopio={setDesacopio}
        categorias={categorias}
      />
    );
    openCloseModal();
  };

  // FUNCIÓN PARA ELIMINAR UN INSUMO CREADO EN EL DESACOPIO
  const onDeleteInsumo = (data, index) => {
    const result = window.confirm(`¿Eliminar insumo: ${data.nombre}?`);
    if (result) {
      setDesacopio([
        ...desacopio.slice(0, index),
        ...desacopio.slice(index + 1, desacopio.length),
      ]);
    }
  };

  // FUNCIÓN PARA ENVIAR LA SOLICITUD DE DESACOPIO
  const enviarDesacopio = async () => {
    const result = window.confirm(`¿Enviar el desacopio?`);
    if (result) {
      try {
        const enviar = {
          estado: "PENDIENTE",
          descripcion_solicitud: descripcion,
          // descripcion.replace(/ /g, "") === "" ? "S/D" : descripcion,
          insumos: desacopio,
          id_usuario: auth?.me.id,
        };

        await addDesacopio(enviar, setDesacopio, setDescripcion);

        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <form className="add-edit-desacopio-form">
      {/* ============= ENCABEZADO DE LA PÁGINA ============= */}
      <HeaderPage
        title="Solicitar desacopio"
        subheader="En esta sección se realizan las solicitudes de desacopio de insumos desechables."
        btnTitle="Añadir insumo"
        btnClick={addInsumo}
      />

      {/* ========= INSUMOS QUE SE VAN CARGANDO DE MANERA DINÁMICA ========= */}
      <TableDesacopioAdmin
        insumos={desacopio}
        updateInsumo={updateInsumo}
        onDeleteInsumo={onDeleteInsumo}
      />

      {/* ============= SECCIÓN PARA AÑADIR UNA DESCRIPCIÓN Y ENVIAR EL DESACOPIO ============= */}
      {desacopio.length > 0 && (
        <FootPage
          tfLabel="Descripción del desacopio"
          tfValue={descripcion}
          tfOnChange={handleChange}
          btnTitle="Enviar la solicitud de desacopio"
          btnClick={enviarDesacopio}
        />
      )}

      {/* ================= CARGA DEL MODAL ================ */}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size="small"
      />
    </form>
  );
}
