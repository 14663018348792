// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import { Grid } from "@mui/material";
import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

import { Checkbox } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

// IMPORTACIÓN DE ESTILOS
import "./AddEditCategoriaAdmin.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { useFormik } from "formik";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { useCategoria } from "../../../../hooks";

export function AddEditCategoriaForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA CategoriasAdmin.js
  const { onClose, onRefetch, categoria } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { addCategoria, updateCategoria } = useCategoria();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = React.useState(false);
  const [valor, setValor] = React.useState(
    categoria ? categoria.stock_minimo : 0
  );

  // DEFINICIÓN DEL FORMIK Y YUP PARA LA VALIDACIÓN DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(categoria),
    validationSchema: Yup.object(Schame(valor)),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        setLoading(true);

        // CONVERSIÓN DATOS IMPORTANTES A MAYUSCULA
        formValue.nombre = formValue.nombre.toUpperCase();

        // if (formValue.descripcion.replace(/ /g, "") === "")
        //   formValue.descripcion = "S/D";

        // EN CASO DE QUE EXISTA UNA CATEGORÍA SE ACTUALIZA DE LO CONTRARIO SE CREA
        if (categoria) await updateCategoria(categoria.id, formValue);
        else await addCategoria(formValue);

        onRefetch(); // REFRESCA LA PÁGINA CategoriasAdmin.js
        onClose(); // CIERRA EL MODAL
      } catch (error) {
        console.log(error);
      }
    },
  });

  // FUNCIÓN AUXILIAR PARA VALIDAR QUE EL STOCK MÍNIMO SEA MAYOR AL STOCK CRÍTICO
  const handleChange = (event) => {
    setValor(event.target.value);
    formik.setFieldValue("stock_minimo", event.target.value);
  };

  return (
    <form className="add-edit-categoria-form" onSubmit={formik.handleSubmit}>
      {/* ====================== NOMBRE DE LA CATEGORÍA ====================== */}
      <CssTextField
        // inputProps={{ style: { textTransform: "uppercase" } }}
        label="Nombre de la categoría"
        name="nombre"
        value={formik.values.nombre}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.nombre && formik.errors.nombre)}
        helperText={formik.touched.nombre && formik.errors.nombre}
        onBlur={formik.handleBlur}
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      />

      {/* =============== SELECCIÓN SI EL INSUMO ES DESECHABLE =============== */}
      <div className="add-edit-categoria-form__desechable">
        <Checkbox
          toggle
          disabled={
            categoria?.stock_disponible > 0 || categoria?.stock_prestado > 0
              ? true
              : false
          }
          checked={formik.values.desechable}
          onChange={(_, data) =>
            formik.setFieldValue("desechable", data.checked)
          }
        />{" "}
        Desechable
      </div>

      {/* =========================== DESCRIPCIÓN ============================ */}
      <CssTextField
        label="Descripción"
        name="descripcion"
        value={formik.values.descripcion}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.descripcion && formik.errors.descripcion)}
        helperText={formik.touched.descripcion && formik.errors.descripcion}
        onBlur={formik.handleBlur}
        fullWidth
        variant="outlined"
        margin="dense"
        multiline
        rows={4}
      />

      {/* =========================== STOCK MÍNIMO =========================== */}
      <CssTextField
        label="Stock mínimo"
        name="stock_minimo"
        inputProps={{
          step: 1,
          min: 1,
          type: "number",
          "aria-labelledby": "input-slider",
        }}
        value={formik.values.stock_minimo}
        onChange={handleChange}
        error={Boolean(
          formik.touched.stock_minimo && formik.errors.stock_minimo
        )}
        helperText={formik.touched.stock_minimo && formik.errors.stock_minimo}
        onBlur={formik.handleBlur}
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      />

      {/* ========================== STOCK CRÍTICO =========================== */}
      <CssTextField
        label="Stock crítico"
        name="stock_critico"
        inputProps={{
          step: 1,
          min: 1,
          type: "number",
          "aria-labelledby": "input-slider",
        }}
        value={formik.values.stock_critico}
        onChange={formik.handleChange}
        error={Boolean(
          formik.touched.stock_critico && formik.errors.stock_critico
        )}
        helperText={formik.touched.stock_critico && formik.errors.stock_critico}
        onBlur={formik.handleBlur}
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
      />
      <hr />

      {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
      <Grid
        container
        direction="row"
        justifyContent="right"
        alignItems="center"
        columnSpacing={{ md: 1.5 }}
      >
        <Grid item>
          <CssButtonCancelar
            startIcon={<CloseIcon fontSize="large" />}
            onClick={onClose}
          >
            Cancelar
          </CssButtonCancelar>
        </Grid>

        <Grid item>
          <CssLoadingButton
            endIcon={
              categoria ? (
                <ReplaySharpIcon fontSize="large" />
              ) : (
                <AddTaskOutlinedIcon fontSize="large" />
              )
            }
            variant="contained"
            loading={loading}
            type="submit"
          >
            {categoria ? "Actualizar" : "Crear"}
          </CssLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

// INICIALIZACIÓN DE LOS VALORES INICIALES DEL FORMULARIO
function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    descripcion: data?.descripcion || "",
    desechable: data?.desechable ? true : false,
    stock_critico: data?.stock_critico || "",
    stock_minimo: data?.stock_minimo || "",
  };
}

// VALIDACIÓN DE LOS CAMPOS DEL FORMULARIO
function Schame(valor) {
  return {
    nombre: Yup.string()
      .required("Ingrese el nombre de la categoría")
      .min(3, "El nombre debe que tener al menos 3 caracteres"),
    descripcion: Yup.string(),
    desechable: Yup.bool().required(true),
    stock_critico: Yup.number()
      .required("Ingrese el valor del stock crítico")
      .max(valor, "El stock crítico debe ser menor al stock mínimo"),
    stock_minimo: Yup.number().required("Ingrese el valor del stock mínimo"),
  };
}
