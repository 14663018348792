// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CssListItemText,
  CssTablePagination,
} from "../../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import WysiwygIcon from "@mui/icons-material/Wysiwyg";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./TableAcopiosComprasRealizados.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import moment from "moment";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE ACOPIOS REALIZADOS
const columns = [
  { id: "id", label: "ID", minWidth: 100, align: "center" },
  {
    id: "fecha_hora_solicitud",
    label: "Fecha de realización",
    minWidth: 200,
    align: "center",
  },
  { id: "descripcion", label: "Descripción", minWidth: 200 },
  { id: "otro", label: "Otros datos", minWidth: 250 },
  {
    id: "acciones",
    label: "Acciones",
    // minWidth: 150,
    align: "center",
  },
];

export function TableAcopiosComprasRealizados(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA AcopiosRealizadosCompras.js
  const { acopios, infAcopio } = props;

  // CREACIÓN DE LAS VARIABLES DE ESTADOS
  const [page, setPage] = React.useState(0); // PARA EL MANEJO DE LA PAGINACIÓN
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // CANTIDAD DE FILAS A MOSTRAR

  // FUNCIÓN PARA EL CAMBIO DE PÁGINA DE LA TABLA
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FUNCIÓN PARA CAMBIAR LA CANTIDAD DE FILAS A MOSTRAR
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {acopios ? (
        // DETALLES DE LOS ACOPIOS REALIZADOS
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        // width: column.minWidth,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "500",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {acopios
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((acopio) => {
                    return (
                      <TableRow tabIndex={-1} key={acopio.id}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {acopio.id}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ py: FONTSIZE.paddingCell }}
                        >
                          <CssListItemText
                            primary={moment(acopio.fecha_hora_realizacion)
                              .locale("es")
                              .format("DD/MM/YYYY")}
                            secondary={
                              "Hora: " +
                              moment(acopio.fecha_hora_realizacion)
                                .locale("es")
                                .format("HH:mm")
                            }
                          />
                        </TableCell>

                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {acopio.descripcion.length > 100
                            ? acopio.descripcion.substr(0, 100) + "..."
                            : acopio.descripcion
                            ? acopio.descripcion
                            : "S/D"}

                          {/* {acopio.descripcion} */}
                        </TableCell>

                        <TableCell sx={{ py: FONTSIZE.paddingCell }}>
                          <CssListItemText
                            primary={
                              "Proveedor: " + acopio.data_proveedor?.nombre
                            }
                            secondary={
                              "Presupuesto: " + acopio.data_presupuesto?.anio
                            }
                          />
                        </TableCell>

                        <Actions acopio={acopio} infAcopio={infAcopio} />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* == CONTROL DE LA PAGINACIÓN ======================================== */}
          <CssTablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={acopios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Acopios por página:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES DE VER LOS DETALLES DE LOS ACOPIOS REALIZADOS POR EL USUARIO
function Actions(props) {
  const { acopio, infAcopio } = props;
  return (
    <TableCell align="center">
      {/* == BOTÓN PARA VER LOS DETALLES DEL ACOPIO ============================= */}
      <Tooltip title="Ver detalles">
        <IconButton
          aria-label="delete"
          size={FONTSIZE.sizeIcon}
          onClick={() => infAcopio(acopio)}
          type="button"
        >
          <WysiwygIcon fontSize="inherit" sx={{ color: "#2C6975" }} />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
