// IMPORTACIÓN DE REACT
import React, { useState, useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import { MenuItem, Grid, Autocomplete } from "@mui/material";
import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

import { toast } from "react-toastify";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

// IMPORTACIÓN DE ESTILOS
import "./AddEditAcopioForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { map } from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { useInsumo } from "../../../../hooks";

export function AddEditAcopioForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA AcopioAdmin.js
  const {
    onClose,
    insumo,
    acopio,
    setAcopio,
    index,
    categorias,
    onRefetch,
    setMontoTotal,
    montoTotal,
  } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { insumos, getInsumos } = useInsumo();
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = React.useState(false);

  const [categoriasFormat, setCategoriasFormat] = useState([]); // SETEA LOS DATOS DE CATEGORÍAS

  const [valueCategoria, setValueCategoria] = useState(null); // PARA EL VALOR DEL AUTOCOMPLETE
  const [inputCategoria, setInputCategoria] = useState(""); // PARA EL VALOR DE LA OPCIÓN SELECCIONADA DEL AUTOCOMPLETE

  // CREACIÓN DE LOS useEffect
  useEffect(
    () => setCategoriasFormat(formatCategorias(categorias)),
    [categorias]
  ); // SETEO DE LOS DATOS DE LAS CATEGORÍAS

  // DEFINICIÓN DE FORMIK Y YUP PARA LA VALIDACIÓN DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(insumo),
    validationSchema: Yup.object(Schame()),
    validateOnChange: false,
    onSubmit: (formValue) => {
      try {
        setLoading(true);

        formValue.codigo = formValue.codigo.toUpperCase(); // PARA ALMACENARLO DE FORMA CORRECTA

        // BUSQUEDA SI EL CÓDIGO INGRESADO YA EXISTE EN LA LISTA
        let index_busqueda = -1;
        if (!formValue.categoria_data.desechable) {
          for (var i = 0; i < acopio.length; i++) {
            if (
              acopio[i].codigo === formValue.codigo &&
              formValue.codigo !== insumo?.codigo
            ) {
              index_busqueda = i;
              break;
            }
          }
        }

        // SE VERIFICA SI EL CÓDIGO INGRESADO YA EXISTE EN LA BASE DE DATOS
        if (insumos?.length === 0 && index_busqueda === -1) {
          // SE MODIFICA LOS DATOS DEL INSUMO EN BASE A SI ES O NO DESECHABLE
          if (formValue.categoria_data.desechable) {
            formValue.marca = null;
            formValue.codigo = null;
            formValue.modelo = null;
            formValue.serie = null;
            formValue.datos_tecnicos = null;
            formValue.observaciones = null;
            formValue.tiempo_mantenimiento = null;
          } else {
            // if (formValue.observaciones.replace(/ /g, "") === "")
            //   formValue.observaciones = "S/O";

            // if (formValue.datos_tecnicos.replace(/ /g, "") === "")
            //   formValue.datos_tecnicos = "S/DT";

            formValue.cantidad = 1;
          }
          // SE VERIFICA SI SE RECIBIÓ EL INSUMO MEDIANTE PROPS
          if (insumo) {
            // SI EXISTE, SE ACTUALIZA LOS DATOS DEL INSUMO
            let newState = [...acopio];
            setMontoTotal(
              montoTotal +
                formValue.precio_unitario * formValue.cantidad -
                newState[index].precio_unitario * newState[index].cantidad
            );
            newState[index] = formValue;
            setAcopio(newState);
          } else {
            // SI NO EXISTE, SE AGREGA AL ACOPIO
            setMontoTotal(
              montoTotal + formValue.precio_unitario * formValue.cantidad
            );
            setAcopio(acopio.concat(formValue));
          }

          onClose(); // CIERRA EL MODAL
        } else {
          setLoading(false);
          // MENSAJES DE ERRORES DE QUE EXISTE EL CÓDIGO INGRESADO EN LA BASE DE DATOS O EN LA LISTA DE ACOPIO
          if (insumos?.length === 0) {
            toast.error(
              `No se puede añadir el insumo porque el código ${formValue.codigo} ya fue asignado a otro insumo de la lista.`,
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 7000,
              }
            );
          } else {
            toast.error(
              `No se puede añadir el insumo porque el código ${formValue.codigo} ya existe en la base de datos.`,
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
              }
            );
          }
        }
        onRefetch();
      } catch (error) {
        console.log(error);
      }
    },
  });

  // CREACIÓN DE LOS useEffect
  useEffect(
    () => getInsumos(formik.values.codigo.toUpperCase()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.codigo]
  ); // SETEO DE LOS DATOS DE LAS CATEGORÍAS

  // FUNCIÓN PARA LA SELECCIÓN DE LA CATEGORÍA A LA QUE PERTENECE EL INSUMO
  const handleChange = (event, newValue) => {
    // SE ASIGNA AL FORMIK EL VALOR DE LA CATEGORÍA SELECCIONADA
    setValueCategoria(newValue);
    formik.setFieldValue("categoria", newValue?.value);
    // SE RECORRE TODAS LAS CATEGORÍA EXISTENTE
    for (var i = 0; i < categorias.length; i++) {
      // SE OBTIENE LOS DATOS DE LA CATEGORÍA SELECCIONADA
      if (categorias[i].id === newValue?.value) {
        // SE ASIGNA AL FORMIK EL VALOR DE LOS DATOS DE LA CATEGORÍA SELECCIONADA
        formik.setFieldValue("categoria_data", categorias[i]);
        // SI NO SE RECIBIÓ SE HACE LO SIGUIENTE
        // SE VERIFICA SI LA CATEGORÍA SELECCIONADA ES DESECHABLE
        if (categorias[i].desechable) {
          // SI ES DESECHABLE SE LE ASIGNAN VALORES TEMPORALES PARA QUE PUEDAN PASAR POR LA VALIDACIÓN DEL YUP
          if (formik.values.marca === "") {
            formik.setFieldValue("marca", "temp");
          }
          if (formik.values.codigo === "") {
            formik.setFieldValue("codigo", "temp");
          }
          if (formik.values.tiempo_mantenimiento === "") {
            formik.setFieldValue("tiempo_mantenimiento", 99);
          }
        } else {
          // SI NO ES DESECHABLE, EN CASO DE TENER VALORES TEMPORALES, SE BORRA DICHOS VALORES
          if (formik.values.marca === "temp") {
            formik.setFieldValue("marca", "");
          }
          if (formik.values.codigo === "temp") {
            formik.setFieldValue("codigo", "");
          }
          if (formik.values.tiempo_mantenimiento === 99) {
            formik.setFieldValue("tiempo_mantenimiento", "");
          }
          formik.setFieldValue("cantidad", 1);
        }
        break;
      }
    }
  };

  return (
    <form className="add-edit-acopio-form" onSubmit={formik.handleSubmit}>
      {/* ====================== SELECCIÓN DE LA CATEGORÍA ====================== */}
      {categoriasFormat.length > 0 && (
        <Autocomplete
          value={
            insumo && valueCategoria === null
              ? categoriasFormat.find(
                  (categoria) => categoria.key === insumo.categoria
                )
              : valueCategoria
          }
          onChange={(event, newValue) => {
            handleChange(event, newValue);
          }}
          inputValue={inputCategoria}
          onInputChange={(event, newInputValue) => {
            setInputCategoria(newInputValue);
          }}
          options={categoriasFormat}
          renderInput={(params) => (
            <CssTextField
              {...params}
              label="Seleccione la categoría del insumo"
              size="small"
            />
          )}
        />
      )}

      {/* ====================== SE VERIFICA SI SE SELECCIONO UNA CATEGORÍA ====================== */}
      {formik.values.categoria !== "" ? (
        <>
          {/* ======== SE VERIFICA SI SE SELECCIONÓ UNA CATEGORÍA DE UN INSUMO DESECHABLE ======== */}
          {formik.values.categoria_data.desechable ? (
            <>
              {/* ====== SI EL INSUMO ES DESECHABLE SOLO SE LE PIDE LA CANTIDAD DEL INSUMOS A AGREGAR ======= */}
              <CssTextField
                label="Cantidad de insumos"
                name="cantidad"
                value={formik.values.cantidad}
                inputProps={{
                  step: 1,
                  min: 1,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.cantidad && formik.errors.cantidad
                )}
                helperText={formik.touched.cantidad && formik.errors.cantidad}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
                sx={{ mt: 2 }}
              />

              <CssTextField
                label="Precio unitario del insumo"
                name="precio_unitario"
                value={formik.values.precio_unitario}
                inputProps={{
                  type: "number",
                }}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.precio_unitario &&
                    formik.errors.precio_unitario
                )}
                helperText={
                  formik.touched.precio_unitario &&
                  formik.errors.precio_unitario
                }
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
                sx={{ mt: 2 }}
              />
            </>
          ) : (
            <>
              {/* ============ SI EL INSUMO ES NO DESECHABLE SE LE PIDE MAS DATOS  ============= */}

              {/* =================== GRILLA DE 2 COLUMNAS [CÓDIGO Y NÚMERO DE SERIE] ===================*/}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                sx={{ mt: 1 }}
              >
                {/* =============== CÓDIGO =============== */}
                <Grid item xs>
                  <CssTextField
                    label="Código del insumo"
                    name="codigo"
                    value={formik.values.codigo}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.codigo && formik.errors.codigo
                    )}
                    helperText={formik.touched.codigo && formik.errors.codigo}
                    onBlur={formik.handleBlur}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                  />
                </Grid>

                {/* =============== NÚMERO DE SERIE =============== */}
                <Grid item xs>
                  <CssTextField
                    label="Número de serie"
                    name="serie"
                    value={formik.values.serie}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.serie && formik.errors.serie)}
                    helperText={formik.touched.serie && formik.errors.serie}
                    onBlur={formik.handleBlur}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                  />
                </Grid>
              </Grid>

              {/* =================== GRILLA DE 2 COLUMNAS [MARCA Y MODELO] ===================*/}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {/* =============== MARCA =============== */}
                <Grid item xs>
                  <CssTextField
                    label="Marca del insumo"
                    name="marca"
                    value={formik.values.marca}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.marca && formik.errors.marca)}
                    helperText={formik.touched.marca && formik.errors.marca}
                    onBlur={formik.handleBlur}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                  />
                </Grid>

                {/* =============== MODELO =============== */}
                <Grid item xs>
                  <CssTextField
                    label="Modelo del insumo"
                    name="modelo"
                    value={formik.values.modelo}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.modelo && formik.errors.modelo
                    )}
                    helperText={formik.touched.modelo && formik.errors.modelo}
                    onBlur={formik.handleBlur}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                  />
                </Grid>
              </Grid>

              {/* =================== GRILLA DE 2 COLUMNAS [DATOS TÉCNICOS Y OBSERVACIONES] ===================*/}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {/* =============== DATOS TÉCNICOS =============== */}
                <Grid item xs>
                  <CssTextField
                    label="Datos técnicos"
                    name="datos_tecnicos"
                    value={formik.values.datos_tecnicos}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.datos_tecnicos &&
                        formik.errors.datos_tecnicos
                    )}
                    helperText={
                      formik.touched.datos_tecnicos &&
                      formik.errors.datos_tecnicos
                    }
                    onBlur={formik.handleBlur}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    multiline
                    rows={4}
                  />
                </Grid>

                {/* =============== OBSERVACIONES =============== */}
                <Grid item xs>
                  <CssTextField
                    label="Observaciones"
                    name="observaciones"
                    value={formik.values.observaciones}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.observaciones &&
                        formik.errors.observaciones
                    )}
                    helperText={
                      formik.touched.observaciones &&
                      formik.errors.observaciones
                    }
                    onBlur={formik.handleBlur}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>

              {/* =============== TIEMPO DE MANTENIMIENTO =============== */}
              <CssTextField
                id="outlined-select-currency"
                name="tiempo_mantenimiento"
                select
                search="true"
                label="Tiempo de mantenimiento"
                value={formik.values.tiempo_mantenimiento}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.tiempo_mantenimiento &&
                    formik.errors.tiempo_mantenimiento
                )}
                helperText={
                  formik.touched.tiempo_mantenimiento &&
                  formik.errors.tiempo_mantenimiento
                }
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <MenuItem value={1}>MENSUAL</MenuItem>
                <MenuItem value={2}>BIMESTRAL</MenuItem>
                <MenuItem value={3}>TRIMESTRAL</MenuItem>
                <MenuItem value={6}>SEMESTRAL</MenuItem>
                <MenuItem value={12}>ANUAL</MenuItem>
              </CssTextField>

              <CssTextField
                label="Precio del insumo"
                name="precio_unitario"
                value={formik.values.precio_unitario}
                inputProps={{
                  type: "number",
                }}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.precio_unitario &&
                    formik.errors.precio_unitario
                )}
                helperText={
                  formik.touched.precio_unitario &&
                  formik.errors.precio_unitario
                }
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
                sx={{ mt: 2 }}
              />
            </>
          )}
          <hr />

          {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="center"
            columnSpacing={{ md: 1.5 }}
          >
            <Grid item>
              <CssButtonCancelar
                startIcon={<CloseIcon fontSize="large" />}
                onClick={onClose}
              >
                Cancelar
              </CssButtonCancelar>
            </Grid>

            <Grid item>
              <CssLoadingButton
                endIcon={
                  insumo ? (
                    <ReplaySharpIcon fontSize="large" />
                  ) : (
                    <AddTaskOutlinedIcon fontSize="large" />
                  )
                }
                variant="contained"
                loading={loading}
                type="submit"
              >
                {insumo ? "Actualizar" : "Añadir"}
              </CssLoadingButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {/* ================ EN CASO DE QUE UN NO SE HAYA SELECCIONADO UNA CATEGORÍA ================ */}
          <hr></hr>
          {/* ================ BOTÓN PARA CANCELAR Y CERRAR EL MODAL ================ */}
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="center"
            columnSpacing={{ md: 1.5 }}
          >
            <Grid item>
              <Grid item>
                <CssButtonCancelar
                  startIcon={<CloseIcon fontSize="large" />}
                  onClick={onClose}
                >
                  Cancelar
                </CssButtonCancelar>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
}

// FUNCIÓN PARA SETEAR LOS DATOS DE LAS CATEGORÍAS
function formatCategorias(data) {
  return map(data, (item) => ({
    key: item.id,
    label: item.nombre,
    value: item.id,
  }));
}

// INICIALIZACIÓN DE LOS VALORES INICIALES DEL FORMULARIO PARA AÑADIR INSUMOS
function initialValues(data) {
  return {
    categoria: data?.categoria || "",
    categoria_data: data?.categoria_data || {},
    cantidad: data?.cantidad || 1,
    marca: data?.marca || "temp",
    modelo: data?.modelo || "",
    serie: data?.serie || "",
    codigo: data?.codigo || "temp",
    datos_tecnicos: data?.datos_tecnicos || "",
    observaciones: data?.observaciones || "",
    tiempo_mantenimiento: data?.tiempo_mantenimiento || 99,
    precio_unitario: data?.precio_unitario || "",
  };
}

// VALIDACIÓN DE LOS CAMPOS DEL FORMULARIO
function Schame() {
  return {
    cantidad: Yup.number().required("Ingrese la cantidad de insumos a añadir"),
    marca: Yup.string().required("Ingrese la marca del insumo"),
    modelo: Yup.string(),
    serie: Yup.string(),
    codigo: Yup.string().required("Ingrese el código del insumo"),
    datos_tecnicos: Yup.string(),
    observaciones: Yup.string(),
    tiempo_mantenimiento: Yup.number().required(
      "Ingrese el tiempo de mantenimiento del insumo"
    ),
    precio_unitario: Yup.number()
      .min(0, "El monto tiene que ser mayor o igual a 0")
      .required("Ingrese el precio del insumo"),
  };
}
