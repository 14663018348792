// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CssListItemText,
  CssTablePagination,
} from "../../../Common/componentesCssMui";

import { Icon } from "semantic-ui-react";

import { SeverityPill } from "../../severity-pill";

// IMPORTACIÓN DE ICONOS
import EditIcon from "@mui/icons-material/Edit";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./TableCategoriaAdmin.scss";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE CATEGORÍAS
const columns = [
  { id: "id", label: "", align: "center", minWidth: 50, width: 50 },
  { id: "nombre", label: "Nombre" },
  { id: "descripcion", label: "Descripción", minWidth: 350, width: 350 },
  { id: "desechable", label: "Desechable", align: "center" },
  { id: "insumos", label: "Insumos", align: "center" },
  {
    id: "stock",
    label: "Estado del stock",
    align: "center",
    colSpan: 2,
  },
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
  },
];

export function TableCategoriaAdmin(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA CategoriasAdmin.js
  const { categorias, updateCategoria, deleteCategoria } = props;

  // CREACIÓN DE LAS VARIABLES DE ESTADOS
  const [page, setPage] = React.useState(0); // PARA EL MANEJO DE LA PAGINACIÓN
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // CANTIDAD DE FILAS A MOSTRAR

  // FUNCIÓN PARA EL CAMBIO DE PÁGINA DE LA TABLA
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // FUNCIÓN PARA CAMBIAR LA CANTIDAD DE FILAS A MOSTRAR
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {categorias ? (
        // DETALLES DE LAS CATEGORÍAS
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              className="table-categoria-admin"
            >
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      colSpan={column.colSpan}
                      style={{
                        width: column.width,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "500",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {categorias
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((categoria, index) => {
                    return (
                      <TableRow tabIndex={-1} key={categoria.id}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {index + 1 + page * rowsPerPage}
                          {/* {categoria.id} */}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {categoria.nombre}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {categoria.descripcion.length > 80
                            ? categoria.descripcion.substr(0, 80) + "..."
                            : categoria.descripcion
                            ? categoria.descripcion
                            : "S/D"}
                          {/* {categoria.descripcion} */}
                        </TableCell>
                        <TableCell
                          className={"status"}
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          {categoria.desechable ? (
                            <Icon name="check" />
                          ) : (
                            <Icon name="close" />
                          )}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ py: FONTSIZE.paddingCell }}
                        >
                          {categoria.desechable ? (
                            <CssListItemText
                              primary={
                                "Insumos disponibles: " +
                                categoria.stock_disponible
                              }
                            />
                          ) : (
                            <CssListItemText
                              primary={
                                "Insumos disponibles: " +
                                categoria.stock_disponible
                              }
                              secondary={
                                "Insumos prestados: " + categoria.stock_prestado
                              }
                            />
                          )}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: FONTSIZE.tableCell,
                          }}
                        >
                          <SeverityPill
                            color={
                              categoria.estado_stock === "EN_STOCK"
                                ? "success"
                                : categoria.estado_stock === "LIMITADO"
                                ? "warning"
                                : "error"
                            }
                          >
                            {categoria.estado_stock === "EN_STOCK"
                              ? "EN STOCK"
                              : categoria.estado_stock === "CRITICO"
                              ? "CRÍTICO"
                              : categoria.estado_stock}
                          </SeverityPill>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ py: FONTSIZE.paddingCell }}
                        >
                          <CssListItemText
                            primary={
                              "Stock crítico: " + categoria.stock_critico
                            }
                            secondary={
                              "Stock mínimo: " + categoria.stock_minimo
                            }
                          />
                        </TableCell>

                        <Actions
                          categoria={categoria}
                          updateCategoria={updateCategoria}
                          deleteCategoria={deleteCategoria}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* == CONTROL DE LA PAGINACIÓN ======================================== */}
          <CssTablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={categorias.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Categorías por página:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES DE ACTUALIZAR O ELIMINAR DATOS DE UNA CATEGORÍA
function Actions(props) {
  const { categoria, updateCategoria } = props;
  return (
    <TableCell align="center" sx={{ py: FONTSIZE.paddingCell }}>
      {/* == BOTÓN PARA MODIFICAR DATOS DE UNA CATEGORÍA ============================= */}
      <Tooltip title="Editar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => updateCategoria(categoria)}
          type="button"
        >
          <EditIcon color="info" />
        </IconButton>
      </Tooltip>

      {/* BOTÓN PARA ELIMINAR UNA CATEGORÍA */}
      {/* <Tooltip title="Borrar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => deleteCategoria(categoria)}
          type="button"
        >
          <DeleteForeverIcon color="error" />
        </IconButton>
      </Tooltip> */}
    </TableCell>
  );
}
