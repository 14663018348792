// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getTrabajadoresApi,
  addTrabajadorApi,
  updateTrabajadorApi,
  deleteTrabajadorApi,
  getTrabajadorSolicitanteApi,
  getTrabajadorActualizadorApi,
  getTrabajadorDevolucionApi,
} from "../api/trabajador";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from "./";

export function useTrabajador() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [trabajadores, setTrabajadores] = useState(null); // PARA ALMACENAR LOS TRABAJADORES
  const [trabajadorSol, setTrabajadorSol] = useState(null); // PARA ALMACENAR EL TRABAJADOR QUE SOLICITA LA SOLICITUD
  const [trabajadorAct, setTrabajadorAct] = useState(null); // PARA ALMACENAR EL TRABAJADOR QUE ACTUALIZA EL ESTADO DE LA SOLICITUD
  const [trabajadorDev, setTrabajadorDev] = useState(null); // PARA ALMACENAR EL TRABAJADOR QUE MARCA LA DEVOLUCIÓN DE LOS INSUMOS DE UN PRÉSTAMO

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE TRABAJADORES
  const getTrabajadores = async (dni, email, area, usuario, ordering) => {
    try {
      setLoading(true);
      const response = await getTrabajadoresApi(
        dni,
        email,
        area,
        usuario,
        ordering,
        auth.token
      );
      setLoading(false);
      setTrabajadores(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE TRABAJADOR QUE REALIZA UNA SOLICITUD
  const getTrabajadorSolicitante = async (usuario) => {
    try {
      setLoading(true);
      const response = await getTrabajadorSolicitanteApi(usuario, auth.token);
      setLoading(false);
      setTrabajadorSol(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE TRABAJADOR QUE APRUEBA O DESAPRUEBA LA SOLICITUD
  const getTrabajadorActualizador = async (usuario) => {
    try {
      setLoading(true);
      const response = await getTrabajadorActualizadorApi(usuario, auth.token);
      setLoading(false);
      setTrabajadorAct(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE TRABAJADOR QUE REGISTRA LA DEVOLUCIÓN
  const getTrabajadorDevolucion = async (usuario) => {
    try {
      setLoading(true);
      const response = await getTrabajadorDevolucionApi(usuario, auth.token);
      setLoading(false);
      setTrabajadorDev(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UN NUEVO TRABAJADOR
  const addTrabajador = async (data) => {
    try {
      setLoading(true);
      await addTrabajadorApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DE LOS DATOS DE UN TRABAJADOR
  const updateTrabajador = async (id, data) => {
    try {
      setLoading(true);
      await updateTrabajadorApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ELIMINACIÓN DE UN TRABAJADOR
  const deleteTrabajador = async (id) => {
    try {
      setLoading(true);
      await deleteTrabajadorApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getTrabajadores,
    trabajadores,
    getTrabajadorSolicitante,
    trabajadorSol,
    getTrabajadorActualizador,
    trabajadorAct,
    getTrabajadorDevolucion,
    trabajadorDev,
    addTrabajador,
    updateTrabajador,
    deleteTrabajador,
    loading,
    error,
  };
}
