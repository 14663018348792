// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Icon } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./TableAcopioAdmin.scss";

// NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE ACOPIOS
const columns = [
  { id: "nro", label: "", minWidth: 50 },
  { id: "categoria", label: "Categoría" },
  { id: "desechable", label: "Desechable", align: "center" },
  { id: "cantidad", label: "Cantidad", align: "center" },
  { id: "codigo", label: "Código", align: "center" },
  { id: "marca", label: "Marca", align: "center" },
  { id: "modelo", label: "Modelo", align: "center" },
  { id: "precio_unitario", label: "Precio unitario", align: "center" },
  { id: "subtotal", label: "Subtotal", align: "center" },
  {
    id: "acciones",
    label: "Acciones",
    align: "center",
  },
];

export function TableAcopioAdmin(props) {
  // DATOS Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA AcopioAdmin.js
  const { insumos, onDeleteInsumo, updateInsumo } = props;

  return (
    <>
      {insumos ? (
        // DETALLES DE LOS INSUMOS AGREGADO AL ACOPIO
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
          <TableContainer sx={{ maxHeight: 340 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              className="table-acopio-admin"
            >
              {/* == ENCABEZADO DE LA TABLA ======================================== */}
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      colSpan={column.colSpan}
                      style={{
                        width: column.minWidth,
                        minWidth: column.minWidth,
                        background: "#2C6975",
                        fontSize: FONTSIZE.tableRow,
                        // fontWeight: "500",
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* == CONTENIDO DE LA TABLA ======================================== */}
              <TableBody>
                {insumos.map((insumo, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell
                        align="center"
                        style={{
                          fontSize: FONTSIZE.tableCell,
                        }}
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: FONTSIZE.tableCell,
                        }}
                      >
                        {insumo.categoria_data.nombre}
                      </TableCell>

                      <TableCell
                        className={"status"}
                        align="center"
                        style={{
                          fontSize: FONTSIZE.tableCell,
                        }}
                      >
                        {insumo.categoria_data.desechable ? (
                          <Icon name="check" />
                        ) : (
                          <Icon name="close" />
                        )}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          fontSize: FONTSIZE.tableCell,
                        }}
                      >
                        {insumo.cantidad}
                      </TableCell>

                      {insumo.categoria_data.desechable ? (
                        <>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: FONTSIZE.tableCell,
                            }}
                          >
                            {insumo.codigo}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              fontSize: FONTSIZE.tableCell,
                            }}
                          >
                            {insumo.marca}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              fontSize: FONTSIZE.tableCell,
                            }}
                          >
                            {insumo.modelo ? insumo.modelo : "-"}
                          </TableCell>
                        </>
                      )}

                      <TableCell
                        align="center"
                        style={{
                          fontSize: FONTSIZE.tableCell,
                        }}
                      >
                        $ {insumo.precio_unitario}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          fontSize: FONTSIZE.tableCell,
                        }}
                      >
                        $ {insumo.precio_unitario * insumo.cantidad}
                      </TableCell>

                      <Actions
                        index={index}
                        insumo={insumo}
                        updateInsumo={updateInsumo}
                        onDeleteInsumo={onDeleteInsumo}
                      />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

// ACCIONES DE ACTUALIZAR O ELIMINAR LOS INSUMOS DEL ACOPIO A REALIZAR
function Actions(props) {
  const { insumo, index, updateInsumo, onDeleteInsumo } = props;

  return (
    <TableCell align="center" sx={{ py: FONTSIZE.paddingCell }}>
      {/* == BOTÓN PARA MODIFICAR DATOS DE UN INSUMO ============================= */}
      <Tooltip title="Editar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => updateInsumo(insumo, index)}
          type="button"
        >
          <EditIcon color="info" />
        </IconButton>
      </Tooltip>
      {/* == BOTÓN PARA ELIMINAR UN INSUMO ======================================= */}
      <Tooltip title="Borrar">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => onDeleteInsumo(insumo, index)}
          type="button"
        >
          <DeleteForeverIcon color="error" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
