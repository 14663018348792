// IMPORTACIÓN DE PÁGINAS
import {
  UsersAdmin,
  PersonaAdmin,
  AreasAdmin,
  TrabajadorAdmin,
  CategoriasAdmin,
  InsumosAdmin,
  AcopioAdmin,
  DesacopioAdmin,
  SolicitudDesacopioAdmin,
  AcopiosRealizadosAdmin,
  PresupuestoAdmin,
  PrestamosAdmin,
  ProveedorAdmin,
  SolicitudPrestamoAdmin,
} from "../pages/Admin";

import { Error404, Validacion } from "../pages";

// IMPORTACIÓN DE LAYOUTS
import { AdminLayout, BasicLayout } from "../layouts";

// LISTADO DE LAS URL PARA EL USUARIO ADMINISTRADOR
const routesAdministradores = [
  {
    path: "/",
    layout: AdminLayout,
    component: UsersAdmin,
    exact: true,
  },
  {
    path: "/users",
    layout: AdminLayout,
    component: UsersAdmin,
    exact: true,
  },
  {
    path: "/persona",
    layout: AdminLayout,
    component: PersonaAdmin,
    exact: true,
  },
  {
    path: "/area",
    layout: AdminLayout,
    component: AreasAdmin,
    exact: true,
  },
  {
    path: "/trabajador",
    layout: AdminLayout,
    component: TrabajadorAdmin,
    exact: true,
  },
  {
    path: "/presupuesto",
    layout: AdminLayout,
    component: PresupuestoAdmin,
    exact: true,
  },
  {
    path: "/proveedores",
    layout: AdminLayout,
    component: ProveedorAdmin,
    exact: true,
  },
  {
    path: "/categorias",
    layout: AdminLayout,
    component: CategoriasAdmin,
    exact: true,
  },
  {
    path: "/insumos",
    layout: AdminLayout,
    component: InsumosAdmin,
    exact: true,
  },
  {
    path: "/acopios",
    layout: AdminLayout,
    component: AcopioAdmin,
    exact: true,
  },
  {
    path: "/desacopios",
    layout: AdminLayout,
    component: DesacopioAdmin,
    exact: true,
  },
  {
    path: "/solicitud_desacopios",
    layout: AdminLayout,
    component: SolicitudDesacopioAdmin,
    exact: true,
  },
  {
    path: "/acopios_realizados",
    layout: AdminLayout,
    component: AcopiosRealizadosAdmin,
    exact: true,
  },
  {
    path: "/prestamos",
    layout: AdminLayout,
    component: PrestamosAdmin,
    exact: true,
  },
  {
    path: "/solicitud_prestamos",
    layout: AdminLayout,
    component: SolicitudPrestamoAdmin,
    exact: true,
  },
  {
    path: "/validado",
    layout: BasicLayout,
    component: Validacion,
  },
  {
    path: "*",
    layout: BasicLayout,
    component: Error404,
  },
];

export default routesAdministradores;
