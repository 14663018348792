// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getPresupuestosApi,
  addPresupuestoApi,
  updatePresupuestoApi,
  getModificacionesByPresupuestoApi,
  addModificacionPresupuestariaApi,
} from "../api/presupuesto";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from ".";

export function usePresupuesto() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [presupuestos, setPresupuestos] = useState(null); // PARA ALMACENAR LOS PRESUPUESTOS
  const [modificacionPresupuestaria, setModificacionPresupuestaria] =
    useState(null); // PARA ALMACENAR LAS MODIFICACIONES PRESUPUESTARIAS

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE PRESUPUESTOS
  const getPresupuestos = async (anio, usuario, ordering) => {
    try {
      setLoading(true);
      const response = await getPresupuestosApi(
        anio,
        usuario,
        ordering,
        auth.token
      );
      setLoading(false);
      setPresupuestos(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UN NUEVO PRESUPUESTO
  const addPresupuesto = async (data) => {
    try {
      setLoading(true);
      await addPresupuestoApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DE LOS DATOS DE UN PRESUPUESTO
  const updatePresupuesto = async (id, data) => {
    try {
      setLoading(true);
      await updatePresupuestoApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // OBTENCIÓN DE LAS MODIFICACIONES PRESUPUESTARIAS DE UN PRESUPUESTO
  const getModificacionesByPresupuesto = async (id_presupuesto) => {
    try {
      setLoading(true);
      const response = await getModificacionesByPresupuestoApi(
        id_presupuesto,
        auth.token
      );
      setLoading(false);
      setModificacionPresupuestaria(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UNA NUEVA MODIFICACIÓN PRESUPUESTARIA
  const addModificacionPresupuestaria = async (data) => {
    try {
      setLoading(true);
      await addModificacionPresupuestariaApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getPresupuestos,
    presupuestos,
    addPresupuesto,
    updatePresupuesto,
    getModificacionesByPresupuesto,
    modificacionPresupuestaria,
    addModificacionPresupuestaria,
    loading,
    error,
  };
}
