// IMPORTACIÓN DE REACT
import React, { useEffect } from "react";

// IMPORTACIÓN DE COMPONENTES
import {
  Box,
  Grid,
  Card,
  Divider,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CssListItemText,
  CssButtonRegresar,
  CssTypography,
} from "../../../Common/componentesCssMui";

import { Loader } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";

// IMPORTACIÓN DE ESTILOS
import { FONTSIZE } from "../../../../utils/constants";
import "./AddEditSolDesSoliForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import moment from "moment";

// IMPORTACIÓN DE HOOKS CREADOS
import { useDesacopio, useTrabajador } from "../../../../hooks";

export function AddEditSolDesSoliForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA SolicitudDesacopioSolicitante.js
  const { desacopio, onClose } = props;

  // NOMBRES DE LOS ENCABEZADOS DE LAS COLUMNAS DE LA TABLA DE LOS INSUMOS
  const columns = [
    { id: "nro", label: "", minWidth: 50 },
    { id: "categoria", label: "Categoría" },
    {
      id: "cantidad_solicitada",
      label: "Cantidad solicitada",
      align: "center",
    },
    {
      id: "cantidad_entregada",
      label:
        desacopio.estado !== "PENDIENTE"
          ? "Cantidad entregada"
          : "Cantidad a entregar",
      align: "center",
    },
  ];

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { getCategoriasByDesacopio, categorias, loading } = useDesacopio();

  const { trabajadorAct, getTrabajadorActualizador } = useTrabajador();

  // CREACIÓN DE useEffect PARA LAS FUNCIONES
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCategoriasByDesacopio(desacopio.id), []); // PARA OBTENER LOS INSUMOS DE UN DESACOPIO ESPECIFICO

  useEffect(
    () =>
      desacopio.usuario_actualizacion &&
      getTrabajadorActualizador(desacopio.usuario_actualizacion),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [desacopio]
  ); // DATOS DEL TRABAJADOR QUE ACTUALIZA EL ESTADO DEL PRÉSTAMO

  return (
    <form className="add-edit-soldes-form">
      {/* ====================== SE VERIFICA SI YA SE OBTUVO TODOS LOS INSUMOS ====================== */}
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <>
          {categorias ? (
            <>
              {/* == DETALLES DEL DESACOPIO REALIZADO ====================================== */}
              <Card
                style={{
                  border: "1px solid",
                  borderColor: "#2C6975",
                  boxShadow: "none",
                }}
                sx={{ px: 1, py: 0.5 }}
              >
                <Typography sx={{ fontSize: FONTSIZE.tableCell, px: 1 }}>
                  <b>ID: {desacopio.id}</b>
                </Typography>

                <Divider sx={{ borderColor: "#2C6975" }}></Divider>

                <Grid
                  container
                  direction="row"
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  sx={{ px: 1 }}
                >
                  <Grid item xs>
                    <CssListItemText
                      primary={
                        <>
                          <b>Fecha y hora de solicitud: </b>{" "}
                          {moment(desacopio.fecha_hora_solicitud)
                            .locale("es")
                            .format("DD/MM/YYYY, HH:mm")}{" "}
                        </>
                      }
                      secondary={
                        <>
                          <b>Descripción: </b>{" "}
                          {desacopio.descripcion_solicitud
                            ? desacopio.descripcion_solicitud
                            : "S/D"}
                        </>
                      }
                      sx={{ mb: 0 }}
                    />
                  </Grid>
                </Grid>
              </Card>

              {/* == DETALLES DE LOS INSUMOS DEL DESACOPIO ================================= */}
              <Divider sx={{ borderColor: "#2C6975", mt: 2, mb: 0.7 }} />

              <CssTypography align="center" sx={{ pb: 0.5 }}>
                Lista de insumos solicitados en el desacopio
              </CssTypography>

              <Paper sx={{ width: "100%", overflow: "hidden", mt: 0.1 }}>
                <TableContainer sx={{ maxHeight: 300 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    size="small"
                    className="table-acopio-admin"
                  >
                    {/* == ENCABEZADO DE LA TABLA ======================================== */}
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            colSpan={column.colSpan}
                            style={{
                              width:
                                desacopio.estado === "PENDIENTE" &&
                                column.id === "cantidad_entregada"
                                  ? 0
                                  : column.minWidth,
                              minWidth:
                                desacopio.estado === "PENDIENTE" &&
                                column.id === "cantidad_entregada"
                                  ? 0
                                  : column.minWidth,
                              background: "#2C6975",
                              fontSize: FONTSIZE.tableRow,
                              // fontWeight: "500",
                              color: "#FFFFFF",
                            }}
                          >
                            {desacopio.estado === "PENDIENTE" &&
                            column.id === "cantidad_entregada"
                              ? ""
                              : column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* == CONTENIDO DE LA TABLA ======================================== */}
                    <TableBody>
                      {categorias?.map((categoria, index) => {
                        return (
                          <TableRow tabIndex={-1} key={index}>
                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {categoria.categoria_data.nombre}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                fontSize: FONTSIZE.tableCell,
                              }}
                            >
                              {categoria.cantidad_solicitada}
                            </TableCell>

                            {desacopio.estado !== "PENDIENTE" && (
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: FONTSIZE.tableCell,
                                }}
                              >
                                {categoria.cantidad_entregada}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          ) : (
            <></>
          )}

          {/* SI EL ESTADO DEL DESACOPIO ESTA EN PENDIENTE SE PROCEDE A ACTUALIZAR SU ESTADO ======== */}
          {desacopio.estado === "PENDIENTE" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
              }}
            >
              <CssButtonRegresar
                startIcon={<ArrowBackSharpIcon fontSize="large" />}
                onClick={onClose}
              >
                Regresar
              </CssButtonRegresar>
            </Box>
          ) : (
            <>
              {/* == SI EL DESACOPIO ESTA APROBADO O DESAPROBADO SE MUESTRA LOS DETALLES */}
              {trabajadorAct && (
                <Card
                  style={{
                    border: "1px solid",
                    borderColor: "#2C6975",
                    boxShadow: "none",
                  }}
                  sx={{ px: 1, py: 0.5, mt: 2 }}
                >
                  <Typography
                    sx={
                      desacopio.estado === "DESAPROBADO"
                        ? { fontSize: FONTSIZE.tableCell, color: "red", px: 1 }
                        : {
                            fontSize: FONTSIZE.tableCell,
                            color: "green",
                            px: 1,
                          }
                    }
                  >
                    <b>Estado actual: {desacopio.estado}</b>
                  </Typography>

                  <Divider sx={{ borderColor: "#2C6975" }}></Divider>

                  <Grid item xs sx={{ px: 1 }}>
                    <CssListItemText
                      primary={
                        <>
                          <b>Fecha y hora de actualización: </b>{" "}
                          {moment(desacopio.fecha_hora_actualizacion)
                            .locale("es")
                            .format("DD/MM/YYYY, HH:mm")}{" "}
                        </>
                      }
                      secondary={
                        <>
                          <b>Descripción: </b>{" "}
                          {desacopio.descripcion_actualizacion
                            ? desacopio.descripcion_actualizacion
                            : "S/D"}
                        </>
                      }
                      sx={{ mb: 0 }}
                    />
                  </Grid>

                  <Divider sx={{ borderColor: "#2C6975", mt: 0.5 }}></Divider>

                  <Grid
                    container
                    direction="row"
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    sx={{ px: 1 }}
                  >
                    <Grid item xs>
                      <CssListItemText
                        primary={
                          <>
                            <b>
                              {desacopio.estado === "DESAPROBADO"
                                ? "Desaprobado por: "
                                : "Aprobado por: "}
                            </b>
                            {desacopio.data_user_act?.last_name
                              ? desacopio.data_user_act.last_name +
                                ", " +
                                desacopio.data_user_act.first_name
                              : desacopio.data_user_act.email}
                          </>
                        }
                        sx={{ mb: 0 }}
                      />
                    </Grid>

                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ borderColor: "#2C6975" }}
                      sx={{ mt: 0.5 }}
                    />

                    <Grid item xs>
                      <CssListItemText
                        primary={
                          <>
                            <b>Trabaja en el área de: </b>{" "}
                            {trabajadorAct?.length !== 0
                              ? trabajadorAct[0].area_data.nombre
                              : "No identificado"}
                          </>
                        }
                        sx={{ mb: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              )}

              {/* == BOTÓN PARA REGRESAR ==================================== */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <CssButtonRegresar
                  startIcon={<ArrowBackSharpIcon fontSize="large" />}
                  onClick={onClose}
                >
                  Regresar
                </CssButtonRegresar>
              </Box>
            </>
          )}
        </>
      )}
    </form>
  );
}
