// IMPORTACIÓN DE REACT
import { useState } from "react";

// IMPORTACIÓN DE LAS PETICIONES A LA API
import {
  getCategoriasApi,
  addCategoriaApi,
  updateCategoriaApi,
  deleteCategoriaApi,
} from "../api/categoria";

// IMPORTACIÓN DEL useAuth PARA LA AUTENTICACIÓN
import { useAuth } from ".";

export function useCategoria() {
  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(true); // PARA LA ESPERA DE LA RESPUESTA
  const [error, setError] = useState(null); // PARA MOSTRAR EL ERROR
  const [categorias, setCategorias] = useState(null); // PARA ALMACENAR LAS CATEGORÍAS

  // OBTENEMOS LOS DATOS A PARTIR DEL HOOK useAuth
  const { auth } = useAuth();

  // OBTENCIÓN DE CATEGORÍAS
  const getCategorias = async (desechable, nombre, stock, id, ordering) => {
    try {
      setLoading(true);
      const response = await getCategoriasApi(
        desechable,
        nombre,
        stock,
        id,
        ordering,
        auth.token
      );
      setLoading(false);
      setCategorias(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // AÑADIR UNA NUEVA CATEGORÍA
  const addCategoria = async (data) => {
    try {
      setLoading(true);
      await addCategoriaApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ACTUALIZACIÓN DE LOS DATOS DE UNA CATEGORÍA
  const updateCategoria = async (id, data) => {
    try {
      setLoading(true);
      await updateCategoriaApi(id, data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  // ELIMINACIÓN DE UNA CATEGORÍA
  const deleteCategoria = async (id) => {
    try {
      setLoading(true);
      await deleteCategoriaApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    getCategorias,
    categorias,
    addCategoria,
    updateCategoria,
    deleteCategoria,
    loading,
    error,
  };
}
