// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER ÁREAS, SE PUEDE FILTRAR LA OBTENCIÓN DE LOS REGISTROS
export async function getAreasApi(id = "", nombre = "", ordering = "", token) {
  try {
    const filterId = `id=${id}`;
    const filterNombre = `nombre=${nombre}`;
    const filterOrdering = `ordering=${ordering}`;
    const url = `${BASE_API}/api/areas/?${filterId}&${filterNombre}&${filterOrdering}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AGREGAR UNA NUEVA ÁREA
export async function addAreaApi(data, token) {
  try {
    const url = `${BASE_API}/api/areas/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error(
        "Hubo un error al crear el área, es probable que el nombre del área ya exista",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente la nueva área", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LOS DATOS DE UNA ÁREA
export async function updateAreaApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/areas/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (response.status === 400) {
      toast.error(
        "Hubo un error al actualizar los datos del área, es probable que el nombre del área ya exista",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        }
      );
    } else {
      toast.success("Se ha registrado correctamente los cambios", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ELIMINAR UNA ÁREA
export async function deleteAreaApi(id, token) {
  try {
    const url = `${BASE_API}/api/areas/${id}/`;

    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);

    if (response.status === 400) {
      toast.error("Hubo un error al eliminar el área", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
      });
    } else {
      toast.success("Se ha eliminado el área", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
