// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import { Modal } from "semantic-ui-react";

// IMPORTACIÓN DE ESTILOS
import "./ModalBasic.scss";

export function ModalBasic(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE DIVERSAS PÁGINAS
  const { show, size, title, children, onClose } = props;

  return (
    <Modal
      closeOnDimmerClick={false}
      dimmer="inverted"
      // closeIcon
      className="modal-basic"
      open={show}
      onClose={onClose}
      size={size}
    >
      {title && (
        <Modal.Header className="modal-basic__header">{title}</Modal.Header>
      )}
      <Modal.Content className="modal-basic__content">{children}</Modal.Content>
    </Modal>
  );
}

ModalBasic.defaultProps = {
  size: "tiny",
};
