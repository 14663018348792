// IMPORTACIÓN DE REACT
import React from "react";

// IMPORTACIÓN DE COMPONENTES
import { Card, CardHeader } from "@mui/material";

import { CssButtonPrimary } from "../../Common/componentesCssMui";

// IMPORTACIÓN DE ICONOS
import AddCircleIcon from "@mui/icons-material/AddCircle";

// IMPORTACIÓN DE ESTILOS
import "./HeaderPage.scss";

export function HeaderPage(props) {
  const { title, subheader, btnTitle, btnClick } = props;
  return (
    <Card>
      {/* === TITULO DEL ENCABEZADO DE LA PÁGINA ========= */}
      <CardHeader
        title={title}
        subheader={subheader}
        //* ============= BOTÓN PARA AGREGAR ============= */}
        action={
          btnTitle && (
            <CssButtonPrimary
              startIcon={<AddCircleIcon fontSize="large" sx={{ ml: 0.5 }} />}
              size="medium"
              onClick={btnClick}
              sx={{ marginTop: 1.15, mr: 0.7 }}
            >
              {btnTitle}
            </CssButtonPrimary>
          )
        }
      />
    </Card>
  );
}
