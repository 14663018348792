// IMPORTACIÓN DE LA URL
import { BASE_API } from "../utils/constants";

// IMPORTACIÓN DE COMPONENTE
import { toast } from "react-toastify";

// PETICIÓN A LA API PARA OBTENER LOS INSUMOS, SE PUEDE FILTRAR LA OBTENCIÓN DE LOS REGISTROS
export async function getInsumosApi(
  codigo = "",
  categoria = "",
  disponibilidad = "",
  ordering = "",
  token
) {
  try {
    const codigoFilter = `codigo=${codigo}`;
    const categoriaFilter = `categoria=${categoria}`;
    const disponibilidadFilter = `disponibilidad=${disponibilidad}`;
    const orderingFilter = `ordering=${ordering}`;
    const url = `${BASE_API}/api/insumos/?${codigoFilter}&${categoriaFilter}&${disponibilidadFilter}&${orderingFilter}`;

    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA AGREGAR UN NUEVO INSUMO
export async function addInsumoApi(data, token) {
  try {
    const url = `${BASE_API}/api/insumos/`;

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ACTUALIZAR LOS DATOS DE UN INSUMO
export async function updateInsumoApi(id, data, token) {
  try {
    const url = `${BASE_API}/api/insumos/${id}/`;

    const params = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status === 400) {
      toast.error("Hubo un error al actualizar los datos del insumo", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
      });
    } else {
      toast.success("Se ha registrado correctamente los cambios", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }

    return result;
  } catch (error) {
    throw error;
  }
}

// PETICIÓN A LA API PARA ELIMINAR UN INSUMO
export async function deleteInsumoApi(id, token) {
  try {
    const url = `${BASE_API}/api/insumos/${id}/`;

    const params = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
