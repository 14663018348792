// IMPORTACIÓN DE REACT
import React, { useEffect, useState } from "react";

// IMPORTACIÓN DE COMPONENTES
import { MenuItem, Grid } from "@mui/material";
import {
  CssTextField,
  CssButtonCancelar,
  CssLoadingButton,
} from "../../../Common/componentesCssMui";

import { Button, Icon, Loader } from "semantic-ui-react";

// IMPORTACIÓN DE ICONOS
import CloseIcon from "@mui/icons-material/Close";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

// IMPORTACIÓN DE ESTILOS
import "./AddEditPersonaForm.scss";

// IMPORTACIÓN DE FUNCIONES Y UTILIDADES
import { useFormik, Field, FieldArray, FormikProvider } from "formik";
import * as Tformik from "formik-material-ui";
import * as Yup from "yup";

// IMPORTACIÓN DE HOOKS CREADOS
import { usePersona, useGeoreferencias } from "../../../../hooks";

export function AddEditPersonaForm(props) {
  // VARIABLES Y/O FUNCIONES OBTENIDAS MEDIANTE PROPS DE LA PÁGINA PersonaAdmin.js
  const { onClose, onRefetch, persona } = props;

  // VARIABLES Y/O FUNCIONES DE HOOKS
  const { addPersona, updatePersona } = usePersona();
  const {
    provincias,
    getProvincias,
    departamentos,
    getDepartamentos,
    localidades,
    getLocalidades,
  } = useGeoreferencias();

  // CREACIÓN DE VARIABLES DE ESTADOS
  const [loading, setLoading] = useState(false);

  const [provincia, setProvincia] = useState(
    persona !== undefined
      ? persona.domicilios[0].localidad_data.departamento_data.provincia
      : ""
  ); // PARA OBTENER LAS PROVINCIAS

  const [departamento, setDepartamento] = useState(
    persona !== undefined
      ? persona.domicilios[0].localidad_data.departamento
      : ""
  ); // PARA OBTENER LOS DEPARTAMENTOS

  // CREACIÓN DE LOS useEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getProvincias(), []); // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getDepartamentos(provincia), [provincia]); // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getLocalidades(departamento), [departamento]);

  // VERIFICACIÓN Y VALIDACIÓN DE LOS DATOS DEL FORMULARIO
  const formik = useFormik({
    initialValues: initialValues(persona),
    validationSchema: Yup.object(Schame()),
    validateOnChange: false,
    // ENVIO DEL FORMULARIO
    onSubmit: async (formValue) => {
      try {
        setLoading(true);

        // PASA INTERMEDIO PARA EL SERIALIZADOR
        formValue = Object.assign(formValue, {
          domicilios: [formValue.domicilio],
        });

        if (persona) {
          await updatePersona(persona.id, formValue); // SI LA PERSONA EXISTE SE ACTUALIZA SUS DATOS
        } else {
          await addPersona(formValue); // SE AÑADE UNA NUEVA PERSONA
        }
        onRefetch();
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  // CREACIÓN DE FUNCIONES PARA LA SELECCIÓN DE PROVINCIA Y DEPARTAMENTO
  const handleChangeP = (event) => {
    setProvincia(event.target.value);
    setDepartamento("");
    formik.setFieldValue("domicilio.localidad", "");
  };

  const handleChangeD = (event) => {
    setDepartamento(event.target.value);
    formik.setFieldValue("domicilio.localidad", "");
  };

  return (
    // FORMULARIO PRINCIPAL
    <form className="add-edit-user-form" onSubmit={formik.handleSubmit}>
      {/* =================== GRILLA DE 2 COLUMNAS  [PERSONALES Y DOMICILIO]===================*/}
      <Grid
        container
        direction="row"
        justifyContent="center"
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        {/* =================== SECCIÓN DE DATOS PERSONALES  ===================*/}
        <Grid item xs>
          <h3 className="datosPersonales">Datos personales</h3>
          <CssTextField
            label="DNI"
            name="num_doc"
            value={formik.values.num_doc}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.num_doc && formik.errors.num_doc)}
            helperText={formik.touched.num_doc && formik.errors.num_doc}
            onBlur={formik.handleBlur}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          />

          {/* =================== GRILLA DE 2 COLUMNAS [NOMBRES Y APELLIDOS] ===================*/}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            {/* =============== NOMBRES =============== */}
            <Grid item xs>
              <CssTextField
                label="Nombres"
                name="nombres"
                value={formik.values.nombres}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.nombres && formik.errors.nombres)}
                helperText={formik.touched.nombres && formik.errors.nombres}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>

            {/* =============== APELLIDOS =============== */}
            <Grid item xs>
              <CssTextField
                label="Apellidos"
                name="apellidos"
                value={formik.values.apellidos}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.apellidos && formik.errors.apellidos
                )}
                helperText={formik.touched.apellidos && formik.errors.apellidos}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>
          </Grid>

          {/* =============== SEXO =============== */}
          <CssTextField
            id="outlined-select-currency"
            name="sexo"
            select
            search="true"
            label="Sexo"
            value={formik.values.sexo}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.sexo && formik.errors.sexo)}
            helperText={formik.touched.sexo && formik.errors.sexo}
            onBlur={formik.handleBlur}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          >
            <MenuItem value={"F"}>Femenino</MenuItem>
            <MenuItem value={"M"}>Masculino</MenuItem>
            {/* <MenuItem value={"O"}>Otro</MenuItem> */}
          </CssTextField>

          {/* ========= FECHA DE NACIMIENTO ========= */}
          <CssTextField
            InputLabelProps={{ shrink: true }}
            label="Fecha de nacimiento"
            type="date"
            name="fecha_nacimiento"
            value={formik.values.fecha_nacimiento}
            onChange={formik.handleChange}
            error={Boolean(
              formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento
            )}
            helperText={
              formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento
            }
            onBlur={formik.handleBlur}
            // placeholder="Fecha de Nacimiento"
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          />
          {/* =================== SECCIÓN DE DATOS DE CONTACTO  ===================*/}
          {/* =================== GRILLA DE 2 COLUMNAS [EMAILS Y TELÉFONOS] ===================*/}
          <h3 className="datoContacto">Datos de contacto</h3>
          <Grid
            container
            direction="row"
            justifyContent="center"
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            {/* =============== EMAILS =============== */}
            <Grid item xs>
              <FormikProvider value={formik}>
                <FieldArray name="emails">
                  {({ push, remove }) => (
                    <>
                      {formik.values.emails.map((_, index) => (
                        <div key={index} className="botones">
                          <Field
                            fullWidth
                            name={`emails.${index}.email`}
                            component={Tformik.TextField}
                            label={"Correo electrónico " + (index + 1)}
                            variant="outlined"
                            margin="dense"
                            size="small"
                          />

                          <Button
                            className="botones__agregar"
                            icon
                            type="button"
                            size="small"
                            disabled={formik.isSubmitting}
                            onClick={() => push({ email: "" })}
                          >
                            <Icon name="pencil" />
                          </Button>

                          <Button
                            className="botones__eliminar"
                            size="small"
                            icon
                            type="button"
                            negative
                            disabled={
                              formik.values.emails.length === 1
                                ? true
                                : formik.isSubmitting
                            }
                            onClick={() => {
                              const result = window.confirm(
                                `¿Eliminar el email ${index + 1}: ${
                                  formik.values.emails[index].email
                                }?`
                              );
                              if (result) {
                                remove(index);
                              }
                            }}
                          >
                            <Icon name="close" />
                          </Button>
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
              </FormikProvider>
            </Grid>

            {/* =============== TELEFONOS =============== */}
            <Grid item xs={5}>
              <FormikProvider value={formik}>
                <FieldArray name="telefonos">
                  {({ push, remove }) => (
                    <>
                      {formik.values.telefonos.map((_, index) => (
                        <div key={index} className="botones">
                          <Field
                            fullWidth
                            name={`telefonos.${index}.num_telefono`}
                            component={Tformik.TextField}
                            label={"Teléfono " + (index + 1)}
                            variant="outlined"
                            margin="dense"
                            size="small"
                          />

                          <Button
                            className="botones__agregar"
                            icon
                            type="button"
                            size="small"
                            disabled={formik.isSubmitting}
                            onClick={() => push({ num_telefono: "" })}
                          >
                            <Icon name="pencil" />
                          </Button>

                          <Button
                            className="botones__eliminar"
                            size="small"
                            icon
                            type="button"
                            negative
                            disabled={
                              formik.values.telefonos.length === 1
                                ? true
                                : formik.isSubmitting
                            }
                            onClick={() => {
                              const result = window.confirm(
                                `¿Eliminar el teléfono ${index + 1}: ${
                                  formik.values.telefonos[index].num_telefono
                                }?`
                              );
                              if (result) {
                                remove(index);
                              }
                            }}
                          >
                            <Icon name="close" />
                          </Button>
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
              </FormikProvider>
            </Grid>
          </Grid>
        </Grid>

        {/* =================== SECCIÓN DE DATOS DEL DOMICILIO  ===================*/}
        <Grid item xs={5}>
          <h3 className="datoContacto">Datos del domicilio</h3>
          {/* =============== PROVINCIA =============== */}
          <CssTextField
            select
            search="true"
            label="Provincia"
            value={provincias ? provincia : ""}
            onChange={handleChangeP}
            fullWidth
            // minRows={5}
            // maxRows={5}
            // rows={5}
            variant="outlined"
            margin="dense"
            size="small"
          >
            {provincias ? (
              provincias.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nombre}
                </MenuItem>
              ))
            ) : (
              <Loader active inline="centered">
                Cargando...
              </Loader>
            )}
          </CssTextField>

          {/* =============== DEPARTAMENTO =============== */}
          <CssTextField
            disabled={provincia === "" ? true : false}
            select
            search="true"
            label="Departamento"
            value={departamentos ? departamento : ""}
            onChange={handleChangeD}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          >
            {departamentos ? (
              departamentos.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nombre}
                </MenuItem>
              ))
            ) : (
              <Loader active inline="centered">
                Cargando...
              </Loader>
            )}
          </CssTextField>

          {/* =============== LOCALIDAD =============== */}
          <CssTextField
            disabled={provincia === "" || departamento === "" ? true : false}
            select
            name="domicilio.localidad"
            search="true"
            label="Localidad"
            value={localidades ? formik.values.domicilio?.localidad : ""}
            error={Boolean(
              formik.touched.domicilio?.localidad &&
                formik.errors.domicilio?.localidad
            )}
            helperText={
              formik.touched.domicilio?.localidad &&
              formik.errors.domicilio?.localidad
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          >
            {localidades ? (
              localidades.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nombre}
                </MenuItem>
              ))
            ) : (
              <Loader active inline="centered">
                Cargando...
              </Loader>
            )}
          </CssTextField>

          {/* =================== GRILLA DE 2 COLUMNAS [CALLE Y NUMERO] ===================*/}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            {/* =============== CALLE =============== */}
            <Grid item xs>
              <CssTextField
                label="Calle"
                name="domicilio.calle"
                value={formik.values.domicilio?.calle}
                error={Boolean(
                  formik.touched.domicilio?.calle &&
                    formik.errors.domicilio?.calle
                )}
                helperText={
                  formik.touched.domicilio?.calle &&
                  formik.errors.domicilio?.calle
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>

            {/* =============== NÚMERO DE CASA =============== */}
            <Grid item xs>
              <CssTextField
                label="Número de casa"
                name="domicilio.numero"
                value={formik.values.domicilio?.numero}
                error={Boolean(
                  formik.touched.domicilio?.numero &&
                    formik.errors.domicilio?.numero
                )}
                helperText={
                  formik.touched.domicilio?.numero &&
                  formik.errors.domicilio?.numero
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>
          </Grid>

          {/* =================== GRILLA DE 2 COLUMNAS [MANZANA Y LOTE] ===================*/}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            {/* =============== MANZANA =============== */}
            <Grid item xs>
              <CssTextField
                label="Manzana"
                name="domicilio.manzana"
                value={formik.values.domicilio?.manzana}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>

            {/* =============== LOTE =============== */}
            <Grid item xs>
              <CssTextField
                label="Lote"
                name="domicilio.lote"
                value={formik.values.domicilio?.lote}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              />
            </Grid>
          </Grid>

          {/* =============== OBSERVACIONES =============== */}
          <CssTextField
            label="Observaciones"
            name="domicilio.observaciones"
            value={formik.values.domicilio?.observaciones}
            onChange={formik.handleChange}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          />
        </Grid>
      </Grid>
      <hr />
      {/* =============== SECCIÓN DE BOTONES PARA CREAR/ACTUALIZAR O CANCELAR =============== */}
      <Grid
        container
        direction="row"
        justifyContent="right"
        alignItems="center"
        columnSpacing={{ md: 1.5 }}
      >
        <Grid item>
          <CssButtonCancelar
            startIcon={<CloseIcon fontSize="large" />}
            onClick={onClose}
          >
            Cancelar
          </CssButtonCancelar>
        </Grid>

        <Grid item>
          <CssLoadingButton
            endIcon={
              persona ? (
                <ReplaySharpIcon fontSize="large" />
              ) : (
                <AddTaskOutlinedIcon fontSize="large" />
              )
            }
            variant="contained"
            loading={loading}
            type="submit"
          >
            {persona ? "Actualizar" : "Crear"}
          </CssLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

// INICIALIZACIÓN DE LOS VALORES DEL FORMULARIO
function initialValues(data) {
  return {
    nombres: data?.nombres || "",
    apellidos: data?.apellidos || "",
    num_doc: data?.num_doc || "",
    sexo: data?.sexo || "",
    fecha_nacimiento: data?.fecha_nacimiento || "",
    telefonos: data?.telefonos || [{ num_telefono: "" }],
    emails: data?.emails || [{ email: "" }],
    domicilio: data
      ? {
          calle: data?.domicilios[0].calle,
          numero: data?.domicilios[0].numero,
          manzana: data?.domicilios[0].manzana,
          lote: data?.domicilios[0].lote,
          observaciones: data?.domicilios[0].observaciones,
          localidad: data?.domicilios[0].localidad,
        }
      : {
          calle: "",
          numero: "",
          manzana: "",
          lote: "",
          observaciones: "",
          localidad: "",
        },
  };
}

// VALIDACIÓN DEL FORMULARIO PARA LA CREACIÓN O MODIFICACIÓN DE LOS DATOS DE UNA PERSONA
function Schame() {
  return {
    num_doc: Yup.number()
      .typeError("Este campo solo puede estar formado por números")
      .required("Ingrese el DNI"),
    apellidos: Yup.string()
      .required("Ingrese el apellido")
      .min(3, "El apellido debe que tener al menos 3 letras"),
    nombres: Yup.string()
      .required("Ingrese el nombre")
      .min(3, "El nombre debe que tener al menos 3 letras"),
    sexo: Yup.string().required("Ingrese el sexo"),
    fecha_nacimiento: Yup.date().required("Ingrese la fecha de nacimiento"),
    telefonos: Yup.array(
      Yup.object({
        num_telefono: Yup.number()
          .typeError("Ingrese un número de teléfono valido")
          .required("Ingrese el teléfono"),
      })
    ),
    emails: Yup.array(
      Yup.object({
        email: Yup.string()
          .email("Ingrese un correo electrónico valido")
          .required("Ingrese un correo electrónico"),
      })
    ),
    domicilio: Yup.object({
      localidad: Yup.number().required("Seleccione la localidad"),
      calle: Yup.string().required("Seleccione la localidad"),
      numero: Yup.number()
        .typeError("Este campo tiene que ser un número")
        .required("Ingrese el número de casa"),
    }),
  };
}
